import React from "react";
import { ClipLoader } from "react-spinners";
import "./Loader.css";

const Loading = (props) => {
  const { isShowing, text, inLine, absCenter } = props;

  if (!isShowing) return null;

  const loader = () => (
    <ClipLoader sizeUnit="px" size={100} color="#123abc" loading />
  );

  if (inLine) {
    return (
      <div
        className={
          absCenter
            ? "kmod-react-spinner-loading-abs-center"
            : "kmod-react-spinner-loading"
        }
      >
        {loader()}
        <div>{text}</div>
      </div>
    );
  }

  return (
    <div className="overlay-parent">
      <div className="overlay-child">
        <div>
          {loader()}
          {text}
        </div>
      </div>
    </div>
  );
};

export default Loading;
