import React, { Component } from 'react';
import AddRoleView from './presentation/AddRole';
import { manage } from '../../../api';

export default class AddRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '',
      validRole: true,
      organizations: [],
      selectedOrganizations: [],
      validOrganization: true,
    };
    this.addRole = this.addRole.bind(this);
    this.setRole = this.setRole.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.setOrganization = this.setOrganization.bind(this);
  }

  componentDidMount() {
    this.getOrganizations();
  }

  async getOrganizations() {
    const organizations = await manage.get.organizations();
    if (organizations.data !== null) {
      organizations.data = organizations.data.filter(org => org.isDeleted === false);
      // Renaming the organizations for clarity
      // eslint-disable-next-line
      organizations.data.map(org => org.name = `${org.name} (${org.asset.name})`);
      this.setState({ organizations: organizations.data });
    }
  }

  setOrganization(selectedOrganizations) {
    this.setState({ selectedOrganizations });
  }

  setRole(roleName) {
    this.setState({ role: roleName });
  }

  isValid() {
    const { role, selectedOrganizations } = this.state;
    const validRole = role !== '';
    const validOrganization = selectedOrganizations.length > 0;
    this.setState({ validRole, validOrganization });
    return validRole && validOrganization;
  }

  async addRole() {
    const { role, selectedOrganizations: orgs } = this.state;
    const { hide } = this.props;
    if (this.isValid()) {
      const organizations = orgs.map(org => ({ organizationID: org.organizationID }));
      const res = await manage.post.role(
        role,
        organizations,
      ).catch((err) => alert(err.response.data)); // eslint-disable-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ role: '', seletectedOrganizations: [] });
      }
    }
  }

  render() {
    return (
      <div>
        <AddRoleView
          {...this.props}
          {...this.state}
          set={{
            role: this.setRole,
            organization: this.setOrganization,
          }}
          submit={this.addRole}
        />
      </div>
    );
  }
}
