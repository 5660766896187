import React from "react";
import styles from "./HeadcountCard.module.scss";
import { connect } from "react-redux";
import * as Actions from "../../../../../redux/actions/manageHeadcountActions";
import IconOnlyButton from "../../../../presentation/unity/IconOnlyButton";

const DisplayHeadcountCard = (props) => {
  const { headcount, disableButtons } = props;

  const SmallIcon = ({ icon, color }) => (
    <svg className="em-c-icon em-c-icon--small" style={{ color: color }}>
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={`/images/em-icons.svg#${icon}`}
      />
    </svg>
  );

  return (
    <div className={styles.card_container}>
      <div>
        <div className={styles[`pill_${headcount.status.toLowerCase()}`]}>
          {headcount.status}
        </div>
      </div>

      <div className={styles.card_button_container}>
        {headcount.status === "New" && (
          <IconOnlyButton
            btnClass={styles.card_button}
            disabled={disableButtons}
            title={"Delete this Headcount!"}
            icon={"trashcan"}
            iconColor={"red"}
            onClick={() => props.deleteHeadcount(headcount.headcountRequestID)}
          />
        )}
        <IconOnlyButton
          btnClass={styles.card_button}
          disabled={disableButtons}
          icon={"copy"}
          title={"Clone this Headcount!"}
          onClick={() => props.cloneHeadcount(headcount.headcountRequestID)}
        />
        <IconOnlyButton
          btnClass={styles.card_button}
          disabled={disableButtons || !headcount.editable}
          icon={"edit"}
          title={"Edit this Headcount!"}
          onClick={() => props.editHeadcount(headcount.headcountRequestID)}
        />
      </div>
      <div>
        <div className={styles.card_column_20}>
          <div className={styles.icon_image}>
            <SmallIcon icon={"group"} />
            <strong className={styles.icon_text_left}>
              {headcount.organization || "-"}
            </strong>
          </div>
          <div>
            <strong>Usage Type: </strong>
            {headcount.usageType || "-"}
          </div>
          <div>
            <strong>Role: </strong>
            {headcount.positionRole || "-"}
          </div>
        </div>
        <div className={styles.card_column_25}>
          <div>
            <strong>Department: </strong>
            {headcount.department || "-"}
          </div>
          <div>
            <strong>Section: </strong>
            {headcount.section || "-"}
          </div>
          <div>
            <strong>Area: </strong>
            {headcount.area || "-"}
          </div>
        </div>
        <div className={styles.card_column_15}>
          <div
            className={
              headcount.shiftID === 1
                ? styles.icon_box_day
                : styles.icon_box_night
            }
          >
            <div className={styles.icon_box_position}>
              <SmallIcon icon={headcount.shiftID === 1 ? "sun" : "moon"} />
            </div>
            <span className={styles.icon_box_text}>
              {headcount.shiftID === 1 ? "Day" : "Night"}
            </span>
          </div>
          <div>
            <span className={styles.icon_text_right}>Billable </span>
            <SmallIcon
              icon={headcount.billable ? "check" : "x-filled"}
              color={headcount.billable ? "green" : "red"}
            />
          </div>
          <div>
            <span className={styles.icon_text_right}>Schedulable </span>
            <SmallIcon
              icon={headcount.schedulable ? "check" : "x-filled"}
              color={headcount.schedulable ? "green" : "red"}
            />
          </div>
          <div>
            <span className={styles.icon_text_right}>Shift Imbalance </span>
            <SmallIcon
              icon={headcount?.headCountGrouping ? "check" : "x-filled"}
              color={headcount?.headCountGrouping ? "green" : "red"}
            />
          </div>
        </div>
        <div className={styles.card_column_20}>
          <div>
            <SmallIcon icon={"calendar"} />
            <strong className={styles.icon_text_left}>Dates</strong>
          </div>
          <div className={styles.table_box}>
            <table className={`${styles.table_date} ${styles.card_table}`}>
              <tbody>
                <tr>
                  <td
                    className={
                      styles.card_td + " " + styles.card_td_first_child
                    }
                  >
                    {headcount.startDate.value}{" "}
                  </td>
                  <td
                    className={styles.card_td + " " + styles.card_td_last_child}
                  >
                    {headcount.endDate != null
                      ? headcount.endDate.value
                      : "indefinite"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.card_column_20}>
          <div>
            <SmallIcon icon={"suitcase"} />
            <strong className={styles.icon_text_left}>Company</strong>
          </div>
          <div className={styles.table_box}>
            <table className={styles.card_table}>
              <tbody>
                <tr>
                  <td
                    className={
                      styles.table_company +
                      " " +
                      styles.card_td +
                      " " +
                      styles.card_td_first_child
                    }
                  >
                    {headcount.company || "-"}{" "}
                  </td>
                  <td
                    className={styles.card_td + " " + styles.card_td_last_child}
                  >
                    {headcount.quantity || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    editHeadcount: (id) => dispatch(Actions.editHeadcount(id)),
    cloneHeadcount: (id) => dispatch(Actions.cloneHeadcount(id)),
    deleteHeadcount: (id) => dispatch(Actions.deleteHeadcount(id)),
  };
};

export default connect(null, mapDispatchToProps)(DisplayHeadcountCard);
