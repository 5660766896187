import React, { Component } from 'react';
import Select from 'react-select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Col,
} from 'reactstrap';
import UnityButton from '../../../presentation/unity/Button';

export default class ActivateCompanyView extends Component {
  render() {
    const {
      isOpen,
      hide,
      submit,
      list,
      selected,
      set,
      validCompany,
    } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => hide()} className="modal-xl">
          <ModalHeader toggle={() => hide()}>
            Activate Company
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm="3">
                  <p className="section-heading" key="label">
                    Company Name
                  </p>
                </Col>
                <Col sm="9">
                  <Select
                    placeholder="Companies"
                    options={list}
                    onChange={option => set.company(option)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.companyID}
                    defaultValue={selected ? selected.userCompanies : null}
                    className="over-button"
                    isClearable
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <UnityButton color="primary" onClick={() => submit()} text="OK" disabled={!validCompany} />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
