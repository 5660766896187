/**
 This file is now for msal, but before the upgrade this file was
 named react-adal.js and unconventionally supported the ADAL implementation.
 **/
import { user as userAPI } from "../../api";
import { msalInstance } from "../../utils/auth/authProvider";

let getCachedUserRoles = () => {
  return document.localRoles;
};
let getCachedUserCompanies = () => {
  return document.localUserCompanies;
};
let getCachedUser = () => {
  return document.localUserDetails;
};

let setCachedUserRoles = (localRoles) => {
  document.localRoles = localRoles;
};
let setCachedUserCompanies = (localUserCompanies) => {
  return (document.localUserCompanies = localUserCompanies);
};
let setCachedUser = (localUserDetails) => {
  return (document.localUserDetails = localUserDetails);
};

// This is a Microsoft thing.
/* eslint-disable no-underscore-dangle */
export const getUser = () => {
  const account = msalInstance.getActiveAccount();
  return account;
};

export const logout = () => {
  setCachedUserRoles(null);

  msalInstance.logoutPopup({
    account: msalInstance.getActiveAccount(),
    mainWindowRedirectUri: "logout.html",
  });
};

export const getRoles = () => {
  return getCachedUserRoles();
};

export const getCompanies = () => {
  return getCachedUserCompanies();
};

export const getDBUser = () => {
  if (getCachedUser()) {
    return getCachedUser(); //found the cached user
  }

  console.error(`cachedUser is not set; call loadDBUserAndCache first`);
};

export const loadDBUserAndCache = async () => {
  const msalUser = getUser();
  if (!msalUser) {
    console.error(`unexpected error; maybe not logged in`);
    return null; //no msal user, maybe not logged in
  }

  //otherwise, query api for user details since it is not cached
  let userFoundInDb = true;
  const userObject = await userAPI.get
    .byEmail(msalUser.username)
    .catch((err) => {
      userFoundInDb = false;
      console.error(`Error getting user by email ${err.response.status}`);
    });

  if (!userFoundInDb) {
    await addUserToDB(msalUser);
  }

  if (!userObject) {
    console.error(`unexpected error; userObject from db is undefined`);
    return null;
  }

  setUserInCache(userObject);
};

const checkUserRole = (role) => {
  const userRoles = getRoles();

  return userRoles.find((item) => item.name === role) !== undefined;
};

export const isUser = {
  securityGuard: () => checkUserRole("SecurityGuard"),
  admin: () => checkUserRole("Admin"),
  approver: () => checkUserRole("Approver"),
  contractLead: () => checkUserRole("Contract Lead"),
  requestor: () => checkUserRole("Requestor"),
};

const setUserInCache = async (userObject) => {
  const localRoles = [];
  const localCompanies = [];
  let localUserDetails = {};
  userObject.data.userRoles.map((role) => localRoles.push(role.role));
  userObject.data.userCompanies.map((company) =>
    localCompanies.push(company.company)
  );
  localUserDetails = userObject.data;

  setCachedUserRoles(localRoles);
  setCachedUserCompanies(localCompanies);
  setCachedUser(localUserDetails);
};

const addUserToDB = async (user) => {
  const { name } = user;
  const splitName = name.split(",");
  if (splitName.length < 2) {
    console.error(
      `Expected a surname and given name. But count was ${splitName.length}`
    );
  }
  const lastName = splitName[0];
  const givenNameSplit = splitName[1].trim().split(" "); //Example: "Lastname /C"; so split on the space
  const firstName = givenNameSplit[0]; //take first value; example: ["Lastname", "/C"]
  const { username: email } = user;

  const roles = await userAPI.get.list();

  // New users can only be requestors
  const userRoles = [
    roles.data.find(
      (role) => role.name === "Requestor" || role.name === "Requester"
    ),
  ];
  const userObject = await userAPI.post.user({
    firstName,
    lastName,
    email,
    userRoles,
  });
  await setUserInCache(userObject);
};

const updateUserInDB = async (user) => {
  await userAPI.put
    .user(user.data.userID, user.data)
    .then()
    .catch(() => null);
};

async function fetchUserFromDB() {
  //get the msal user
  const msalUser = getUser();
  if (!msalUser) {
    console.error(`unexpected error maybe not logged in`);
    return; //no msal user, maybe not logged in
  }

  //otherwise, query api for user details since it is not cached
  const email = msalUser.username;
  const userObject = await userAPI.get
    .byEmail(email)
    .catch((err) =>
      console.error(`Error getting user by email ${err.response.status}`)
    );
  if (!userObject) {
    console.error(`unexpected error userObject from db is undefined`);
    return;
  }

  await setUserInCache(userObject);
}

export async function runWithMsal(svgForEveryone, setDbUser) {
  await loadDBUserAndCache();
  setDbUser(getCachedUser());
  svgForEveryone();
}
