import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Col,
} from 'reactstrap';
import Dropdown from '../../../../../containers/Input/Dropdown';
import Breadcrumb from '../../../../../presentation/Breadcrumb';
import UnityButton from '../../../../../presentation/unity/Button';

const breadcrumb = [
  { name: 'Home', link: '/' },
  { name: 'Reports', isActive: true },
  { name: 'Unassigned Personnel', isActive: true },
];

export default class UnassignedPersonnelView extends Component {
  render() {
    const {
      setSelected,
      companies,
      companyLink,
      getReport,
    } = this.props;
    return (
      <div className="personnel-report-container">
        <Breadcrumb items={breadcrumb} />
        <Form>
          <FormGroup row>
            <Dropdown
              options={companies}
              label="Company Name"
              onSelect={value => setSelected(value)}
              nullable
            />
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <UnityButton text="Export" onClick={() => getReport()} disabled={companyLink === null} />
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
