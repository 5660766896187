import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import {titleCaseRegex as titleCase} from '../../../utils/string/to-title-case';

export default class ApproverTable extends PureComponent {
  render() {
    function renderStatus(status) {
        
      let badgeTypeClass = '';
      switch (status) {
        case 'Approved':
          badgeTypeClass = 'em-c-badge--positive';
          break;
        case 'Denied':
          badgeTypeClass = 'em-c-badge--negative';
          break;
        case 'Submitted':
        case 'For Review':
          badgeTypeClass = 'em-c-badge--caution';
          break;
        default:
          break;
      }
      return (<span className={`em-c-badge ${badgeTypeClass}`}>{status}</span>);   
    }
    
    const {
      id,
      list
    } = this.props;
    return (
      <Table hover>
        <tbody>
          {
            list.map((item) => {
              return (
                <tr key = {`${id}_${item.user.userID}`} className='em-c-table__row'>
                    <td>{item.approvalSequence}</td>
                    <td>
                        { titleCase(item.user?.firstName + ' ' + item.user?.lastName) }
                    </td>
                    <td>
                      {renderStatus(item.approvalStatus.statusName)}
                    </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    );
  }
}

ApproverTable.defaultProps = {
  list: [],
  highlights: []
};