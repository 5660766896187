import html2canvas from 'html2canvas';

export const clone = async () => {
    let currentTarget = document.querySelector('#emailCloneCurrent');
    let previousTarget = document.querySelector('#emailClonePrevious');
    const currentSource = document.querySelector('#currentCCRView');
    const previousSource = document.querySelector('#previousCCRView');
    currentTarget.innerHTML = previousSource ? '<h3>Current CCR</h3>' + currentSource.innerHTML : currentSource.innerHTML;
    let elements = [currentTarget];
    if (previousSource) {
      previousTarget.innerHTML = '<h3>Previous CCR</h3>' + previousSource.innerHTML
      elements.push(previousTarget)
    }
    var imgs = await generateImages(elements)
    previousTarget.innerHTML = '';
    currentTarget.innerHTML = '';
    return imgs
}

export const generateImage =  element => {
    window.scroll(0, 0);
    return new Promise( (resolve, reject) => {
        html2canvas(element).then(canvas => {
            resolve(canvas.toDataURL().substring('data:image/png;base64,'.length))
          });
    });
}

export const generateImages = (elements) => {
    const prms = elements.map(x => generateImage(x));
    return new Promise(
        (resolve, reject) => {
            Promise.all(prms).then(
                e => resolve(e)
            )
        }
    )
}


const generateAttachmentText = attachment => `----boundary_text_string
Content-Type: ${attachment.fileType}; name=${attachment.fileName}
Content-Transfer-Encoding: base64
Content-Disposition: attachment

${attachment.data}

`;


export function generateEmlFile(subject, body, attachments) {
    var atts = attachments.map(generateAttachmentText).join('\n');
    var emlContent = `data:message/rfc822 eml;charset=utf-8,To: 
Subject: ${subject}
X-Unsent: 1
Content-Type: multipart/mixed; boundary=--boundary_text_string

----boundary_text_string
Content-Type: text/html; charset=UTF-8

<html>
<body>
${body}
</body>
</html>

${atts}
`;
    const fileLink = new Blob([emlContent], {type: 'text/plain'});
    return window.URL.createObjectURL(fileLink);
}

