import React from 'react';
import { isUser } from '../../../../js/msal/msalHelper';

const Security = () => {
  // ToDo: Check if Admin need access to this as well
  if (isUser.securityGuard() || isUser.admin()) {
    return (
      <li className="em-c-tree__item">
        <span className="em-c-tree__link em-c-tree__item--has--children" role="tab">
          <span className="em-c-tree__text em-c-tree__link--has--children">
          Security
          </span>
        </span>
        <ul className="em-c-tree__list em-js-tree-dropdown">
          <li className="em-c-tree__item">
            <a
              href="/badge-data-entry"
              className="em-c-tree__link"
            >
              Badge Data Entry
            </a>
          </li>
        </ul>
      </li>
    );
  }
  return null;
};

export default Security;
