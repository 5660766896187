export const LOAD_REASSIGN_CCR_DATA = 'LOAD_REASSIGN_CCR_DATA';
export const LOAD_REASSIGN_CCR_DATA_SUCCESS = 'LOAD_REASSIGN_CCR_DATA_SUCCESS';
export const LOAD_REASSIGN_CCR_DATA_ERROR = 'LOAD_REASSIGN_CCR_DATA_ERROR';
export const REASSIGN_CCR_CLEAR = 'REASSIGN_CCR_CLEAR';
export const SUBMIT_REASSIGN_CCR = 'SUBMIT_REASSIGN_CCR';


export const loadReassignData = ccrID => ({
    type: LOAD_REASSIGN_CCR_DATA,
    payload: ccrID,
});

export const loadReassignDataSuccess = data => ({
    type: LOAD_REASSIGN_CCR_DATA_SUCCESS,
    payload: data,
});

export const loadReassignDataError = msg => ({
    type: LOAD_REASSIGN_CCR_DATA_ERROR,
    payload: msg,
});

export const clearReassignData = () => ({
    type: REASSIGN_CCR_CLEAR,
    payload: null,
});

export const submitReassignCCR = (ccrID, approvedApprovers, selectedApprovers, history) => ({
    type: SUBMIT_REASSIGN_CCR,
    payload: {ccrID, approvedApprovers, selectedApprovers, history}
});