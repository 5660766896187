import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './CollapseableSection.module.css';

const CollapseableSection = props => {

    const readDefaultState = state => {
        if (typeof state === 'boolean') 
            return state;
        else if (Number.isInteger(state)){
            return state > -1 ? true : false
        }
    }

    const {title, defaultOpen, children, disableToggle, forceOpen} = props;
    const [isExpanded, SetIsExpanded] = useState(readDefaultState(defaultOpen));

    useEffect(() => SetIsExpanded(readDefaultState(defaultOpen)), [defaultOpen]);

    const handleToggle = () => !forceOpen && !disableToggle && SetIsExpanded(!isExpanded);

    return (
        <div>
            <h5 onClick={handleToggle} className={styles.section_header}>{title}
            <svg className="em-c-icon em-c-icon--small">
                <use 
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/em-icons.svg#chevron-${ (forceOpen || isExpanded) ? 'up' : 'down'}`}
                />
            </svg>
            </h5>
            <div className={styles.section_content}>{(forceOpen || isExpanded) && children}</div>
        </div>
    )
}

CollapseableSection.propTypes = {
    title: PropTypes.string,
    defaultOpen: PropTypes.oneOfType([
        PropTypes.bool, PropTypes.number
    ]),
    disableToggle: PropTypes.bool
}

CollapseableSection.defaultProps = {
    title: "--NULL--",
    defaultOpen: false,
    disableToggle: false
}

export default CollapseableSection
