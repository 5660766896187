import React from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import TextArea from '../../../presentation/Input/TextArea';
import TableLayout from '../../../containers/Table';
import Marker from '../../../presentation/Marker';
import cx from 'classnames';
import styles from './styles.module.css';
import { titleCaseRegex } from '../../../../utils/string/to-title-case';
import ApproverTable from '../../../containers/Table/ApproverTable';

const CCRView = (props) => {
  const {
    id,
    title,
    mode,
    justification,
    headcounts,
    asset,
    organization,
    purchaseOrderUpdateRequired,
    annualEstimatedCost,
    equipmentVehicleInvolved,
    equipmentVehicleEstimatedCost,
    equipmentDetails,
    requestor,
    highlights,
    ccrApprovers,
    ccrStatus,
    table
  } = props;

  const isDiff = element => highlights && highlights.ccr.includes(element);
  const getRequestorDisplay = requestor => {
    if (!requestor) return "-";
    if (requestor.firstName && requestor.lastName){
      return titleCaseRegex(`${requestor?.firstName} ${requestor?.lastName} `) + `(${requestor?.email})`;
    } else {
      return titleCaseRegex(requestor.name);
    }
  }

  return (
    <div id={id}>
      <div className={styles.cardContainer}>
        <Card className={styles.cardCcrHeader}>
          <CardHeader>
            <Marker hasMarker={isDiff('title')}>
              {title}
            </Marker>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={3}>
                <b>
                  Asset
              </b>
              </Col>
              <Col sm={9}>
                { asset ? asset : organization && organization.asset ? organization.asset.name : ''}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <b>
                  Justification
              </b>
              </Col>
              <Col sm={9}>
                <TextArea
                  type="textarea"
                  name="justificationText"
                  id="Justification"
                  value={justification}
                  highlight={isDiff('justification')}
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <b>
                  Requestor
              </b>
              </Col>
              <Col>
                <Marker hasMarker={isDiff('requestorID')}>
                  {getRequestorDisplay(requestor)}
                </Marker>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <b>
                  PO Update
              </b>
              </Col>
              <Col sm={9}>
                {purchaseOrderUpdateRequired ? 'Required' : 'Not Required'}
              </Col>
            </Row>
            {
              purchaseOrderUpdateRequired
              && (
                <Row>
                  <Col sm={3}>
                    <b>
                      PO Annual Costs
                </b>
                  </Col>
                  <Col sm={9}>
                    <Marker hasMarker={isDiff('annualEstimatedCost')}>
                      {`$${annualEstimatedCost}.00`}
                    </Marker>
                  </Col>
                </Row>
              )
            }
            <Row>
              <Col sm={3}>
                <b>
                  Equipment or Vehicle
              </b>
              </Col>
              <Col sm={9}>
                {equipmentVehicleInvolved ? 'Required' : 'Not Required'}
              </Col>
            </Row>
            {
              equipmentVehicleInvolved
              && [
                <Row key="costs">
                  <Col sm={3}>
                    <b>
                      Equipment/Vehicle Costs
                  </b>
                  </Col>
                  <Col sm={9}>
                    <Marker hasMarker={isDiff('equipmentVehicleEstimatedCost')}>
                      {`$${equipmentVehicleEstimatedCost}.00`}
                    </Marker>
                  </Col>
                </Row>,
                <Row key="description">
                  <Col sm={3}>
                    <b>
                      Equipment/Vehicle Details
                  </b>
                  </Col>
                  <Col sm={9}>
                    <TextArea
                      type="textarea"
                      name="equipmentDetailsText"
                      id="equipmentDetails"
                      value={equipmentDetails}
                      highlight={isDiff('equipmentDetails')}
                      readOnly
                    />
                  </Col>
                </Row>
              ]
            }
            {ccrStatus && ccrStatus.name === "Denied" && <Row key="comment">
              <Col sm={3} style={{ color: "red" }}>
                <b>
                  Comment
                    </b>
              </Col>
              <Col sm={9} style={{ color: "red" }}>
                <span className={cx({ [styles.highlighttext]: isDiff('comment') })}>
                  {ccrApprovers.find(a => a.comment) ? ccrApprovers.find(a => a.comment).comment : "-"}
                </span>
              </Col>
            </Row>
            }
          </CardBody>
        </Card>
        <Card className={styles.cardCcrApprover}>
          <CardHeader>
            Approvers
            {highlights?.ccrApprovers && <span className='em-c-badge util-float-right'>edited</span>}
          </CardHeader>
          <CardBody>
            <ApproverTable id={id} list={ccrApprovers} />
          </CardBody>
        </Card>
      </div>
      <div className={cx(styles.summaryTable, 'summary-table')}>
      <br />
        <TableLayout
          headers={table.headers}
          fields={table.fields}
          list={headcounts}
          highlights={highlights ? (mode === 'current') ? highlights.currentHeadcountRequests : highlights.previousHeadcountRequests : []}
        />
      </div>
    </div>
  );
}

CCRView.defaultProps = {
  usageType: {
    name: null,
  },
  highlights: {
    ccr: [],
    headcountRequests: []
  },
  organization: {
    name: null,
    asset: {
      name: null,
    },
  },
  table: {
    headers: [
      'Organization',
      'Usage Type',
      'Role',
      'Department',
      'Section',
      'Area',
      'Shift',
      'Schedulable',
      'Billable',
      'Start Date',
      'End Date',
      'Company',
      'Headcount Impacted'
    ],
    fields: [
      'organizationName',
      'usageTypeName',
      'positionRoleName',
      'departmentName',
      'sectionName',
      'areaName',
      'shiftName',
      'schedulable',
      'billable',
      'startDate',
      'endDate',
      'companyName',
      'totalHeadcount'
    ],
  }
};

export default CCRView;
