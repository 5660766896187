import { put, takeEvery, call } from 'redux-saga/effects';
import {  GET_CCR, setSelectedCCRLoadingAction, setSelectedCCRAction, resetSelectedCCRAction } from '../actions/ccrActions';
import { showErrorAction } from '../actions/errorMessageActions'
import { ccr as ccrAPI } from '../../api';

export function* queryCcrAsync(action) {
    try {
        yield put(resetSelectedCCRAction())
        yield put(setSelectedCCRLoadingAction(true))
        const ccr = yield call(ccrAPI.get.currentAndPrevious, action.payload);
        yield put(setSelectedCCRAction(ccr.data));
    } catch (err) {
        yield put(resetSelectedCCRAction())
        yield put(showErrorAction('Error Occured. Please contact application support', err))
    }
}

export function* watchGetCcr() {
    try {
        yield takeEvery(GET_CCR, queryCcrAsync);
    } catch (e) {
        console.log('Error ', e)
    }    
}

export default [watchGetCcr];