import { createSelector } from "reselect";
import { getReadonlyCcr } from "./ccrDataConvertors";
import { convertHTML } from "../../utils/string/htmlParse";
import * as R from "ramda";

import { getUser } from "./user";

const selectedCCR = (state) => state.selectedCCR;

export const getCcr = (state) =>
  getReadonlyCcr(
    state.selectedCCR.ccr,
    state.selectedCCR.previous,
    getUser(state)
  );

export const getSelectedHeadlines = createSelector(
  [selectedCCR],
  (selectedCCR) =>
    selectedCCR.previous ? ["Current CCR", "Previous CCR"] : ["Current CCR"]
);

export const getSelectedPrevious = createSelector(
  [selectedCCR],
  (selectedCCR) => getReadonlyCcr(selectedCCR.previous)
);

export const getSelectedLoading = createSelector(
  [selectedCCR],
  (selectedCCR) => selectedCCR.loading
);

export const getDiffPreviousCurrent = createSelector(
  [getCcr, getSelectedPrevious],
  (ccr, prv) => {
    if (!prv.ccrID) return null;
    return getHighlightDiff(ccr, prv);
  }
);

export const getHighlightDiff = (ccr, prv) => ({
  ccr: compareCcrProperties(ccr, prv),
  currentHeadcountRequests: calculateHeadcountRequestDiff(
    ccr.headcountRequests,
    prv.headcountRequests
  ),
  previousHeadcountRequests: calculateHeadcountRequestDiff(
    prv.headcountRequests,
    ccr.headcountRequests
  ),
  ccrApprovers: compareApprovers(ccr.ccrApprovers, prv.ccrApprovers),
});

export const compareCcrProperties = (ccr, prv) =>
  Object.keys(ccr).filter((x) => {
    if (["justification", "equipmentDetails"].includes(x))
      return (
        getComparableText(_.get(ccr, x)) !== getComparableText(_.get(prv, x))
      );
    return _.get(ccr, x) !== _.get(prv, x);
  });

export const compareApprovers = (currentApprovers, previousApprovers) =>
  !R.equals(
    currentApprovers.map((x) => parseInt(x.approverID)),
    previousApprovers.map((x) => parseInt(x.approverID))
  );

export const calculateHeadcountRequestDiff = (current, against) => {
  let diff = {};
  if (!current || current.length === 0) return diff;
  current.forEach((hr) => {
    const targetHR = against.filter(
      (x) =>
        x.headcountRequestID === hr.prevID || x.prevID === hr.headcountRequestID
    )[0];
    let diffKeys = [];
    if (!targetHR) diffKeys = Object.keys(hr);
    else
      diffKeys = Object.keys(hr).filter(
        (x) => _.get(hr, x) !== _.get(targetHR, x)
      );
    diff[hr.headcountRequestID] = diffKeys;
  });
  return diff;
};

const getComparableText = (text) =>
  text
    ? convertHTML(text)
        ?.replace(/ +|&nbsp;|&#160;|<p><br><\/p>/g, "")
        ?.trim()
    : "";
