import React, { PureComponent } from "react";
import { Table } from "reactstrap";
import UnityButton from "../../presentation/unity/Button";
import obj from "../../../utils/json/flatten";
import ConditionalCell from "./component/ConditionalCell";

export default class TableLayout extends PureComponent {
  render() {
    const { del, edit, layout, list, showContractors, isEditable } = this.props;
    const headers = showContractors
      ? layout.withContractor.headers
      : layout.withoutContractor.headers;
    const fields = showContractors
      ? layout.withContractor.fields
      : layout.withoutContractor.fields;
    return (
      <Table hover>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {list.map((item, count) => {
            const flatItem = obj.flatten(item);
            if (item !== undefined) {
              return (
                // eslint-disable-next-line
                <tr key={item.name + count}>
                  {fields.map((field) => (
                    <td>{_.get(flatItem, field)}</td>
                  ))}
                  <ConditionalCell
                    condition={
                      showContractors && item.userCompanies !== undefined
                    }
                  >
                    {item.userCompanies !== undefined
                      ? item.userCompanies
                          .map((company) => company.name)
                          .join(", ")
                      : null}
                  </ConditionalCell>
                  {isEditable ? (
                    <td>
                      <span className="float-right em-c-btn-group">
                        <UnityButton
                          size="small"
                          onClick={() => edit(item)}
                          icon="edit"
                          text="Edit"
                        />
                        <UnityButton
                          size="small"
                          onClick={() => del(item)}
                          icon="circle-x"
                          text="Delete"
                        />
                      </span>
                    </td>
                  ) : (
                    <td>
                      {item.isDeleted ? (
                        <UnityButton
                          className="float-right"
                          size="small"
                          onClick={() => del(item, true)}
                          icon="circle-plus"
                          text="Activate"
                        />
                      ) : (
                        <UnityButton
                          className="float-right"
                          size="small"
                          onClick={() => del(item, false)}
                          icon="circle-minus"
                          text="Deactivate"
                        />
                      )}
                    </td>
                  )}
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </Table>
    );
  }
}

TableLayout.defaultProps = {
  headers: [],
  list: [],
  fields: [],
  showContractors: false,
  isEditable: true,
};
