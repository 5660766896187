const local = {
  header_display: "Local",
  adal_tenant: 'd1ee1acd-bc7a-4bc4-a787-938c49a83906',
  adal_clientId: '6a89f355-f2c1-4d11-bdea-8453b927ec32',
  app_insights_instrumentation_key: 'f4f79a20-71c9-4ebc-9303-fbb8d27512c9',
  kmod_api_moc_base_url: 'http://localhost:53499',
};

const dev = {
  header_display: "Development",
  adal_tenant: 'd1ee1acd-bc7a-4bc4-a787-938c49a83906',
  adal_clientId: '6a89f355-f2c1-4d11-bdea-8453b927ec32',
  app_insights_instrumentation_key: 'f4f79a20-71c9-4ebc-9303-fbb8d27512c9',
  kmod_api_moc_base_url: 'https://kmod-api-moc-dev1.uda.xom.cloud'
};

const uat = {
  header_display: "Acceptance",
  adal_tenant: 'd1ee1acd-bc7a-4bc4-a787-938c49a83906',
  adal_clientId: '6109d8ce-b45f-4ff8-b147-cefe9e0dc40a',
  app_insights_instrumentation_key: 'f11bed2a-2f45-43d3-b6ce-68c6b631a3d1',
  kmod_api_moc_base_url: 'https://kmod-api-moc-uat1.uda.xom.cloud'
};

const prod = {
  header_display: "",
  adal_tenant: 'd1ee1acd-bc7a-4bc4-a787-938c49a83906',
  adal_clientId: 'ceee6bff-bea8-4cd3-9e1d-55576cc43dd8',
  app_insights_instrumentation_key: '6522ebf5-f8f9-49ee-8d4e-415c29ed58e4',
  kmod_api_moc_base_url: 'https://kmod-api-moc-prod.uda.xom.cloud'
};

const { REACT_APP_STAGE } = process.env;

let config = null;

switch (REACT_APP_STAGE) {
  case 'production':
  case 'prod':
    config = prod;
    break;
  case 'uat':
  case 'staging':
    config = uat;
    break;
  case 'local':
    config = local;
    break;
  case 'dev':
  case 'local_dev':
    config = dev;
    break;
  default:
    config = dev;
}

export default { ...config };
