import React, { Component } from 'react';
import CCRListView from './presentation';
import { connect } from 'react-redux';
import { ccr } from '../../../../api';
import states from '../../../../utils/state';
import { toDisplayDateTime } from '../../../../utils/date/reformat';
import { getDBUser } from '../../../../js/msal/msalHelper';
import { showErrorAction, user } from '../../../../redux/actions';

export class CCRList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ccrList: {
        ...states.isLoading,
        items: [],
        hasMore: true,
        pageIndex: 1,
      },
    };

    this.getCCRList = this.getCCRList.bind(this);
    this.GetCcrOnScroll = this.GetCcrOnScroll.bind(this);
    this.getCCRListWithFilter = this.getCCRListWithFilter.bind(this);
    this.formatCCRList = this.formatCCRList.bind(this);
    this.formatCCRListWithPage = this.formatCCRListWithPage.bind(this);
  }

  GetCcrOnScroll(){
    const {
      state: {
        ccrList,
      },
    } = this;
    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (ccrList.isLoading || ccrList.isError || !ccrList.hasMore) return;

    // Checks that the page has scrolled to the bottom
    const scrollviewOffsetY = document.documentElement.scrollTop;
    const scrollviewFrameHeight = document.documentElement.clientHeight;
    const scrollviewContentHeight = document.documentElement.scrollHeight;
    const sum = scrollviewOffsetY + scrollviewFrameHeight;
    if (sum <= scrollviewFrameHeight) {
      // the top reached
    } else if (sum + 2 >= scrollviewContentHeight) {
      this.getCCRList();
    }
  }

  componentDidMount() {
    this.getCCRList();
    window.addEventListener('scroll', this.GetCcrOnScroll)
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.GetCcrOnScroll)
  }

  getCCRList() {
    this.setState(prevState => ({
      ccrList: {
        ...prevState.ccrList,
        ...states.isLoading,
      },
    }));
    const {
      ccrList: { pageIndex },
    } = this.state;
    //const terms = filter && filter.terms;
    const usrDb = getDBUser();
    this.props.setUser(usrDb);
    if (document.getElementById('FilterRequestsAll') && document.getElementById('FilterRequestsMy Approval') && document.getElementById('FilterRequestsMy Requests')) {
      // eslint-disable-next-line
      const userID = usrDb.userID;
      if (document.getElementById('FilterRequestsMy Requests').checked && document.getElementById('FilterRequestsMy Requests')) {

        let searchTerms = (document.getElementById('searchInput').value);
        ccr.post.listFilterByUserType({
          "searchTerm": searchTerms,
          "filterType": "Requestor",
          "userID": userID,
          "pageNumber": pageIndex

        })
          .then((res) => {
            this.formatCCRListWithPage(res);
          }).catch((e) => {
            this.props.showError(`Reading ccr list`, e);
            this.setState({
              ccrList: {
                items: [],
              },
            });
          });
      } else if (document.getElementById('FilterRequestsMy Approval').checked) {
        const userID = usrDb.userID;
        let searchTerms = (document.getElementById('searchInput').value);
        ccr.post.listFilterByUserType({
          "searchTerm": searchTerms,
          "filterType": "Approver",
          "userID": userID,
          "pageNumber": pageIndex

        })
          .then((res) => {
            this.formatCCRList(res);
          }).catch((e) => {
            this.props.showError(`Reading ccr list`, e);
            this.setState({
              ccrList: {
                ...states.isError,
                items: [],
              },
            });
          });
      }
      else {
        let searchTerms = (document.getElementById('searchInput').value);
        const userID = usrDb.userID;
        ccr.post.listFilterByUserType({
          "searchTerm": searchTerms,
          "filterType": "",
          "userID": userID,
          "pageNumber": pageIndex

        })
          .then((res) => {
            this.formatCCRListWithPage(res);
          }).catch((e) => {
            this.props.showError(`Reading ccr list`, e);
            this.setState({
              ccrList: {
                ...states.isError,
                items: [],
              },
            })
          });
      }
    }
    else {
      const userID = usrDb.userID;
      if (document.getElementById('FilterRequestsMy Requests') && document.getElementById('FilterRequestsMy Requests').checked) {
        let searchTerms = (document.getElementById('searchInput').value);
        ccr.post.listFilterByUserType({
          "searchTerm": searchTerms,
          "filterType": "Requestor",
          "userID": userID,
          "pageNumber": pageIndex

        })
          .then((res) => {
            this.formatCCRListWithPage(res);
          }).catch((e) => {
            this.props.showError(`Reading ccr list`, e);
            this.setState({
              ccrList: {
                items: [],
              },
            });
          });
      }
      else {
        let searchTerms = (document.getElementById('searchInput') && document.getElementById('searchInput').value);
        if (!document.getElementById('searchInput')) {
          searchTerms = "";
        }
        ccr.post.listFilterByUserType({
          "searchTerm": searchTerms,
          "filterType": "",
          "userID": userID,
          "pageNumber": pageIndex

        })
          .then((res) => {
            this.formatCCRListWithPage(res);
          }).catch((e) => {
            this.props.showError(`Reading ccr list`, e);
            this.setState({
              ccrList: {
                ...states.isError,
                items: [],
              },
            })
          });
      }
    }
  }

  onSearchChange(value) {
    const { ccrList } = { ...this.state };
    const current = ccrList;
    current.filter = { ...current.filter, terms: value };
    this.setState({ ccrList: current });
  }
  getCCRListWithFilter() {
    const filter = {
      ...this.state.ccrList.filter,
      requester: document.getElementById('option-1') ? document.getElementById('option-1').checked : false,
      approver: document.getElementById('option-3') ? document.getElementById('option-3').checked : false,
    };

    this.setState({
      ccrList: {
        ...states.isLoading,
        hasMore: true,
        pageIndex: 1,
        items: [],
        filter,
      },
    }, function refresh() {
      this.getCCRList();
    });
  }

  formatCCRList(res) {
    if (res.data.length > 0) {
      res.data.forEach((ccrItem) => {
        // Disabling the param reassign here as it is safe
        /* eslint-disable no-param-reassign */
        ccrItem.dateModified = toDisplayDateTime(ccrItem.dateModified);
        ccrItem.dateCreated = toDisplayDateTime(ccrItem.dateCreated);
        /* eslint-enable no-param-reassign */
      });
      this.setState({
        ccrList: {
          ...states.isSuccess,
          hasMore: false,
          items: [...res.data],
        },
      });
    } else {
      this.setState({
        ccrList: {
          ...states.isSuccess,
          hasMore: false,
          items: [],
        },
      });
    }
  }
  formatCCRListWithPage(res) {
    let pageInfo = { currentPage: 0, nextPage: 'No' };
    if (res.headers.pagingheaders) {
      pageInfo = JSON.parse(res.headers.pagingheaders);
    }
    if (res.data.length > 0) {
      res.data.forEach((ccrItem) => {
        // Disabling the param reassign here as it is safe
        /* eslint-disable no-param-reassign */
        ccrItem.dateModified = toDisplayDateTime(ccrItem.dateModified);
        ccrItem.dateCreated = toDisplayDateTime(ccrItem.dateCreated);
        /* eslint-enable no-param-reassign */
      });
      const {
        ccrList: { filter },
      } = this.state;
      if (filter) {
        this.setState(prevState => ({
          ccrList: {
            ...states.isSuccess,
            hasMore: pageInfo.nextPage === 'Yes',
            pageIndex: pageInfo.currentPage + 1,
            items: [...prevState.ccrList.items, ...res.data],
            filter: prevState.ccrList.filter,
          },
        }));
      } else {
        this.setState(prevState => ({
          ccrList: {
            ...states.isSuccess,
            hasMore: pageInfo.nextPage === 'Yes',
            pageIndex: pageInfo.currentPage + 1,
            items: [...prevState.ccrList.items, ...res.data],

          },
        }));
      }
    } else {
      this.setState({
        ccrList: {
          ...states.isSuccess,
          hasMore: pageInfo.nextPage === 'Yes',
          items: [],
        },
      });
    }
  }

  render() {
    const { ccrList } = this.state;
    return (
      <div>
        <CCRListView
          onSearchChange={this.onSearchChange.bind(this)}
          {...ccrList}
          actions={
            { search: this.getCCRListWithFilter }
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  showError: showErrorAction,
  setUser: user
}
export default connect(null, mapDispatchToProps)(CCRList);
