import React from 'react';
import { Link } from 'react-router-dom';
import { isUser } from '../../../../js/msal/msalHelper';

const DFR = () => {
  if (isUser.admin() || isUser.contractLead()) {
    return (
      <li className="em-c-tree__item">
        <span className="em-c-tree__link em-c-tree__item--has--children" role="tab">
          <span className="em-c-tree__text em-c-tree__link--has--children">
            Daily Force Reports
          </span>
        </span>
        <ul className="em-c-tree__list em-js-tree-dropdown">
          <li className="em-c-tree__item">
            <Link
              href="/daily-force-report"
              to={{
                pathname: '/daily-force-report',
                state: { tab: '1' },
              }}
              className="em-c-tree__link"
            >
              Export
            </Link>
          </li>
          <li className="em-c-tree__item">
            <Link
              href="/daily-force-report"
              to={{
                pathname: '/daily-force-report',
                state: { tab: '2' },
              }}
              className="em-c-tree__link"
            >
              Import
            </Link>
          </li>
        </ul>
      </li>
    );
  }
  return null;
};

export default DFR;
