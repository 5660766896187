import React, { Component } from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap';

import {
  FormField,
} from "../../../presentation/Input";
import UnityButton from '../../../presentation/unity/Button';

export default class AddCompanyView extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  escapeHTML(value) {
    return value
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  render() {
    const {
      isOpen,
      hide,
      submit,
      setCompanyName,
      validCompanyName,
      isActivate,
      activate,
      companyName,
      compNameValidation,
    } = this.props;

    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => hide()} className="modal-xl">
          <ModalHeader toggle={() => hide()}>
            Add New Company
          </ModalHeader>
          <ModalBody>
            <FormField
              label="Company Name"
              id="CompanyName"
              isValid={validCompanyName}
              validationMessage={compNameValidation.validationMessage}
            >
              <Input
                name="CompanyName"
                id="CompanyName"
                maxLength={compNameValidation.maxLength}
                minLength={compNameValidation.minLength}
                placeholder="Please provide the name of the Company"
                invalid={!validCompanyName}
                value={companyName}
                onChange={(e) =>
                  setCompanyName(this.escapeHTML(e.target.value))
                }
              />
            </FormField>
            <FormField
              label="Activate"
              id="activate"
            >
              <Input
                type="checkbox"
                id="activate"
                onChange={() => activate()}
                aria-label="Checkbox for Badge Event Import"
                checked={isActivate}
              />
            </FormField>
          </ModalBody>
          <ModalFooter>
            <UnityButton color="primary" onClick={() => submit()} text="OK" disabled={!validCompanyName} />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
