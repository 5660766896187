import React, { useState, useRef, useEffect } from "react";
import AddOrReplaceApprover from "./AddOrReplaceApprover";
import ManageApproverTable from "./ManageApproverTable";
import * as R from "ramda";
import { Alert } from "reactstrap";

const ManageApprovers = (props) => {
  const {
    applicableApprovers,
    selectedApprovers,
    onChange,
    recommendedApprovers,
    setDefault,
    showDefaultHint,
    approvedApprovers,
  } = props;

  const [selected, setSelected] = useState(0);
  const approverTableRef = useRef(null);
  const actionPanelRef = useRef(null);

  const handleClickOutside = (event) => {
    let isClickOutsideTable =
      approverTableRef.current &&
      !approverTableRef.current.contains(event.target);
    let isClickOutsidePanel =
      actionPanelRef.current && !actionPanelRef.current.contains(event.target);
    if (isClickOutsideTable && isClickOutsidePanel && selected) {
      setSelected(null);
    }
  };

  useEffect(() => {
    if (
      selectedApprovers.length === 0 &&
      recommendedApprovers?.length > 0 &&
      setDefault
    ) {
    }
  }, [selectedApprovers.length, recommendedApprovers, setDefault]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const onReorder = (list, srcIdx, dstIdx) => {
    const result = Array.from(list);
    const [removed] = result.splice(srcIdx, 1);
    result.splice(dstIdx, 0, removed);

    onChange(result);
  };

  const onReplace = (userID, targetUserID) => {
    const idxTarget = R.findIndex(R.propEq("userID", Number(targetUserID)))(
      selectedApprovers
    );
    const idxSrc = R.findIndex(R.propEq("userID", Number(userID)))(
      applicableApprovers
    );
    if (idxTarget >= 0 && idxSrc >= 0) {
      const userClone = R.clone(applicableApprovers.at(idxSrc));
      let approversClone = [...selectedApprovers];
      _.assign(approversClone, { [idxTarget]: userClone });

      onChange(approversClone);
      setSelected(0);
    }
  };

  const getApplicableApprovers = (applicableApprovers, selectedApprovers) => {
    return applicableApprovers.filter(
      (user) => !selectedApprovers.some((x) => x.userID === user.userID)
    );
  };

  const onAdd = (userID) => {
    const idx = R.findIndex(R.propEq("userID", Number(userID)))(
      applicableApprovers
    );
    if (idx >= 0) {
      const clone = R.clone(applicableApprovers.at(idx));
      onChange(R.append(clone, selectedApprovers));
    }
  };

  const onRemove = (userID) => {
    const idx = R.findIndex(R.propEq("userID", userID))(selectedApprovers);
    if (idx > -1)
      onChange(selectedApprovers.filter((user) => user.userID !== userID));
  };

  const onSelect = (userID) => {
    setSelected(userID);
  };

  return (
    <div>
      <div className="em-u-display-flex util-justify-center">
        <div className="em-u-margin" ref={approverTableRef}>
          <ManageApproverTable
            approved={approvedApprovers}
            approvers={selectedApprovers}
            onReorder={onReorder}
            onRemove={onRemove}
            onSelect={onSelect}
            selectedRow={selected}
          />
        </div>
        <div className="em-u-margin" ref={actionPanelRef}>
          <AddOrReplaceApprover
            mode={selected ? "replace" : "add"}
            applicableApprovers={getApplicableApprovers(
              applicableApprovers,
              selectedApprovers
            )}
            selectedUser={selected}
            onAdd={onAdd}
            onReplace={onReplace}
          />
        </div>
      </div>
      {showDefaultHint && (
        <Alert
          color="info"
          style={{ position: "fixed", top: "70px", right: "30px" }}
        >
          Recommended Approvers were automatically picked for you!
        </Alert>
      )}
    </div>
  );
};

export default ManageApprovers;
