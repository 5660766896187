import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Col,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import UnityButton from '../../../../../presentation/unity/Button';
import BreadcrumbNav from '../../../../../presentation/Breadcrumb';

const breadcrumb = [
  { name: 'Home', link: '/' },
  { name: 'Reports', isActive: true },
  { name: 'Work Center', isActive: true },
];

export default class WorkCenterView extends Component {
  render() {
    const {
      invalidFile,
      invalidCheck,
      handleFileUpload,
      submit,
      extract,
    } = this.props;
    return (
      <div>
        <BreadcrumbNav items={breadcrumb} />
        <Form row>
          <FormGroup row>
            <Label for="workCenterExtract" sm={10}>
            Click Extract to obtain current approved organization to enter Work Center
            </Label>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <UnityButton
                text="Extract"
                onClick={() => extract()}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="workCenterUpload" sm={10}>
              Select file to enter Work Center number to approved organization
            </Label>
          </FormGroup>
          <FormGroup row>
            <Col sm={10}>
              <Input
                type="file"
                name="file"
                id="workcenterUpload"
                onChange={e => handleFileUpload(e.target.files)}
                invalid={invalidFile}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <FormFeedback>
                Please upload an xlsx file.
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <UnityButton onClick={() => submit()} disabled={invalidCheck} text="Upload" />
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
