
export function validateRequestForm(values, validations) {
    validations.title.isValid = !!values.title;
    validations.title.validationMessage = 'Please add a descriptive title to your CCR request.';
    validations.justification.isValid = !!values.justification && values.justification !== '<p><br></p>';
    validations.justification.validationMessage = 'Please justify the request.';
    validations.annualEstimatedCost.isValid = !(values.purchaseOrderUpdateRequired && !values.annualEstimatedCost);
    validations.annualEstimatedCost.validationMessage = 'Please enter a price, or de-select the PO Update requirement.';
    validations.equipmentVehicleEstimatedCost.isValid = !(values.equipmentVehicleInvolved && !values.equipmentVehicleEstimatedCost);
    validations.equipmentVehicleEstimatedCost.validationMessage = 'Please enter a price, or de-select the Equipment or Vehicle requirement.';
    validations.equipmentDetails.isValid = !(values.equipmentVehicleInvolved && !values.equipmentDetails);
    validations.equipmentDetails.validationMessage = 'Please enter details, or de-select the Equipment or Vehicle requirement.';
    return validations;
}
