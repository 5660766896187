import config from '../../config';
import resolver from './root';

const url = `${config.kmod_api_moc_base_url}/api`;

export const manageCalls = {
  assets: {
    list: () => resolver(url, 'get', '/assets?includeDeleted=true'),
    individual: assetID => resolver(url, 'get', `/assets/${assetID}`),
    create: (assetID, name) => resolver(url, 'post', '/assets/', { assetID, name }),
    update: (assetID, asset, isDeleted) => resolver(url, 'put', `/assets/${assetID}`, { ...asset, isDeleted }),
    delete: asset => resolver(url, 'delete', `/assets/${asset.assetID}`),
  },
  companies: {
    list: () => resolver(url, 'get', '/companies/'),
    individual: companyID => resolver(url, 'get', `/companies/${companyID}`),
    create: (name, priority, importBadgeEvents) => resolver(url, 'post', '/companies/', { name, priority, importBadgeEvents }),
    update: (companyID, name, priority, importBadgeEvents) => resolver(url, 'put', `/companies/${companyID}`, {
      companyID,
      name,
      priority,
      importBadgeEvents,
    }),
    batchUpdate: companyList => resolver(url, 'put', '/companies', companyList),
    delete: company => resolver(url, 'delete', `/companies/${company.companyID}`),
  },
  organizations: {
    list: () => resolver(url, 'get', '/organizations?includeDeleted=true'),
    individual: organizationID => resolver(url, 'get', `/organizations/${organizationID}`),
    create: (name, assetID) => resolver(url, 'post', '/organizations/', { assetID, name }),
    update: (organizationID, organization, isDeleted) => resolver(url, 'put', `/organizations/${organizationID}`, {
      ...organization,
      isDeleted,
    }),
    delete: organization => resolver(url, 'delete', `/organizations/${organization.organizationID}`),
  },
  areas: {
    list: () => resolver(url, 'get', '/areas?includeDeleted=true'),
    individual: areaID => resolver(url, 'get', `/areas/${areaID}`),
    create: (name, sectionID) => resolver(url, 'post', '/areas/', { name, sectionID }),
    update: (areaID, area, isDeleted) => resolver(url, 'put', `/areas/${areaID}`, { ...area, isDeleted }),
    delete: area => resolver(url, 'delete', `/areas/${area.areaID}`),
  },
  departments: {
    list: () => resolver(url, 'get', '/departments?includeDeleted=true'),
    individual: departmentID => resolver(url, 'get', `/departments/${departmentID}`),
    create: (name, organizationID) => resolver(url, 'post', '/departments/', { name, organizationID }),
    update: (departmentID, department, isDeleted) => resolver(url, 'put', `/departments/${departmentID}`, { ...department, isDeleted }),
    delete: department => resolver(url, 'delete', `/departments/${department.departmentID}`),
  },
  roles: {
    list: () => resolver(url, 'get', '/positionroles?includeDeleted=true'),
    individual: positionRoleID => resolver(url, 'get', `/positionroles/${positionRoleID}`),
    create: (name, organizationPositionRoles) => resolver(url, 'post', '/positionroles/', { name, organizationPositionRoles }),
    update: (positionRoleID, role, isDeleted) => {
      const { name } = role;
      return resolver(url, 'put', `/positionroles/${positionRoleID}`, { positionRoleID, name, isDeleted });
    },
    delete: role => resolver(url, 'delete', `/positionroles/${role.positionRoleID}`),
  },
  sections: {
    list: () => resolver(url, 'get', '/sections?includeDeleted=true'),
    individual: sectionID => resolver(url, 'get', `/sections/${sectionID}`),
    create: (name, departmentID) => resolver(url, 'post', '/sections/', { name, departmentID }),
    update: (sectionID, section, isDeleted) => resolver(url, 'put', `/sections/${sectionID}`, { ...section, isDeleted }),
    delete: section => resolver(url, 'delete', `/sections/${section.sectionID}`),
  },
  usageTypes: {
    list: () => resolver(url, 'get', '/usagetypes?includeDeleted=true'),
    individual: usageTypeID => resolver(url, 'get', `/usagetypes/${usageTypeID}`),
    create: name => resolver(url, 'post', '/usagetypes/', { name }),
    update: (usageTypeID, usageType, isDeleted) => resolver(url, 'put', `/usagetypes/${usageTypeID}`, { ...usageType, isDeleted }),
    delete: usageType => resolver(url, 'delete', `/usagetypes/${usageType.usageTypeID}`),
  },
};

export default { manageCalls };
