import React, { Component, Fragment } from 'react';
import UnityButton from '../../../../presentation/unity/Button';
import BreadcrumbNav from '../../../../presentation/Breadcrumb';
import Sortable from '../../../../containers/Table/Sortable';
import TableLayout from '../../../../containers/Table/AdminTable';
import AddDepartment from '../../../../containers/Modal/AddDepartment';
import AddUsage from '../../../../containers/Modal/AddUsage';
import AddAsset from '../../../../containers/Modal/AddAsset';
import AddRole from '../../../../containers/Modal/AddRole';
import AddArea from '../../../../containers/Modal/AddArea';
import AddSection from '../../../../containers/Modal/AddSection';
import AddOrganization from '../../../../containers/Modal/AddOrganization';
import AddCompany from '../../../../containers/Modal/AddCompany';
import ActivateCompany from '../../../../containers/Modal/ActivateCompany';
import Alert from '../../../../presentation/Alert';

const tableParams = {
  company: {
    headers: ['', 'Prioirty', 'Name', ''],
    fields: ['priority', 'name'],
  },
  organizations: {
    headers: ['Name', 'Asset', ''],
    fields: ['name', 'asset.name'],
  },
  sections: {
    headers: ['Name', 'Department', 'Organization', 'Asset', ''],
    fields: ['name', 'department.name', 'department.organization.name', 'department.organization.asset.name'],
  },
  areas: {
    headers: ['Name', 'Section', 'Department', 'Organization', 'Asset', ''],
    fields: ['name', 'section.name', 'section.department.name', 'section.department.organization.name', 'section.department.organization.asset.name'],
  },
  departments: {
    headers: ['Name', 'Organization', 'Asset', ''],
    fields: ['name', 'organization.name', 'organization.asset.name'],
  },
  roles: {
    headers: ['Name', 'Organization (Asset)', ''],
    fields: ['name', 'organizations'],
  },
  other: {
    headers: ['Name', ''],
    fields: ['name'],
  },
};

export default class ManageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalActivate: false,
    };
    this.showModal = this.showModal.bind(this);
    this.showModalActivate = this.showModalActivate.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  showModalActivate() {
    this.setState({ showModalActivate: true });
  }

  hideModal(isUpdated) {
    const { update } = this.props;
    this.setState({
      showModal: false,
      showModalActivate: false
    });
    if (isUpdated === true) {
      update();
    }
  }

  render() {
    const {
      alert,
      title,
      list,
      max,
      del,
      updateCompanies,
      priority,
      importSelected,
      isUpdated: isCompanyUpdated,
    } = this.props;
    const { showModal } = this.state;
    const { showModalActivate } = this.state;
    const breadcrumb = [
      { name: 'Home', link: '/' },
      { name: 'Admin', isActive: true },
      { name: `Manage ${title}`, isActive: true },
    ];
    let table = null;
    let modal = null;
    if (title === 'Companies') {
      table = (
        <Sortable
          headers={tableParams.company.headers}
          keys={tableParams.company.fields}
          rows={list.filter(item => item.importBadgeEvents === true)}
          priority={priority}
          importSelected={importSelected}
          deactivate={item => del(item)}
        />
      );
    } else if (['Sections', 'Areas', 'Roles', 'Departments', 'Organizations'].indexOf(title) > -1) {
      table = (
        <TableLayout
          layout={{
            withoutContractor: {
              headers: tableParams[title.toLowerCase()].headers,
              fields: tableParams[title.toLowerCase()].fields,
            },
          }}
          showContractors={false}
          headers={tableParams[title.toLowerCase()].headers}
          fields={tableParams[title.toLowerCase()].fields}
          list={list}
          isEditable={false}
          del={(item, isActivate) => del(item, isActivate)}
        />
      );
    } else {
      table = (
        <TableLayout
          layout={{
            withoutContractor: {
              headers: tableParams.other.headers,
              fields: tableParams.other.fields,
            },
          }}
          showContractors={false}
          headers={tableParams.other.headers}
          fields={tableParams.other.fields}
          list={list}
          isEditable={false}
          del={(item, isActivate) => del(item, isActivate)}
        />
      );
    }
    if (title === 'Departments') {
      modal = (
        <AddDepartment
          isOpen={showModal}
          hide={isUpdated => this.hideModal(isUpdated)}
          show={() => this.showModal()}
        />
      );
    }
    if (title === 'Usage Types') {
      modal = (
        <AddUsage
          isOpen={showModal}
          hide={isUpdated => this.hideModal(isUpdated)}
          show={() => this.showModal()}
        />
      );
    }
    if (title === 'Assets') {
      modal = (
        <AddAsset
          isOpen={showModal}
          hide={isUpdated => this.hideModal(isUpdated)}
          show={() => this.showModal()}
        />
      );
    }
    if (title === 'Roles') {
      modal = (
        <AddRole
          isOpen={showModal}
          hide={isUpdated => this.hideModal(isUpdated)}
          show={() => this.showModal()}
        />
      );
    }
    if (title === 'Areas') {
      modal = (
        <AddArea
          isOpen={showModal}
          hide={isUpdated => this.hideModal(isUpdated)}
          show={() => this.showModal()}
        />
      );
    }
    if (title === 'Sections') {
      modal = (
        <AddSection
          isOpen={showModal}
          hide={isUpdated => this.hideModal(isUpdated)}
          show={() => showModal()}
        />
      );
    }
    if (title === 'Organizations') {
      modal = (
        <AddOrganization
          isOpen={showModal}
          hide={isUpdated => this.hideModal(isUpdated)}
          show={() => this.showModal()}
        />
      );
    }
    if (title === 'Companies') {

      if (showModal) {
        modal = (
          <AddCompany
            isOpen={showModal}
            list={list.filter(item => item.importBadgeEvents === false)}
            max={max}
            hide={isUpdated => this.hideModal(isUpdated)}
            show={() => this.showModal()}
          />
        );
      }
      else if (showModalActivate) {
        modal = (
          <ActivateCompany
            isOpen={showModalActivate}
            list={list.filter(item => item.importBadgeEvents === false)}
            max={max}
            hide={isUpdated => this.hideModal(isUpdated)}
            show={() => this.showModalActivate()}
          />
        );

      }


    }
    return (
      <div className="homeContainer ">
        <BreadcrumbNav items={breadcrumb} />
        <div className="ccrContainer">
          {modal}
          <h3 className="header">
            {`Manage ${title}`}
          </h3>
          <div className="em-c-btn-group float-right">
            <UnityButton onClick={() => this.showModal()} text={`Add ${title}`} className="float-right" icon="plus" />
            {title === 'Companies'
              ?
              <Fragment>
                <UnityButton onClick={() => this.showModalActivate()} text={`Activate ${title}`} className="float-right" icon="check" />
                <UnityButton onClick={() => updateCompanies()} text={`Update ${title}`} className="float-right" icon="upload" disabled={!isCompanyUpdated} color="primary" />
              </Fragment>
              : null}
          </div>
          <br />
          <br />
          <br />
          <Alert {...alert} />
          {table}
        </div>
      </div>
    );
  }
}
