import React, { Component, Fragment } from 'react';
import { workcenter } from '../../../../../api';
import config from '../../../../../config';
import WorkCenterView from './presentation';
import UploadAlert from '../../../../presentation/Alert';
import DownloadError from '../../../../presentation/Error';
import Loading from '../../../../presentation/Loading';
import { download } from '../helper';

const baseCCRURL = `${config.kmod_api_moc_base_url}/api`;

const uploadAlert = {
  successMessage: 'Work Center Report successfully uploaded.',
  failMessage: 'There was an error uploading your Work Center Report. Please verify the file you\'ve selected is correct, and try again.',
};

const downloadAlert = 'There was an error downloading your Work Center Report. Please try again.';

export default class WorkCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workCenterLink: `${baseCCRURL}/headcounts/WorkCenterExport`,
      workCenterReport: null,
      invalidFile: false,
      importFile: null,
      invalidCheck: true,
      isSuccess: true,
      showAlert: false,
      showWarning: false,
      isLoading: false,
      loadingMessage: 'Uploading the Work Center Report',
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.submit = this.submit.bind(this);
    this.export = this.export.bind(this);
    this.invalid = this.invalid.bind(this);
  }

  async handleFileUpload(importFiles) {
    const importFile = importFiles[importFiles.length - 1];
    await this.setState({
      importFile,
      invalidFile: importFile !== undefined ? importFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : true,
    });
    this.invalid(true);
  }

  async submit() {
    const { importFile } = this.state;
    if (this.invalid(false)) {
      this.setState({ isLoading: true });
      const formData = new FormData();
      formData.append('ImportFile', importFile);
      const isSuccess = await workcenter.post.import(formData).then(() => true).catch(() => false);
      this.setState({ isLoading: false, isSuccess, showAlert: true });
    }
  }

  async export() {
    await workcenter.get.export()
      .then(res => download(res.data, res.headers))
      .catch(() => this.setState({ showWarning: true }));
  }

  async invalid(check) {
    const {
      importFile,
    } = this.state;
    const invalidFile = importFile === null || importFile === undefined;
    const invalidCheck = invalidFile;
    if (!check) {
      this.setState({
        invalidFile,
      });
    } else {
      await this.setState({ invalidCheck });
    }
    return invalidCheck;
  }

  dismissAlert() {
    this.setState({ showAlert: false });
  }

  dismissWarning() {
    this.setState({ showWarning: false });
  }

  render() {
    const {
      isSuccess,
      showAlert,
      showWarning,
      isLoading,
      loadingMessage,
    } = this.state;
    return (
      <Fragment>
        <Loading isShowing={isLoading} text={loadingMessage} />
        <WorkCenterView
          {...this.state}
          handleFileUpload={this.handleFileUpload}
          submit={this.submit}
          extract={this.export}
        />
        <UploadAlert
          {...uploadAlert}
          show={showAlert}
          dismiss={() => this.dismissAlert()}
          isSuccess={isSuccess}
        />
        <DownloadError
          copy={downloadAlert}
          show={showWarning}
          dismiss={() => this.dismissWarning()}
        />
      </Fragment>
    );
  }
}
