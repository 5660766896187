export const MANAGE_APPROVERS_LOAD = 'MANAGE_APPROVERS_LOAD';
export const UPDATE_SELECTED_APPROVERS = 'UPDATE_SELECTED_APPROVERS';
export const SET_DEFAULT_APPROVERS = 'SET_DEFAULT_APPROVERS';


export const loadApprovers = data => ({
    type: MANAGE_APPROVERS_LOAD,
    payload: data,
});

export const updateSelectedApprovers = newList => ({
    type: UPDATE_SELECTED_APPROVERS,
    payload: newList
});

export const setDefaultApprovers = approvers => ({
    type: SET_DEFAULT_APPROVERS,
    payload: approvers
});