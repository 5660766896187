import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { hideErrorAction } from '../../../redux/actions';

export class ErrorMessage extends PureComponent {
    hideError() {
        this.props.hideError();
    }
    render() {
        const { showError, errorMessage } = this.props;
        return (<Alert color="danger" isOpen={showError} toggle={this.hideError.bind(this)}>
            <h4>Encountered an error while {errorMessage}. </h4>
            <h6>Please contact the application support team</h6>
        </Alert>);
    }
}

const mapStateToProps = (state) => ({
    ...state.errorState
});

const mapDispatchToProps = {
    hideError: hideErrorAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);