import moment from 'moment';

export const toDisplayDate = date => moment.utc(date).format('ll');

export const toDisplayDateTime = date => moment.utc(date).local().format('lll');

export const toAPIDate = date => date.startOf('day').format('YYYY-MM-DD');


const reformat = {
  toDisplayDate,
  toAPIDate,
};

export default reformat;
