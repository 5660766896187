import React, { Component, Fragment } from 'react';
import {
  find,
  propEq,
} from 'ramda';
import { user as userAPI, dfr } from '../../../../../api';
import HistoricalDFRView from './presentation';
import Error from '../../../../presentation/Error';
import Loading from '../../../../presentation/Loading';
import { download } from '../helper';
import moment from 'moment';

export default class HistoricalDFR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      company: null,
      isError: false,
      errorMessage: '',
      isLoading: false,
      loadingMessage: 'Loading Companies',  
      startDate: null,
      endDate: null, 
    };
    this.setSelected = this.setSelected.bind(this);
    this.getCompanies = this.getCompanies.bind(this);
    this.getReport = this.getReport.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.setDateSelected = this.setDateSelected.bind(this);
  }

  componentDidMount() {
    this.getCompanies();
    this.setState({ company: null });
  }

  async getCompanies() {
    const companies = await userAPI.get.activeCompanies().catch(() => []);
    this.setState({ companies: companies.data });
  }

  setSelected(companySelection) {
    const { companies } = this.state;
    const company = find(propEq('name', companySelection))(companies);
    if (company !== null) {
      this.setState({ company });
    } else {
      this.setState({ company: null });
    }
  }

  setDateSelected(data){

    if (moment.isMoment(data.startDate)){      
      this.setState({
        startDate: data.startDate
      })
    }

    if (moment.isMoment(data.endDate)){
      this.setState({
        endDate: data.endDate
      })
    }
  
  }

  getReport() {
    
    const { company, startDate, endDate } = this.state;

    if (company && startDate && endDate){

      let start = startDate.format("YYYY-MM-DD");
      let end = endDate.format("YYYY-MM-DD");


      this.setState({ isLoading: true });
      dfr.get.historical(company.companyID, start, end)
        .then((res) => {
          download(res.data, res.headers);
          this.setState({ isLoading: false, isError: false, errorMessage: '' });
        })
        .catch((err) => {          
            this.setState({ isLoading: false, isError: true, errorMessage: `There are no personnel assigned in the system for ${company.name} on ${start} to ${end} ` });
        });
    }
    else{
      this.setState({ isLoading: false, isError: true, errorMessage: `Please select a company, start date and end date.` });
    }
  }

  dismissError() {
    this.setState({ isError: false, errorMessage: '' });
  }

  render() {
    const {
      isError,
      errorMessage,
      isLoading,
      loadingMessage,
    } = this.state;
    return (
      <Fragment>
        <Loading isShowing={isLoading} text={loadingMessage} />
        <HistoricalDFRView
          {...this.state}
          startDate={this.startDate}
          endDate={this.endDate}
          setSelected={this.setSelected}
          setDateSelected={this.setDateSelected}
          getReport={this.getReport}
        />
        <Error show={isError} dismiss={this.dismissError} copy={errorMessage} />
      </Fragment>
    );
  }
}
