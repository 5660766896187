import React, { Component } from 'react';
import DailyForceReportView from './presentation';
import Loading from '../../presentation/Loading';
import { user as userAPI } from '../../../api';

export default class DailyForceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      isLoading: false,
    };
    this.getCompanies = this.getCompanies.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getCompanies();
  }

  async getCompanies() {
    const companies = await userAPI.get.activeCompanies().catch(() => []);
    this.setState({ companies: companies.data, isLoading: false });
  }

  render() {
    const { location: { state } } = this.props;
    const { isLoading } = this.props;
    let passedTab = null;
    if (state !== undefined && state.tab !== undefined) {
      passedTab = state.tab;
    }
    return [
      <Loading isShowing={isLoading} text="Loading the Companies..." key="loader" />,
      <DailyForceReportView tab={passedTab} {...this.state} key="view" />,
    ];
  }
}
