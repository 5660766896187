const initialState = null;

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, ...action.user, roleNames: action.user.userRoles.map(x => x.role.name) };
    default:
      return state;
  }
};

export default user;
