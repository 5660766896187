import * as Actions from '../../actions/editCcrActions';

import { getCcrFromApiData } from './manageCcr.logic';
import { getDBUser } from '../../../js/msal/msalHelper';

export const initialState = {};

const emptyCcr = () => ({
    ccrID: 0,
    title: '',
    approverID: null,
    justification: null,
    prevID: null,
    nextID: null,
    equipmentDetails: null,
    equipmentVehicleEstimatedCost: 0,
    equipmentVehicleInvolved: false,
    purchaseOrderUpdateRequired: false,
    annualEstimatedCost: 0,
    requestorID: (getDBUser()).userID
});

const manageCCR = (state = initialState, action) => {
    switch (action.type) {
        case Actions.EDIT_CCR_SET_DATA:
            const ccr = action.payload.ccr ? getCcrFromApiData(action.payload.ccr.data) : emptyCcr();
            const lastApprovedCcr = action.payload.lastApproved ? getCcrFromApiData(action.payload.lastApproved.data) : null;
            return {
                ...state,
                ccr: Object.assign({}, ccr),
                originalCcr: Object.assign({}, ccr),
                previousApprovedCcr: lastApprovedCcr ? Object.assign({}, lastApprovedCcr) : null,
                referenceData: action.payload.endorsors
            }
        case Actions.EDIT_CCR_UPDATE_REQUIREMENTS:
            return {
                ...state,
                ccr: Object.assign({}, state.ccr, action.payload)
            };
        default:
            return state;
    }
}

export { manageCCR };
