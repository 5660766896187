import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { sortBy, prop } from "ramda";
import ManageView from "./presentation";
import Loading from "../../../presentation/Loading";
import toTitleCase from "../../../../utils/string/to-title-case";
import { manage } from "../../../../api";

const sort = sortBy(prop("priority"));

const getMax = (oldList) =>
  sort(
    oldList.filter((i) => i.priority > 0 && i.importBadgeEvents === true)
    // eslint-disable-next-line
  ).reduce((prev, curr) => {
    return prev.priority > curr.priority && curr.importBadgeEvents === true
      ? prev.priority
      : curr.priority;
  });

const getCallType = (type) => {
  if (type === "usage-types") {
    return "usageTypeID";
  }
  if (type === "roles") {
    return "positionRoleID";
  }
  return `${type.slice(0, -1)}ID`;
};

const alertText = {
  successMessage: "Companies successfully updated.",
  failMessage: "Error updating companies. Please try again.",
};

const companyRemoveAlertText = {
  successMessage: "Company successfully removed.",
  failMessage: "Error removing company. Please try again.",
};

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      initialList: [],
      isUpdated: false,
      max: 0,
      showAlert: false,
      isSuccess: false,
      companyAlert: false,
      isLoading: false,
      loadingText: "",
    };
    this.toggleActivation = this.toggleActivation.bind(this);
    this.update = this.update.bind(this);
    this.increasePrioirity = this.increasePrioirity.bind(this);
    this.decreasePriority = this.decreasePriority.bind(this);
    this.importSelected = this.importSelected.bind(this);
    this.updateCompanies = this.updateCompanies.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
  }

  componentDidMount() {
    this.get();
  }

  update() {
    this.get();
  }

  async get() {
    const {
      match: {
        params: { type },
      },
    } = this.props;
    const title =
      type !== "dfrs" ? toTitleCase(type.split("-").join(" ")) : "DFRs";
    this.setState({ loadingText: `Loading ${title}`, isLoading: true });

    const list = await _.get(manage.get, type)();

    let max = 0;
    if (list !== null && list.data !== "") {
      if (type === "companies") {
        const top = sort(list.data.filter((i) => i.priority > 0));
        if (top.length > 1) {
          max = getMax(top);
        }
        if (top.length === 1) {
          max = 1;
        }
        const bottom = list.data.filter((i) => i.priority <= 0);
        list.data = [...top, ...bottom];
      }
      // Adding re-assignment to ensure organization/role makes sense.
      /* eslint-disable no-return-assign */
      /* eslint-disable no-param-reassign */
      if (type === "roles") {
        list.data.map(
          (role) =>
            (role.organizations = role.organizationPositionRoles
              .map(
                (org) =>
                  `${org.organization.name} (${org.organization.asset.name})`
              )
              .join(", "))
        );
      }
      /* eslint-enable no-param-reassign */
      /* eslint-enable no-return-assign */
      this.setState({
        list: list.data,
        initialList: list.data,
        max,
        isLoading: false,
      });
    }
  }

  async toggleActivation(item, isActivate) {
    const {
      match: {
        params: { type },
      },
    } = this.props;
    let response = true;
    if (!isActivate) {
      // eslint-disable-next-line
      response = window.confirm(
        `Are you sure you want to deactivate ${item.name} from ${type}? It will not be available to users anymore.`
      );
    }

    if (response === true) {
      const loadingText = isActivate ? "Activating" : "Deactivating";
      this.setState({ loadingText, isLoading: true });
      let updated = null;
      if (type === "companies") {
        updated = await manage.put
          .company(item.companyID, item.name, null, false)
          .then(() => this.updateCompanies(item.priority))
          .catch(() =>
            this.setState({
              isSuccess: false,
              showAlert: true,
              companyAlert: true,
              isLoading: false,
            })
          );
      } else {
        const callType = getCallType(type);
        const singularizedType = type.slice(0, -1);
        // removes 's' at the end of the word
        // example: roles -> role
        if (!manage.put.hasOwnProperty(singularizedType)) {
          this.setState({ isLoading: false });
          return null;
        }
        updated = await manage.put[singularizedType](
          _.get(item, callType),
          item,
          !isActivate
        ).catch(() => {
          this.setState({ isLoading: false });
          return null;
        });
      }
      if (updated !== null) {
        this.get(type);
      }
    }
  }

  async checkList(list) {
    const serverList = await manage.get.companies();
    const sortedList = sort(serverList.data.filter((i) => i.priority > 0));
    this.setState({
      isUpdated: JSON.stringify(list) !== JSON.stringify(sortedList),
    });
  }

  // Manage Companies Only
  increasePrioirity(item) {
    const { list: oldList } = this.state;
    let newList = [];
    let max = getMax(oldList);
    if (item.priority > 1) {
      oldList.forEach((e) => {
        if (e.priority === item.priority - 1) {
          newList.push({
            ...e,
            priority: e.priority + 1,
          });
        } else if (e.priority === item.priority) {
          newList.push({
            ...e,
            priority: e.priority - 1,
          });
        } else {
          newList.push(e);
        }
      });
    } else if (
      item.priority !== 1 ||
      item.priority === -1 ||
      item.priority === null
    ) {
      const index = oldList.findIndex(
        (i) => JSON.stringify(i) === JSON.stringify(item)
      );
      max += 1;
      _.assign(oldList, {
        [index]: {
          ...item,
          priority: max,
        },
      });
      newList = oldList;
    } else {
      newList = oldList;
    }
    const top = sort(newList.filter((i) => i.priority > 0));
    const bottom = newList.filter((i) => i.priority <= 0);
    const list = [...top, ...bottom];
    this.setState({ list, max });
    this.checkList(list);
  }

  // Manage Companies Only
  decreasePriority(item) {
    const { list: oldList } = this.state;
    const newList = [];
    if (
      item.priority < oldList.filter((i) => i.importBadgeEvents === true).length
    ) {
      oldList.forEach((e) => {
        if (e.priority === item.priority + 1) {
          newList.push({
            ...e,
            priority: e.priority - 1,
          });
        } else if (e.priority === item.priority) {
          newList.push({
            ...e,
            priority: e.priority + 1,
          });
        } else {
          newList.push(e);
        }
      });
      const top = sort(newList.filter((i) => i.priority > 0));
      const bottom = newList.filter((i) => i.priority <= 0);
      const list = [...top, ...bottom];
      this.setState({ list });
      this.checkList(list);
    }
  }

  importSelected(item) {
    const { list } = this.state;
    const index = list.findIndex((i) => i.companyID === item.companyID);
    list.at(index).importBadgeEvents = !list.at(index).importBadgeEvents;
    this.setState({ list });
    this.checkList(list);
  }

  updateCompanies(deletedIndex) {
    let { list } = this.state;
    this.setState({ loadingText: "Updating Companies", isLoading: true });
    if (deletedIndex) {
      list = list
        .filter((company) => company.priority > deletedIndex)
        .map(
          // eslint-disable-next-line
          (company) => ({ ...company, priority: (company.priority -= 1) })
        );
    }
    manage.put["batch-company"](list)
      .then(() => {
        if (!deletedIndex) {
          this.setState({
            isSuccess: true,
            showAlert: true,
            companyAlert: false,
            isLoading: false,
          });
        } else {
          this.setState({
            isSuccess: true,
            showAlert: true,
            companyAlert: true,
            list,
            isLoading: false,
          });
        }
      })
      .catch(() =>
        this.setState({
          isSuccess: false,
          showAlert: true,
          companyAlert: false,
          isLoading: false,
        })
      );
  }

  dismissAlert() {
    this.setState({ showAlert: false });
  }

  render() {
    const {
      match: {
        params: { type },
      },
    } = this.props;
    const { showAlert, isSuccess, companyAlert, isLoading, loadingText } =
      this.state;
    const title =
      type !== "dfrs" ? toTitleCase(type.split("-").join(" ")) : "DFRs";
    const alert = companyAlert ? companyRemoveAlertText : alertText;
    return (
      <Fragment>
        <Loading isShowing={isLoading} text={loadingText} />
        <ManageView
          {...this.state}
          title={title}
          del={this.toggleActivation}
          update={this.update}
          priority={{
            increase: this.increasePrioirity,
            decrease: this.decreasePriority,
          }}
          importSelected={this.importSelected}
          updateCompanies={this.updateCompanies}
          alert={{
            show: showAlert,
            isSuccess,
            dismiss: this.dismissAlert,
            ...alert,
          }}
        />
      </Fragment>
    );
  }
}

export default withRouter(Manage);
