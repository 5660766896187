import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
// CCR
import CCRList from '../CCR/List';
import EditCCR from '../../containers/CCREdit'
import PrintCCR from '../CCR/Print';
import Approver from '../Review/Approver';
import ReassignCCR from '../../containers/CCRReassign';
// Admin
import ManageUsers from '../Admin/ManageUsers';
import Manage from '../Admin/Manage';
import UnassignedPersonnel from '../Admin/Reports/UnassignedPersonnel';
import WorkCenter from '../Admin/Reports/WorkCenter';
import HistoricalDFR from '../Admin/Reports/HistoricalDFR';
// Security
import BadgeDataEntry from '../Security/BadgeDataEntry';
// DFR
import DailyForceReports from '../DailyForceReports';
// routes
import HomeRoute from './HomeRoute';
import SecurityRoute from './roles/SecurityRoute';
import ApproverRoute from './roles/ApproverRoute';
import AdminRoute from './roles/AdminRoute';
import ContractLeadRoute from './roles/ContractLeadRoute';
import ReassignRoute from './roles/ReassignRoute';

class Routes extends Component {
  render() {
    return (
      <Switch>
        <HomeRoute exact path="/" component={CCRList} />
        <HomeRoute exact path="/null" component={CCRList} />
        <Route exact path="/ccr/new/(resume)?" component={EditCCR} />
        <Route exact path="/ccr/edit/:id" component={EditCCR} />
        <Route exact path="/ccr/print" component={PrintCCR} />
        <AdminRoute exact path="/manage/users" component={ManageUsers} />
        <SecurityRoute exact path="/badge-data-entry" component={BadgeDataEntry} />

        {}
        <ApproverRoute
          exact
          path="/ccr/approver-review"
          component={Approver}
        />
        <ContractLeadRoute
          exact
          path="/daily-force-report"
          component={DailyForceReports}
        />
        <AdminRoute exact path="/manage/:type" component={Manage} />
        <AdminRoute exact path="/reports/workcenter" component={WorkCenter} />
        <ReassignRoute exact path="/ccr/reassign/:ccrID" component={ReassignCCR} />
        <AdminRoute exact path="/reports/unassigned-personnel" component={UnassignedPersonnel} />
        <AdminRoute exact path="/reports/historicalDFR" component={HistoricalDFR} />
      </Switch>
    );
  }
}

export default Routes;
