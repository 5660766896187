import React, { useState } from "react";
import styles from "./ManageApprovers.module.scss";
import toTitleCase from "../../../../utils/string/to-title-case";
import Button from "../../../presentation/unity/Button";
import Dropdown from "../../../presentation/Input/Dropdown";

const AddOrReplaceApprover = (props) => {
  const { applicableApprovers, mode, onAdd, onReplace, selectedUser } = props;
  const [dropdownSelected, setDropdownSelected] = useState(0);

  const ReplaceButton = () => (
    <Button
      color="primary"
      text="Replace"
      disabled={!(dropdownSelected && selectedUser)}
      onClick={() => {
        onReplace(dropdownSelected, selectedUser);
        setDropdownSelected(0);
      }}
    />
  );

  const AddButton = () => (
    <Button
      color="primary"
      text="Add"
      disabled={!dropdownSelected}
      onClick={() => {
        onAdd(dropdownSelected);
        setDropdownSelected(0);
      }}
    />
  );

  return (
    <div className={styles.action_panel_container + " util-box-shadow"}>
      {mode === "add" ? <h3>Add Approver</h3> : <h3>Replace User With</h3>}
      <div className="em-u-margin">
        <Dropdown
          items={applicableApprovers}
          value={dropdownSelected}
          className={`em-u-padding-right-double ${styles.action_panel_dropdown}`}
          labelFn={(user) => `${getUserName(user)} (${user.email})`}
          valueAttribute="userID"
          showNoneOption
          onChange={(e) => setDropdownSelected(e.target.value)}
        />
      </div>
      <div className="em-u-margin">
        {mode === "add" ? <AddButton /> : <ReplaceButton />}
      </div>
    </div>
  );
};

const getUserName = (user) => toTitleCase(`${user.firstName} ${user.lastName}`);

export default AddOrReplaceApprover;
