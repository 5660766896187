import config from '../../config'

const authority = `https://login.microsoftonline.com/${config.adal_tenant}`;

export const msalConfig = {
  auth: {
    authority,
    clientId: config.adal_clientId,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'memoryStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['user.read'], //TODO: should scopes be different than only 'user.read'?
  //scopes: ['user.read', apiScope],  
};
