import React, { Component } from "react";
import { connect } from "react-redux";
import { getEditCCRAsync } from "../../../redux/actions/editCcrActions";
import { editCcrLoading, ccrData } from "../../../redux/selectors/editCcr";
import Loading from "../../presentation/Loading";
import EditView from "./EditView";

export class EditCCR extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
      location: { pathname },
    } = this.props;

    let loadFromStore = false;
    if (
      props.currentCCRInStore &&
      (props.currentCCRInStore.ccrID === Number(id) ||
        pathname.includes("resume"))
    )
      loadFromStore = true;

    this.state = {
      editedID: id,
      editStatus: id ? "edit" : "new",
      loadFromStore: loadFromStore,
    };
  }

  componentDidMount() {
    if (!this.state.loadFromStore) this.props.getCCR(this.state.editedID);
  }

  render() {
    return this.props.isLoading ? (
      <Loading isShowing={true} text="Loading ...." />
    ) : Boolean(this.props.currentCCRInStore) ? (
      <EditView editStatus={this.state.editStatus} />
    ) : (
      <div></div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: editCcrLoading(state),
  currentCCRInStore: ccrData(state),
});

const mapDispatchToProps = {
  getCCR: getEditCCRAsync,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCCR);
