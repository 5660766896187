import * as Actions from '../../actions/manageApproversActions';
import { getDBUser } from '../../../js/msal/msalHelper';

export const initialState = {
    all_approvers: [],
    original_approvers: [],
    selected_approvers: [],
    last_approved_approvers: []
}

export const manageApprovers = (state = initialState, { type, payload }) => {
    switch (type) {

    case Actions.MANAGE_APPROVERS_LOAD:

        const allApprovers = payload.approvers.data;

        const originalApprovers = new Set(
                payload?.ccr?.data?.ccrApprovers
                ?.filter(x => x.isActive)
                ?.sort( (a,b) => parseInt(a.approvalSequence) - parseInt(b.approvalSequence))
        );

        const lastApprovedApprovers = new Set(
            payload?.lastApproved?.data?.ccrApprovers
            ?.filter(x => x.isActive)
            ?.sort( (a,b) => parseInt(a.approvalSequence) - parseInt(b.approvalSequence))
        );

        let preloadApprovers = [];
        originalApprovers.forEach(user => {
            const targetApprover = allApprovers.filter(x => x.userID === user.approverID && x.userID !== (getDBUser()).userID)[0];
            if (targetApprover) preloadApprovers.push(targetApprover);
        });

        return { 
            ...state,
            original_approvers: [...originalApprovers],
            all_approvers: [...allApprovers],
            selected_approvers: [...preloadApprovers],
            last_approved_approvers: [...lastApprovedApprovers]
        }

    case Actions.SET_DEFAULT_APPROVERS:
        if (state.selected_approvers.length > 0) return {...state};

        return { 
            ...state,
            selected_approvers: [...payload]
        }

    case Actions.UPDATE_SELECTED_APPROVERS:
        return {
            ...state,
            selected_approvers: [
                ...payload
            ]
        }

    default:
        return state
    }
}
