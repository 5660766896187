import moment from "moment";

export const getOptions = (siteData, orgID, deptID, sectID) => {
  let options = {
    orgOptions: [],
    roleOptions: [],
    deptOptions: [],
    sectOptions: [],
    areaOptions: [],
  };

  if (!siteData) return options;

  options.orgOptions = siteData;

  //siteStructure.organizations.map(x => ({value: x.organizationID, label: x.name}));

  if (!orgID) return options;
  const organization = siteData.filter((x) => x.value === Number(orgID))[0];
  options.roleOptions = organization.roles;
  options.deptOptions = organization.departments;

  if (!deptID || !organization.departments) return options;
  const department = organization.departments.filter(
    (x) => x.value === Number(deptID)
  )[0];

  options.sectOptions = department.sections;

  if (!sectID || !department.sections) return options;
  const section = department.sections.filter(
    (x) => x.value === Number(sectID)
  )[0];

  // if section was soft deleted from the database it will be undefined
  if (!section) return options;
  options.areaOptions = section.areas;

  return options;
};

export const validate = (headcount) => {
  let errors = {};
  if (!headcount.organizationID) {
    errors.organizationID = "Missing Organization.";
  }
  if (!headcount.usageTypeID) {
    errors.usageTypeID = "Missing Usage Type.";
  }

  if (!headcount.departmentID) {
    errors.departmentID = "Missing Department.";
  }
  if (!headcount.positionRoleID) {
    errors.positionRoleID = "Missing Role.";
  }
  if (!headcount.startDate) {
    errors.startDate = "Missing start date";
  }
  if (isNaN(Number(headcount.quantity)) || !headcount.companyID)
    errors.quantity = "Missing Company or head count";
  else {
    if (Number(headcount.quantity) === 0 && headcount.status === "New") {
      errors.quantity = "Head count should be greater than zero";
    } else {
      if (Number(headcount.quantity) < 0) {
        errors.quantity = "Head count cannot be negative";
      }
    }
  }
  return { valid: Object.keys(errors).length === 0, errors };
};

export const getInitalMonthForDateRangePicker = (startDate, endDate) => {
  const current = moment();
  if (!startDate) return current;
  if (startDate.moment.isBefore(moment()) && endDate) return endDate.moment;
  return current;
};

export const calculateProjectedDatesArray = (
  recurrence,
  initialStartDate,
  daysWorking
) => {
  const arr = Array.from({ length: recurrence }).map((_, i) => {
    // if days working = 4, then they work for 4 days, and off 3, they start again 1 week from the original

    // otherwise, for example: if they work 7 days, then they are off 7 days, so you start again 14 days from the original, aka 2*days you work
    // because days you work = days you are off

    // the initial if works for all asymmetric cases under 7 days: Ex: 5 days working, 2 days off
    // so really, I am checking whether their days working == days off, if it is, then just add i * 7 days,
    // The else statement calculation will work for all symmetric cases
    const addDays = daysWorking === 4 ? i * 7 : 2 * i * daysWorking;
    const newStartDate = initialStartDate.clone().add(addDays, "days");

    const newEndDate = newStartDate.clone().add(daysWorking, "days");
    return {
      startDate: newStartDate,
      endDate: newEndDate,
    };
  });
  return arr;
};
