import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import reducer from './reducers';
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware({
  onError: err => console.log('Error: ', err)
});
const middlewares = applyMiddleware(thunk, sagaMiddleware);

const enhancer = ['local', 'local_dev'].includes(process.env.REACT_APP_STAGE) ? composeWithDevTools(middlewares) : middlewares

const store = createStore(
  reducer,
  enhancer
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;