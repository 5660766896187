import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Table, Alert } from 'reactstrap';
import Icon from '../../../presentation/unity/Icon';
import IconOnlyButton from '../../../presentation/unity/IconOnlyButton';
import {titleCaseRegex as titleCase} from '../../../../utils/string/to-title-case';
import styles from './ManageApprovers.module.scss';

class ManageApproverTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
        selectedRow: 0
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    this.props.onReorder(
      this.props.approvers,
      result.source.index,
      result.destination.index
    );
  }

  onDragStart(result){
    if(result.draggableId){
      this.props.onSelect(0);
    }
  }

  getMessage(){
    if (!this.props.approvers.length){
      if (this.props.approved?.length > 0) return (<Alert color={"warning"}> The CCR will automatically be approved. </Alert>);
      else return (<Alert color={"warning"}> Please add an approver to proceed. </Alert>);
    }
          
  }

  render() {
    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
          <div style={{display: "contents"}}>
            <Table style={{display: "contents", userSelect: "none"}} >
                  <thead className="thead-dark">
                      <tr>
                        <th style={{width: '65px'}}> Order </th>
                        <th style={{width: '250px'}}> Name  </th>
                        <th style={{width: '300px'}}> Email </th>
                        <th style={{width: '100px'}}></th>
                      </tr>
                  </thead>
                  <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                      <tbody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                      {
                        this.props.approved?.map(item=>(
                          <tr style={{background: "rgba(0, 0, 0, 0.03)"}} key={item.userID.toString()}>
                              <th 
                                style={{width: '65px'}} 
                                scope="row" 
                                >
                                <Icon name="minus"/>
                              </th>
                              <td 
                                style={{width: '250px'}} 
                                > 
                                {titleCase(`${item.firstName} ${item.lastName}`)} 
                              </td>
                              <td 
                                style={{width: '300px'}} 
                                > 
                                {item.email} 
                              </td>
                              <td 
                                style={{width: '10px'}}
                                > 
                                <Alert color='success' style={{padding:"0px 5px 0px 5px", margin:0}}>Approved</Alert>
                              </td>
                          </tr>
                        ))
                      }
                      {this.props.approvers.map((item, index) => (
                        <Draggable 
                          key={item.userID.toString()} 
                          draggableId={item.userID.toString()} 
                          index={index}
                          >
                            {(provided, snapshot) => (
                              <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              className={item.userID === this.props.selectedRow ? "table-info" : ""}
                              >
                                  <th 
                                    style={{width: '65px'}} 
                                    scope="row" 
                                    onClick = {()=> this.props.onSelect(item.userID)} 
                                    {...provided.dragHandleProps}
                                    >
                                    <Icon name="arrow-multi-direction"/>
                                  </th>
                                  <td 
                                    style={{width: '250px', cursor:'pointer'}} 
                                    onClick = {()=> this.props.onSelect(item.userID)}
                                    > 
                                    {titleCase(`${item.firstName} ${item.lastName}`)} 
                                  </td>
                                  <td 
                                    style={{width: '300px', cursor:'pointer'}} 
                                    onClick = {()=> this.props.onSelect(item.userID)}
                                    > 
                                    {item.email} 
                                  </td>
                                  <td 
                                    style={{width: '10px'}}
                                    > 
                                    <IconOnlyButton 
                                      icon="trashcan" 
                                      iconColor="red" 
                                      btnClass={styles.approver_delete_btn} 
                                      onClick={() => this.props.onRemove(item.userID)} 
                                    />
                                  </td>
                              </tr>
                        )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      </tbody>
                  )}
                  </Droppable>
            </Table>
          </div>
        </DragDropContext>
        { this.getMessage() }
      </div>
    );
  }
}

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  opacity: isDragging ? "0.8" : "1",
  background: isDragging ? "#0d68ae42" : "white"
});

 export default ManageApproverTable;