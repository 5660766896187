import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import icons from './helpers/IconList';
import Icon from './Icon';

export class Button extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { disabled, onClick } = this.props;
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick(e);
    }
  }

  render() {
    const {
      active,
      block,
      className,
      cssModule,
      color,
      disabled,
      outline,
      size,
      innerRef,
      icon,
      text,
      ...attributes
    } = this.props;
    let { tag: Tag } = this.props;

    const classes = classNames(
      className,
      'em-c-btn',
      color ? `em-c-btn${outline ? '-outline' : ''}--${color}` : false,
      size ? `em-c-btn--${size}` : false,
      block ? 'btn-block' : false,
      disabled ? 'em-c-btn--disabled' : null,
      active ? 'em-is-active' : null,
      { active, disabled },
    );

    if (attributes.href && Tag === 'button') {
      Tag = 'a';
    }

    const Inner = icon ? (
      <div className="em-c-btn__inner">
        <Icon name={icon} />
        { text ? (
          <span className="em-c-btn__text">
            {text}
          </span>
        ) : null
        }
      </div>
    ) : (
      <span className="em-c-btn__text">
        {text}
      </span>
    );

    return (
      <Tag
        type={(Tag === 'button' && attributes.onClick) ? 'button' : undefined}
        {...attributes}
        className={classes}
        ref={innerRef}
        onClick={this.onClick}
      >
        {Inner}
      </Tag>
    );
  }
}

Button.propTypes = {
  active: PropTypes.bool,
  block: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'bare', 'inverted', 'disabled']),
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['tiny', 'small', 'large']),
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  icon: PropTypes.oneOf(icons),
};

Button.defaultProps = {
  active: null,
  block: null,
  color: null,
  disabled: false,
  outline: null,
  tag: 'button',
  innerRef: null,
  onClick: null,
  size: null,
  children: null,
  className: null,
  cssModule: null,
  text: null,
  icon: null,
};

export default Button;
