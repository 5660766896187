import handleKeyPress from './handleKeyPress';

const badgeInput = (e) => {
  if (e.target.value.length < 9
    || e.keyCode === 8 // backspace
    || e.keyCode === 9 // tab
    || e.keyCode === 13 // enter
    || e.keyCode === 37 // arrow
    || e.keyCode === 38 // arrow
    || e.keyCode === 39 // arrow
    || e.keyCode === 40 // arrow
    || e.keyCode === 46 // delete
  ) {
    handleKeyPress(e);
  } else {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
  }
};

export default badgeInput;
