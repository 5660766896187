import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BreadcrumbNav from '../../../presentation/Breadcrumb';
import { getCcr,
  getSelectedHeadlines,
  getSelectedPrevious, getDiffPreviousCurrent} from '../../../../redux/selectors/selectedCcr';
import CCRReview from '../../../presentation/CCR/Review';
import UnityButton from '../../../presentation/unity/Button';
import './print.css';

const breadcrumb = [
    { name: 'Home', link: '/' },
    { name: 'CCR', isActive: true },
    { name: 'Print', isActive: true },
  ];
  export class PrintCCR extends Component {
    
    sortedRequestsForPrint(args) 
    {
      let sortedRequestsForPrint = args.sort(function (a, b) {
        if(a.endDate === null) return -1;
        else if(b.endDate === null ) return 1;
        else return (Date.parse(a.endDate) > Date.parse(b.endDate)) ? -1 : 1;
        });
      return sortedRequestsForPrint;
    }
    
    componentDidMount() {
      this.print();
      
    }
    
    print() {
      window.print();
    }
    
    render() {
    const { ccr, previous, headlines, diff } = this.props;
    return (
      <div className="homeContainer">
        <BreadcrumbNav items={breadcrumb} />
        <h3>CCR {ccr.ccrNumber}</h3>
        { Boolean(previous.headcountRequests.length) && (<h3>{headlines[0]}</h3>)}
        <CCRReview {...ccr} headcounts={this.sortedRequestsForPrint(ccr.headcountRequests)} highlights={diff} mode={'current'}/>
        {
          Boolean(previous.headcountRequests.length) && (
            <div className="pagebreak">
              <h3>{headlines[1]}</h3>
              <CCRReview {...previous} headcounts={this.sortedRequestsForPrint(previous.headcountRequests)} highlights={diff} mode={'previous'}/>
            </div>
          )
          
        }
        <div className="modal-footer">
        <UnityButton text="Print" id="printButton" onClick={this.print}/>
        <Link to='/'><UnityButton text="Back" id="backButton" /></Link>
        </div>
      </div>
      
    );
  }
}


const mapStateToProps = (state) => ({
    ccr: getCcr(state),
    headlines: getSelectedHeadlines(state),
    previous: getSelectedPrevious(state),
    diff: getDiffPreviousCurrent(state)
  });

  
export default connect(mapStateToProps)(withRouter(PrintCCR));
