import axios from 'axios';
import Cookies from 'js-cookie';
import { msalInstance } from '../../utils/auth/authProvider';
import { loginRequest } from '../../utils/auth/authConfig';
const xlsx = {
  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const importHeaders = token => ({
  Accept: xlsx.type,
  'Content-Type': 'multipart/form-data',
  Authorization: `Bearer ${token}`,
  Pragma: 'no-cache',
  'Cache-Control': 'no-cache, no-store',
});

const exportHeaders = token => ({
  Authorization: `Bearer ${token}`,
  Pragma: 'no-cache',
  'Cache-Control': 'no-cache, no-store',
});

const resolve = (url, method, path, data, token) => axios({
  baseURL: url,
  url: path,
  method,
  data,
  headers: token ? { Authorization: `Bearer ${token}`, 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' } : null, 
});

const multipartResolve = (url, method, path, data, token) => {
  const headers = method === 'post' ? importHeaders(token) : exportHeaders(token);
  return axios({
    baseURL: url,
    url: path,
    method,
    data,
    headers,
    responseType: 'blob',
    withCredentials: true,
  });
};

const resolver = async (url, method, path, data, isMultipart) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No  active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({ 
    ...loginRequest,
    account
  });

  const token = response.idToken;

  const needsAuth = true; //always need token!!!
  if (token) {
    if (isMultipart) {
      return multipartResolve(url, method, path, data, token);
    }
    const axios1 = resolve(url, method, path, data, token);
    return axios1;
  }
  if (!token && needsAuth) { // some request doesn't need token
    return resolve(url, method, path, data);
  }
  // await fetchToken(url);
  return resolver(url, method, path, data);
};

export default resolver;
