import React, { Component, Fragment } from "react";
import { find, propEq } from "ramda";
import AddUserView from "./presentation";
import Loading from "../../../presentation/Loading";
import { user as userAPI } from "../../../../api";

const errors = {
  add: {
    duplicateUser: "That user already exists in this role.",
  },
  edit: {
    handledError:
      "There was an issue updating that user. Please refresh the page, and try again. If the issue persists, please contact the system administrator.",
  },
  delete: {
    handledError:
      "There was an issue deleting that user. Please refresh the page, and try again. If the issue persists, please contact the system administrator.",
  },
  serverError:
    "There was an error communicating with the server. Please refresh the page and try again. If the issue persists, please contact the system administrator.",
  unhandled:
    "The server returned an unexpected error. Please contact the system administrator.",
};

export default class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: {
        approver: [],
        contractLead: [],
        securityGuard: [],
        admin: [],
      },
      roleIDs: {
        approver: null,
        contractLead: null,
        securityGuard: null,
        admin: null,
      },
      isSuccess: false,
      errorMessage: "",
      showAlert: false,
      isLoading: false,
      loadingMessage: "",
      firstName: "",
      lastName: "",
      email: "",
      firstNameValid: true,
      lastNameValid: true,
      emailValid: true,
      userCompanies: [],
    };
    this.emailRegex = RegExp(/\S+(@esso.ca$|@exxonmobil.com$)/);
    this.nameRegex = RegExp(/^[a-zA-Z]*$/);
    this.getUsers = this.getUsers.bind(this);
    this.addUser = this.addUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.setCompanies = this.setCompanies.bind(this);
  }

  componentDidMount() {
    this.getUsers();
  }

  async getUsers() {
    this.setState({
      isLoading: true,
      loadingMessage: "Fetching list of users",
    });
    const roles = await userAPI.get.list();
    this.setState({
      lists: {
        approver: find(propEq("name", "Approver"))(roles.data).roleUsers.filter(
          (user) => !user.isDeleted
        ),
        securityGuard: find(propEq("name", "SecurityGuard"))(
          roles.data
        ).roleUsers.filter((user) => !user.isDeleted),
        contractLead: find(propEq("name", "Contract Lead"))(
          roles.data
        ).roleUsers.filter((user) => !user.isDeleted),
        admin: find(propEq("name", "Admin"))(roles.data).roleUsers.filter(
          (user) => !user.isDeleted
        ),
      },
      roleIDs: {
        approver: find(propEq("name", "Approver"))(roles.data).roleID,
        securityGuard: find(propEq("name", "SecurityGuard"))(roles.data).roleID,
        contractLead: find(propEq("name", "Contract Lead"))(roles.data).roleID,
        admin: find(propEq("name", "Admin"))(roles.data).roleID,
      },
    });
    this.setState({ isLoading: false, loadingMessage: "" });
  }

  setEmail(email, isValid) {
    const emailValid = isValid !== null ? isValid : this.emailRegex.test(email);
    // Add email vaildation here
    this.setState({ email, emailValid });
  }

  setFirstName(firstName, isValid) {
    const firstNameValid =
      isValid !== null ? isValid : this.nameRegex.test(firstName);
    this.setState({ firstName, firstNameValid });
  }

  setLastName(lastName, isValid) {
    const lastNameValid =
      isValid !== null ? isValid : this.nameRegex.test(lastName);
    this.setState({ lastName, lastNameValid });
  }

  setCompanies(userCompanies) {
    this.setState({ userCompanies });
  }

  async deleteUser(user, role) {
    const name = user.firstName
      ? `${user.firstName} ${user.lastName}`
      : user.email;
    const { roleIDs } = this.state;
    // eslint-disable-next-line
    const response = window.confirm(
      `Are you sure you want to remove ${name} from the ${role.name} role?`
    );
    if (response === true) {
      const deleted = await userAPI.put
        .userFromRole(user.userID, roleIDs[role.listName])
        .catch((err) => {
          const {
            response: { status },
          } = err;
          if (status >= 400 && status < 500) {
            this.setState({
              isSuccess: false,
              errorMessage: errors.delete.handledError,
            });
          } else if (status >= 500 && status < 600) {
            this.setState({
              isSuccess: false,
              errorMessage: errors.serverError,
            });
          } else {
            this.setState({
              isSuccess: false,
              errorMessage: `${errors.unhandled} (${status})`,
            });
          }
          return null;
        });
      if (deleted !== null) {
        this.setState({ showAlert: false });
        this.getUsers();
      }
    }
  }

  async editUser(selectedRole, userID) {
    const {
      firstName,
      lastName,
      roleIDs,
      email,
      userCompanies,
      emailValid,
      firstNameValid,
      lastNameValid,
      lists,
    } = this.state;
    if (emailValid && firstNameValid && lastNameValid) {
      const userRoles = [];
      if (
        selectedRole !== "approver" &&
        lists.approver.findIndex((item) => item.email === email) > -1
      ) {
        userRoles.push({ roleID: roleIDs.approver });
      }
      if (
        selectedRole !== "securityGuard" &&
        lists.securityGuard.findIndex((item) => item.email === email) > -1
      ) {
        userRoles.push({ roleID: roleIDs.securityGuard });
      }
      if (
        selectedRole !== "contractLead" &&
        lists.contractLead.findIndex((item) => item.email === email) > -1
      ) {
        userRoles.push({ roleID: roleIDs.contractLead });
      }
      if (
        selectedRole !== "admin" &&
        lists.admin.findIndex((item) => item.email === email) > -1
      ) {
        userRoles.push({ roleID: roleIDs.admin });
      }
      const res = userRoles.push({ roleID: _.get(roleIDs, selectedRole) });
      await userAPI.put
        .user(userID, {
          userID,
          firstName,
          lastName,
          email,
          userCompanies,
          userRoles,
        })
        .catch((err) => {
          const {
            response: { status },
          } = err;
          if (status >= 400 && status < 500) {
            this.setState({
              isSuccess: false,
              errorMessage: errors.delete.handledError,
            });
          } else if (status >= 500 && status < 600) {
            this.setState({
              isSuccess: false,
              errorMessage: errors.serverError,
            });
          } else {
            this.setState({
              isSuccess: false,
              errorMessage: `${errors.unhandled} (${status})`,
            });
          }
          return null;
        });
      const isSuccess = res !== null;
      this.getUsers();
      return isSuccess;
    }
    return false;
  }

  async addUser(selectedRole) {
    const {
      firstName,
      lastName,
      roleIDs,
      email,
      userCompanies,
      emailValid,
      firstNameValid,
      lastNameValid,
    } = this.state;
    if (emailValid && firstNameValid && lastNameValid) {
      const userRoles = [];
      userRoles.push({ roleID: _.get(roleIDs, selectedRole) });
      const res = await userAPI.post
        .user({ firstName, lastName, email, userCompanies, userRoles })
        .catch((err) => {
          const {
            response: { status },
          } = err;
          // throw error immediately for debugging
          // eslint-disable-next-line
          if (status === 409) {
            this.setState({
              isSuccess: false,
              errorMessage: errors.add.duplicateUser,
            });
          } else if (status >= 500 && status < 600) {
            this.setState({
              isSuccess: false,
              errorMessage: errors.serverError,
            });
          } else {
            this.setState({
              isSuccess: false,
              errorMessage: `${errors.unhandled} (${status}) [${JSON.parse(
                email
              )} ${JSON.parse(userCompanies)} ${JSON.parse(userRoles)}}]`,
            });
          }
          return null;
        });
      const isSuccess = res !== null;
      this.setState({ isSuccess, showAlert: true });
      this.getUsers();
      return isSuccess;
    }
    return false;
  }

  dismissAlert() {
    this.setState({ showAlert: false, isSuccess: false });
  }

  render() {
    const { isLoading, loadingMessage } = this.state;
    return (
      <Fragment>
        <Loading isShowing={isLoading} text={loadingMessage} />
        <AddUserView
          {...this.state}
          deleteUser={this.deleteUser}
          editUser={this.editUser}
          addUser={this.addUser}
          dismissAlert={this.dismissAlert}
          setCompanies={this.setCompanies}
          setEmail={this.setEmail}
          setFirstName={this.setFirstName}
          setLastName={this.setLastName}
        />
      </Fragment>
    );
  }
}
