import React, { Component } from "react";
import AddCompanyView from "./presentation/AddCompanyView";
import { manage } from "../../../api";

export default class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compNameValidation: {
        validationMessage:
          "Company Name must be at least 2 characters long and less than 156 characters",
        maxLength: 50,
        minLength: 2,
      },
      companyName: "",
      validCompanyName: false,
      isActivate: true,
    };
    this.addCompany = this.addCompany.bind(this);
    this.setCompanyName = this.setCompanyName.bind(this);
    this.toggleActivate = this.toggleActivate.bind(this);
  }

  setCompanyName(value) {
    this.setState({
      companyName: value,
    });

    if (
      value.length < this.state.compNameValidation.minLength ||
      value.length > this.state.compNameValidation.maxLength
    ) {
      this.setState({
        validCompanyName: false,
      });
    } else {
      this.setState({
        validCompanyName: true,
      });
    }
  }

  toggleActivate() {
    this.setState({
      isActivate: !this.state.isActivate,
    });
  }

  async addCompany() {
    const { companyName, validCompanyName, isActivate } = this.state;
    const { hide, max } = this.props;
    if (validCompanyName) {
      const res = await manage.post
        .company(companyName, max + 1, isActivate)
        .catch((err) => alert(err.response.data)); // eslint-disable-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ companyName: "", validCompanyName: false });
      }
    }
  }

  render() {
    return (
      <div>
        <AddCompanyView
          {...this.props}
          {...this.state}
          setCompanyName={this.setCompanyName}
          submit={this.addCompany}
          activate={this.toggleActivate}
        />
      </div>
    );
  }
}
