import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import UnityButton from '../../unity/Button';
import BreadcrumbNav from '../../Breadcrumb';
import CCRView from './index';
import Tabs from '../../../containers/Nav/Tabs';
import ReviewComment from '../../../routes/Review/ReviewComment';

export default class ReviewWrapper extends Component {
  render() {
    const {
      ccr,
      copy,
      previous,
      diff,
      headlines,
      proceed,
    } = this.props;
    const components = [
      (<CCRView {...ccr} headcounts={ccr.headcountRequests} preTitle={copy.title} highlights={diff} mode={'current'}/>)
    ];
    if (previous.headcountRequests.length) {
      components.push((<CCRView {...previous} headcounts={previous.headcountRequests} preTitle={copy.title} highlights={diff} mode={'previous'} />))
    }
    return (
      <div>
        <BreadcrumbNav items={copy.breadcrumb} />
        <h3>
          {copy.title ? `${copy.title}: ` : null}
          {`CCR ${ccr.ccrNumber}`}
        </h3>

        <br />
        <ReviewComment isOpen={this.props.isOpen}
          toggleComment={this.props.toggleComment}
          onDeny={this.props.onDeny}>
        </ReviewComment>
        <Tabs headers={headlines} components={components} />
        <FormGroup className="em-c-btn-group">
          <UnityButton color="primary" onClick={() => proceed(true)} text={copy.actions.positive} />
          <UnityButton color="secondary" onClick={this.props.toggleComment} text={copy.actions.negative} />
        </FormGroup>
      </div>
    );
  }
}
