import config from '../../config';
import resolver from './root';

const url = `${config.kmod_api_moc_base_url}/api`;

export const userCalls = {
  list: () => resolver(url, 'get', '/roles/'),
  byRole: role => resolver(url, 'get', `/roles/GetRoleByName/${role}`),
  byEmail: email => resolver(url, 'get', `/users/GetUserByEmail/${email}`),
  getCompanies: () => resolver(url, 'get', '/companies'),
  getActiveCompanies: () => resolver(url, 'get', '/companies/ActiveCompanies'),
  getCandidateCompanies: () => resolver(url, 'get', '/companies/CandidateCompanies'),
  delete: userID => resolver(url, 'delete', `/Users/${userID}`),
  deletefromRole: (userID, roleID) => resolver(url, 'put', `/Users/RemoveRole/${userID}/${roleID}`),
  edit: (userID, user) => resolver(url, 'put', `/users/${userID}`, user),
  add: user => resolver(url, 'post', '/users', user),
};

export default { userCalls };
