import React, { Component } from 'react';
import AddAssetView from './presentation/AddAsset';
import { manage } from '../../../api';

export default class AddAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: '',
      assetID: '',
      validAsset: true,
      validAssetID: true,
    };
    this.addAsset = this.addAsset.bind(this);
    this.setAsset = this.setAsset.bind(this);
    this.setAssetID = this.setAssetID.bind(this);
  }

  setAsset(asset) {
    this.setState({ asset });
  }

  setAssetID(assetID) {
    this.setState({ assetID });
  }

  isValid() {
    const { asset, assetID } = this.state;
    const validAsset = asset !== '';
    const validAssetID = assetID !== '';
    this.setState({ validAsset, validAssetID });
    return validAsset && validAssetID;
  }

  async addAsset() {
    const { asset, assetID } = this.state;
    const { hide } = this.props;
    if (this.isValid()) {
      const res = await manage.post.asset(
        assetID,
        asset,
      ).catch((err) => alert(err.response.data)); // eslint-disable-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ asset: '' });
      }
    }
  }

  render() {
    return (
      <div>
        <AddAssetView
          {...this.props}
          {...this.state}
          setAssetID={this.setAssetID}
          setAsset={this.setAsset}
          submit={this.addAsset}
        />
      </div>
    );
  }
}
