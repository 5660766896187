import React, { Component } from 'react';
import {
  find,
  propEq,
} from 'ramda';
import AddDepartmentView from './presentation/AddDepartment';
import { manage } from '../../../api';

export default class AddDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      selectedOrganization: null,
      department: '',
      validDepartment: true,
      validOrganization: true,
    };
    this.getOrganizations = this.getOrganizations.bind(this);
    this.addDepartment = this.addDepartment.bind(this);
    this.setOrganization = this.setOrganization.bind(this);
    this.setDepartment = this.setDepartment.bind(this);
  }

  componentDidMount() {
    this.getOrganizations();
  }

  async getOrganizations() {
    const organizations = await manage.get.organizations();
    if (organizations.data !== null) {
      organizations.data = organizations.data.filter(org => org.isDeleted === false);
      // Renaming the organizations for clarity
      // eslint-disable-next-line
      organizations.data.map(org => org.name = `${org.name} (${org.asset.name})`);
      this.setState({ organizations: organizations.data });
    }
  }

  setOrganization(org) {
    const { organizations } = this.state;
    const selectedOrganization = find(propEq('name', org))(organizations);
    this.setState({ selectedOrganization });
  }

  setDepartment(dep) {
    this.setState({ department: dep });
  }

  isValid() {
    const { department, selectedOrganization } = this.state;
    const validDepartment = department !== '';
    const validOrganization = selectedOrganization !== null;
    this.setState({ validDepartment, validOrganization });
    return validDepartment && validOrganization;
  }

  async addDepartment() {
    const { selectedOrganization, department } = this.state;
    const { hide } = this.props;
    if (this.isValid()) {
      const res = await manage.post.department(
        department,
        selectedOrganization.organizationID,
      ).catch((err) => alert(err.response.data)); // eslint-disable-line
      // eslint-disable-next-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ department: '', selectedOrganization: null });
      }
    }
  }

  render() {
    return (
      <div>
        <AddDepartmentView
          {...this.props}
          {...this.state}
          set={{
            organization: this.setOrganization,
            department: this.setDepartment,
          }}
          submit={this.addDepartment}
        />
      </div>
    );
  }
}
