import React, { Component } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
import UnityButton from '../../../../presentation/unity/Button';
import TableLayout from '../../../../containers/Table/AdminTable';
import UserModal from '../../../../containers/Modal/ManageUser';
import BreadcrumbNav from '../../../../presentation/Breadcrumb';
import UploadAlert from '../../../../presentation/Alert';

const tabNames = [
  { id: '1', name: 'Approver', listName: 'approver' },
  { id: '4', name: 'Security Guard', listName: 'securityGuard' },
  { id: '5', name: 'Admin', listName: 'admin' },
];

const table = {
  withContractor: {
    headers: ['First Name', 'Last Name', 'Email', 'Companies', ''],
    fields: ['firstName', 'lastName', 'email'],
  },
  withoutContractor: {
    headers: ['First Name', 'Last Name', 'Email', ''],
    fields: ['firstName', 'lastName', 'email'],
  },
};

const breadcrumb = [
  { name: 'Home', link: '/' },
  { name: 'User Management', isActive: true },
];

export default class AddUserView extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      user: null,
      isAdd: null,
      tabInfo: {
        name: null,
        tabName: null,
      },
      isModalOpen: false,
    };
    this.addUser = this.addUser.bind(this);
    this.editUser = this.editUser.bind(this);
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      const { dismissAlert } = this.props;
      dismissAlert();
    }
  }

  openModal(item, isAdd, user) {
    const { setEmail, setCompanies } = this.props;
    this.setState({
      user,
      isAdd,
      tabInfo: item,
      isModalOpen: true,
    });
    if (!isAdd) {
      setEmail(user.email, true);
      setCompanies(user.userCompanies);
    } else {
      setEmail('', true);
      setCompanies([]);
    }
  }

  closeModal() {
    const { setEmail, setCompanies } = this.props;
    this.setState({ isModalOpen: false });
    setEmail('', false);
    setCompanies([]);
  }

  edit(user, item) {
    const { setEmail, setCompanies } = this.props;
    this.setState({ user, tabInfo: item });
    setEmail(user.email);
    setCompanies(user);
  }

  addUser() {
    const { addUser } = this.props;
    const { tabInfo: { listName } } = this.state;
    const isSuccess = addUser(listName);
    this.setState({ isModalOpen: !isSuccess });
  }

  editUser() {
    const { editUser } = this.props;
    const { tabInfo: { listName }, user } = this.state;
    const isSuccess = editUser(listName, user.userID);
    this.setState({ isModalOpen: !isSuccess });
  }

  render() {
    const {
      activeTab,
      user,
      tabInfo,
      isAdd,
      isModalOpen,
    } = this.state;
    const {
      lists,
      deleteUser,
      isSuccess,
      errorMessage,
      showAlert,
      dismissAlert,
      setEmail,
      setFirstName,
      setLastName,
      setCompanies,
      emailValid,
      firstNameValid,
      lastNameValid
    } = this.props;
    let alert = null;
    if (showAlert) {
      alert = (
        <div>
          <br />
          <br />
          <br />
          <UploadAlert
            isSuccess={isSuccess}
            successMessage="User successfully created."
            failMessage={errorMessage}
            dismiss={() => dismissAlert()}
            show={showAlert}
          />
        </div>
      );
    }
    return (
      <div className="homeContainer">
        <BreadcrumbNav items={breadcrumb} />
        <div className="ccrContainer">
          <UserModal
            tabName={tabInfo.name}
            user={user}
            addOrEdit={isAdd}
            addUser={this.addUser}
            editUser={this.editUser}
            selected={!user ? null : user}
            close={() => this.closeModal()}
            isOpen={isModalOpen}
            setEmail={email => setEmail(email, null)}
            setFirstName={fname => setFirstName(fname, null)}
            setLastName={lname => setLastName(lname, null)}
            setCompanies={setCompanies}
            emailValid={emailValid}
            firstNameValid={firstNameValid}
            lastNameValid={lastNameValid}
          />
          <Nav tabs key="userTabNav">
            {
              tabNames.map(item => (
                <NavItem>
                  <NavLink
                    className={`${classnames({ active: activeTab === item.id })} tabs`}
                    onClick={() => { this.toggle(item.id); }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))
            }
          </Nav>
          <TabContent activeTab={activeTab} key="userTabContent">
            {
              tabNames.map(item => (
                <TabPane tabId={item.id}>
                  <br />
                  <Row>
                    <Col sm="12">
                      <UnityButton className="float-right" onClick={() => this.openModal(item, true)} icon="user-2-plus" text={` Add ${item.name}`} />
                      {alert}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm="12">
                      <TableLayout
                        showContractors={item.name !== 'Security Guard' && /*item.name !== 'Endorser' && */ item.name !== 'Admin'}
                        layout={table}
                        list={lists[item.listName]}
                        del={usr => deleteUser(usr, item)}
                        edit={usr => this.openModal(item, false, usr)}
                      />
                    </Col>
                  </Row>
                </TabPane>
              ))
            }
          </TabContent>
        </div>
      </div>
    );
  }
}
