import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Nav } from 'reactstrap';
import DFR from './DFR';
import Security from './Security';
import Admin from './Admin';

class SideNav extends Component {
  render() {
    return (
      <div className="menuContainer sidebar-sticky col-sm-2">
        <div className="kmod-sidebar-container">
          <Nav vertical className="em-c-header__nav-container em-js-nav-panel">
            <nav className="em-c-trfee">
              <ul className="em-c-tree__list em-c-tree__list--flush" role="tablist" aria-multiselectable="true">
                <DFR />
                <Security />
                <Admin />
              </ul>
            </nav>
          </Nav>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(SideNav);
