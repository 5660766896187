import React from 'react';
import Button from '../../../presentation/unity/Button';

const WizardButtonBar = props => {

    const { disableBackButton, isContinueDisabled, isQuitDisabled, quitMethod, backMethod, continueMethod, continueButtonText } = props;

    return (
        <div className="em-u-margin-bottom-double">
            <hr/>
            <div>
                <div className="util-float-left em-u-display-flex util-align-baseline">
                    <Button 
                        text="Quit" 
                        onClick={() => quitMethod()} 
                        disabled={ isQuitDisabled } 
                        className = "em-u-margin-right"
                    />
                    { props.children }
                </div>
                <div className="util-float-right">
                    {   !disableBackButton &&
                        <Button 
                        color="secondary" 
                        text="Back" 
                        className = "em-u-margin-right"
                        onClick={() => backMethod()} 
                        />
                    }
                    <Button 
                        color="primary"
                        text={continueButtonText || "Continue"}
                        onClick={() => continueMethod()} 
                        disabled={ isContinueDisabled }
                    />
                </div>
            </div>
        </div>
    )
};

export default WizardButtonBar;
