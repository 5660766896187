import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Tabs from '../Nav/Tabs';
import CCRView from '../../presentation/CCR/Review';
import WizardButtonBar from '../Nav/Wizard/WizardButtonBar';
import { Alert } from 'reactstrap';
import { quitCcr, submitCCRAction } from '../../../redux/actions/editCcrActions';
import {
  reviewHeadlines, getEditedCcr, getOriginalCcr, getLastApprovedCcr, changed, getDiffHighlights, getDiffHighlightsOriginal
} from '../../../redux/selectors/manageHeadcount';

import { getSelectedApprovers, getOriginalApprovers, getLastApprovedApprovers} from '../../../redux/selectors/manageApprovers'; 
import { filterDuplicateIndefiniteHeadCounts } from '../../../redux/reducers/EditCCR/manageHeadcount.logic';

let approvalList = [];
const table = {
  headers: [
    'Status',
    'Organization',
    'Usage Type',
    'Role',
    'Department',
    'Section',
    'Area',
    'Shift',
    'Schedulable',
    'Billable',
    'Start Date',
    'End Date',
    'Company',
    'Headcount Impacted'
  ],
  fields: [
    'status',
    'organization',
    'usageType',
    'positionRole',
    'department',
    'section',
    'area',
    'shift',
    'schedulable',
    'billable',
    'startDate',
    'endDate',
    'company',
    'quantity'
  ],
};


const Review = (props) => {
  
    const history = useHistory();
    let {headlines} = props; 

    approvalList = [];
    props.selectedApprovers.forEach(buildApproval);
    const components = [
      <CCRView {...props.ccr}  headcounts={ getFilteredHeadcounts(props.ccr.headcountRequests)} id="editedCCRView" highlights={props.diffNew} ccrApprovers={approvalList} table={table} mode={'current'}/>,
    ];
    if (props.originalCcr.ccrStatusID === 5 && props.lastApprovedCcr){
      components.push(<CCRView {...props.lastApprovedCcr} headcounts={ getFilteredHeadcounts(props.lastApprovedCcr.headcountRequests)} id="originalCCRView" highlights={props.diffNew}  ccrApprovers={props.lastApprovedApprovers} table={table} mode={'previous'}/>);
    } else {
      components.push(<CCRView {...props.originalCcr} headcounts={ getFilteredHeadcounts(props.originalCcr.headcountRequestsriginalHeadCountRequests)} id="originalCCRView" highlights={props.diffOriginal}  ccrApprovers={props.originalApprovers} table={table} mode={'previous'}/>);
    }

    return(
        <>
        <Tabs headers={headlines} components={components} />
        <br /> <hr /> <br /> 
        <div>
        </div>
        <WizardButtonBar 
          isContinueDisabled = {!props.changed}
          continueMethod = {() => props.submitCCR(history)}
          backMethod = {props.back}
          continueButtonText = "Submit"
          quitMethod={() =>  props.quitCcr(history)}
        >
          {!props.changed && (<Alert color="danger"> No change detected. Please make an edit to submit. </Alert>)}
        </WizardButtonBar>
        </>
    )
}

const getFilteredHeadcounts = Headcounts => Headcounts?.filter(hc => (!hc.endDate) || (moment(hc.endDate).diff(moment(), "months") > -2));

function buildApproval(item, index){
  let approval = {
    approvalSequence: index + 1, 
    user: {
      userID: item.userID,
      firstName: item.firstName, 
      lastName: item.lastName, 
    },
    approvalStatus: {statusID: 3, statusName: 'For Review' }
  }

  approvalList.push(approval);
}

const mapStateToProps = (state) => ({
    headlines: reviewHeadlines(state),
    ccr: getEditedCcr(state),
    originalCcr: getOriginalCcr(state),
    lastApprovedCcr: getLastApprovedCcr(state),
    diffNew: getDiffHighlights(state),
    diffOriginal: getDiffHighlightsOriginal(state),
    changed: changed(state), 
    originalApprovers: getOriginalApprovers(state), 
    selectedApprovers: getSelectedApprovers(state),
    lastApprovedApprovers: getLastApprovedApprovers(state)
});

const mapDispatchToProps = {
  submitCCR: submitCCRAction,
  quitCcr
}

export default connect(mapStateToProps, mapDispatchToProps)(Review);