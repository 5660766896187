import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ccrData } from '../../../../../redux/selectors/editCcr';
import { resetEditCcr } from '../../../../../redux/actions/editCcrActions';
import { truncate } from "../../../../../utils/string/truncate";

const CCRRecoverStateAlert = props => {
  const { ccrInStore } = props;
  

  if (!ccrInStore) return null;
  const ccrTitle = truncate(ccrInStore.title, 70, true);

  return (
      <div className="em-c-alert util-clear-both em-u-margin-top em-u-margin-bottom" role="alert">
        <svg className="em-c-icon em-c-icon--medium em-c-alert__icon util-margin-auto">
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="../../images/em-icons.svg#file-spreadsheet"
          ></use>
        </svg>
        <div className="em-c-alert__body util-margin-auto em-u-margin-left-half">
            <span className={"em-u-font-style-regular"}>Detected unfinished CCR:</span> <u>{ccrTitle}</u>! {"  "}
            <span className={"em-u-font-style-regular-italic"}>Editing/Creating another CCR will discard this data.</span>
        </div>
        <div className="em-c-alert__actions">
          <button className="em-c-text-btn em-u-margin-right" onClick={()=>props.discardCCR()}>Discard</button>
          <Link to={ ccrInStore.ccrID ? `/ccr/edit/${ccrInStore.ccrID}` : '/ccr/new/resume'}>
            <button className="em-c-btn em-c-btn--small">
              <span className="em-c-btn__text">Resume</span>
            </button>
          </Link>
        </div>
      </div>
  );
};

CCRRecoverStateAlert.propTypes = {
}

CCRRecoverStateAlert.defaultProps = {

}

const mapStateToProps = (state) => ({
    ccrInStore: ccrData(state)
  })

const mapDispatchToProps = {
    discardCCR: resetEditCcr
}

export default connect(mapStateToProps, mapDispatchToProps)(CCRRecoverStateAlert)
