import React, { Component } from 'react';
import { badge as badgeAPI, user as userAPI } from '../../../../api';
import HeadcountIDEntryView from './presentation/HeadcountIDEntryView';
import BadgeIDEntryView from './presentation/BadgeIDEntryView';
import Error from '../../../presentation/Error';
import Loading from '../../../presentation/Loading';

const loadingMessage = 'Searching...';

export default class BadgeDataEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headcountID: 'N/A',
      headcountIDValid: false,
      badgeID: null,
      badgeIDValid: true,
      showComponent: false,
      positionRole: 'Undefined',
      positionRoleID: null,
      usageType: 'Undefined',
      usageTypeID: null,
      companies: [],
      selectedCompany: null,
      companyIsValid: true,
      validationMessage: 'Please enter a Badge ID.',
      isLoading: false,
      error: {
        show: false,
        copy: '',
      },
    };
    this.searchBadgeID = this.searchBadgeID.bind(this);
    this.handleHeadcountIDInput = this.handleHeadcountIDInput.bind(this);
    this.handleBadgeIDInput = this.handleBadgeIDInput.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.addBadgeID = this.addBadgeID.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

  async componentWillMount() {
    const companyRes = await userAPI.get.activeCompanies();
    const companies = companyRes.data;
    this.setState({
      companies,
    });
  }

  setSelected(value) {
    this.setState({ selectedCompany: value });
  }

  handleHeadcountIDInput(e) {
    const { value } = e.target;
    this.setState({ headcountID: value });
  }

  handleBadgeIDInput(e) {
    const { value } = e.target;
    this.setState({ badgeID: value, badgeIDValid: value !== '' && value !== null });
  }

  async searchBadgeID() {
    const { headcountID } = this.state;
    if (headcountID !== 'N/A' && headcountID !== '' && headcountID !== null) {
      this.setState({
        isLoading: true,
        showComponent: false,
        error: {
          show: false,
          copy: '',
        },
      });

      const headcountIDResults = await badgeAPI.get.individual(headcountID)
        .catch(() => null);
      const headcountIDValid = headcountIDResults !== null;
      if (headcountIDValid) {
        this.setState({
          showComponent: true,
          headcountIDValid,
          searchedHeadcountID: headcountID,
          positionRole: headcountIDResults.data.position.positionRole.name,
          positionRoleId: headcountIDResults.data.position.positionRole.positionRoleID,
          usageType: headcountIDResults.data.usageType.name,
          usageTypeId: headcountIDResults.data.usageType.usageTypeID,
          isLoading: false,
          badgeID: null,
        });
      } else {
        this.setState({
          showComponent: true,
          headcountIDValid,
          searchedHeadcountID: null,
          positionRole: 'Undefined',
          usageType: 'Undefined',
          isLoading: false,
        });
      }
    }
  }

  async addBadgeID() {
    const {
      badgeID, positionRoleID, usageTypeID, headcountID,
    } = this.state;

    this.setState({
      badgeIDValid: true,
      isLoading: true,
      error: {
        show: false,
        copy: '',
      },
    });

    await badgeAPI.post.badge(
      headcountID,
      badgeID,
      positionRoleID,
      usageTypeID,
    ).then(() => {
      // eslint-disable-next-line
      window.confirm('Badge ID has been added.');
      this.setState({ searchedHeadcountID: null, showComponent: false, isLoading: false });
      document.getElementById('headcount-search').reset();
    }).catch((err) => {
      this.setState({ isLoading: false });
      const { response: { status, data } } = err;
      if (status === 400) {
        this.setState({
          error: {
            show: true,
            copy: data,
          },
        });
      }
      if (status >= 500) {
        this.setState({
          error: {
            show: true,
            copy: 'Error communicating with the server. Please try again.',
          },
        });
      }
    });
  }

  dismissError() {
    this.setState(prevState => ({
      error: {
        ...prevState.error,
        show: false,
      },
    }));
  }

  render() {
    const { showComponent, isLoading, error } = this.state;
    return (
      <div>
        <Loading isShowing={isLoading} text={loadingMessage} />
        <HeadcountIDEntryView
          {...this.state}
          searchBadgeID={this.searchBadgeID}
          handleUserInput={this.handleHeadcountIDInput}
        />
        {showComponent
          ? (
            <BadgeIDEntryView
              {...this.state}
              handleUserInput={this.handleBadgeIDInput}
              addBadgeID={this.addBadgeID}
              setSelected={this.setSelected}
            />)
          : null
    }
        <Error {...error} dismiss={this.dismissError} />
      </div>
    );
  }
}
