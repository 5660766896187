import React from "react";

import { Input } from "reactstrap";

/**
 * Clone to avoid mutating usages else where in the app.
 * @param {} list
 * @param {*} property
 * @returns cloned and list sorted by the property
 */
function cloneAndSortByProperty(list, property) {
  const clonedList = _.cloneDeep(list);
  clonedList.sort((a, b) => {
    const aPropValue = _.get(a, property);
    const bPropValue = _.get(b, property);
    if (aPropValue === undefined) {
      console.error(`sort: property '${property}' does not exist`);
    }
    const sortOrder =
      typeof aPropValue === "string" &&
      typeof bPropValue === "string" &&
      aPropValue.toLowerCase() < bPropValue.toLowerCase()
        ? -1
        : 1;
    return sortOrder;
  });
  return clonedList;
}

export default function Dropdown(props) {
  const { showNoneOption, labelFn, valueAttribute, sorted, ...inputProps } =
    props;

  const possiblySorted = sorted
    ? cloneAndSortByProperty(props.items, "label")
    : props.items;

  return (
    <Input type="select" {...inputProps}>
      {showNoneOption && <option value={""}>None</option>}
      {possiblySorted.map((item, ind) => {
        return (
          <option value={_.get(item, valueAttribute)} key={"key" + ind}>
            {labelFn ? labelFn(item) : item.label}
          </option>
        );
      })}
    </Input>
  );
}

Dropdown.defaultProps = {
  showNoneOption: false,
  labelFn: null,
  valueAttribute: "value",
};
