import React, { Component } from 'react';
import {
  find,
  propEq,
} from 'ramda';
import AddOrganizationView from './presentation/AddOrganization';
import { manage } from '../../../api';

export default class AddOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      selectedAsset: null,
      organization: '',
      validAsset: true,
      validOrganization: true,
    };
    this.getAsset = this.getAsset.bind(this);
    this.setAsset = this.setAsset.bind(this);
    this.addOrganization = this.addOrganization.bind(this);
    this.setOrganization = this.setOrganization.bind(this);
  }

  componentDidMount() {
    this.getAsset();
  }

  async getAsset() {
    const assets = await manage.get.assets();
    if (assets.data !== null) {
      assets.data = assets.data.filter(asset => asset.isDeleted === false);
      this.setState({ assets: assets.data });
    }
  }

  setAsset(asset) {
    const { assets } = this.state;
    const selectedAsset = find(propEq('name', asset))(assets);
    this.setState({ selectedAsset });
  }

  setOrganization(org) {
    this.setState({ organization: org });
  }

  isValid() {
    const { organization, selectedAsset } = this.state;
    const validOrganization = organization !== '';
    const validAsset = selectedAsset !== null;
    this.setState({ validAsset, validOrganization });
    return validAsset && validOrganization;
  }

  async addOrganization() {
    const { selectedAsset, organization } = this.state;
    const { hide } = this.props;
    if (this.isValid()) {
      const res = await manage.post.organization(
        organization,
        selectedAsset.assetID,
      ).catch((err) => alert(err.response.data)); // eslint-disable-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ organization: '' });
      }
    }
  }

  render() {
    return (
      <div>
        <AddOrganizationView
          {...this.props}
          {...this.state}
          set={{
            organization: this.setOrganization,
            asset: this.setAsset,
          }}
          submit={this.addOrganization}
        />
      </div>
    );
  }
}
