import React, { Component } from 'react';
import {
  Col,
  Input,
  Label,
  Form,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import { dfr } from '../../../api';
import UnityButton from '../../presentation/unity/Button';
import Loading from '../../presentation/Loading';
import UploadAlert from '../../presentation/Alert';
import { download, xlsx } from './helper';

export default class ImportCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importFile: null,
      invalidFile: false,
      isSuccess: true,
      isAlertOpen: false,
      invalid: true,
      successMessage: 'DFR successfully uploaded.',
      failMessage: 'There was an error uploading your DFR. Please verify the information you\'ve entered is correct, and try again.',
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.submit = this.submit.bind(this);
    this.invalid = this.invalid.bind(this);
  }

  async handleFileUpload(importFiles) {
    const importFile = importFiles[importFiles.length - 1];
    await this.setState({
      importFile,
      invalidFile: importFile !== undefined ? importFile.type !== xlsx.type : true,
    });
    this.invalid(true);
  }

  async invalid(check) {
    const { importFile } = this.state;
    const invalidFile = importFile === null || importFile === undefined;
    if (!check) {
      this.setState({ invalidFile });
    } else {
      await this.setState({ invalid: invalidFile });
    }
    return invalidFile;
  }

  async submit() {
    const { importFile } = this.state;
    if (this.invalid(false)) {
      this.setState({ isUploading: true });
      const formData = new FormData();
      formData.append('ImportFile', importFile);
      const isSuccess = await dfr.post.dfr(formData).then(res => download(
        res.data,
        res.headers,
      )).catch(() => false);
      this.setState({ isSuccess, isAlertOpen: true, isUploading: false });
    }
  }

  dismissAlert() {
    this.setState({ isAlertOpen: false });
  }

  render() {
    const {
      invalidFile,
      isAlertOpen,
      isSuccess,
      invalid,
      successMessage,
      failMessage,
      isUploading,
    } = this.state;
    return (
      <div className="importCompareContainer">
        <Loading isShowing={isUploading} text="Analyzing the DFR..." />
        <Form>
          <FormGroup row>
            <Label for="dfrUpload" sm={2}>
              File
            </Label>
            <Col sm={10}>
              <Input
                type="file"
                name="file"
                id="dfrUpload"
                onChange={e => this.handleFileUpload(e.target.files)}
                invalid={invalidFile}
                accept={xlsx.type}
              />
              <FormFeedback>
                Please upload an xlsx file.
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <UnityButton
                onClick={() => this.submit()}
                disabled={invalid}
                text="Import DFR"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <UploadAlert
                show={isAlertOpen}
                dismiss={() => this.dismissAlert()}
                isSuccess={isSuccess}
                successMessage={successMessage}
                failMessage={failMessage}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
