import React, { Component } from 'react';
import { user } from '../../../api';
import UserModalView from './presentation/ManageUser';

export default class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
    };
  }

  async componentDidMount() {
    const companyRes = await user.get.getCandidateCompanies();
    const companies = companyRes.data;
    this.setState({
      companies,
    });
  }

  render() {
    const { tabName, setEmail, setCompanies } = this.props;
    const { companies } = this.state;
    return (
      <div>
        <UserModalView
          {...this.props}
          companies={companies}
          showContract={tabName !== 'Security Guard' && /*tabName !== 'Endorser' && */ tabName !== 'Admin'}
          setEmail={setEmail}
          setCompanies={setCompanies}
        />
      </div>
    );
  }
}
