import React from 'react';

export default function Field(props) {
    let { isValid } = props;
    if (isValid === null || isValid === undefined) {
        isValid = true
    }
    return (
        <div>
                {props.children}
                <div className={!isValid ? 'invalid-feedback show' : 'invalid-feedback'}>
                    {props.validationMessage}
                </div>
        </div>
    )
}