import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isUser } from '../../../../js/msal/msalHelper';

class ApproverRoute extends Component {
  render() {
    const { component: ReactComponent, ...rest } = this.props;

    if (!isUser.approver() && !isUser.admin()) {
      return (<Redirect to="/" />);
    }

    return (
      <Route
        {...rest}
        render={props => <ReactComponent {...props} />}
      />
    );
  }
}

export default ApproverRoute;
