import * as Actions from '../../actions/editCcrActions';
import { manageHeadcount, initialState as headcountInitialState } from './manageHeadcount';
import { manageApprovers, initialState as approversInitialState} from './manageApprovers';
import { manageCCR, initialState as ccrInitialState } from './manageCcr';
import { convertLookup } from './editCcr.logic';
const initialState = {
    loading: false,
    manageHeadcount: headcountInitialState,
    manageCCR: ccrInitialState,
    manageApprovers: approversInitialState
};

const editCCR = (state = initialState, action) => {
    switch(action.type) {
        case Actions.EDIT_CCR_NEW:
            return {
                ...state,
                status: 'new',
                id: 0,
                manageHeadcount: manageHeadcount(state.manageHeadcount, action),
                manageCCR: manageCCR(state.manageCCR, action),
                manageApprovers: manageApprovers(state.manageApprovers, action)
            }
        case Actions.EDIT_CCR_SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case Actions.EDIT_CCR_SET_DATA:
            const ccr = action.payload.ccr;
            return {
                loading: false,
                status: ccr ? 'edit' : 'new',
                id: ccr ? ccr.data.ccrID : 0,
                lookup: convertLookup(action.payload),
                manageHeadcount: manageHeadcount(state.manageHeadcount, action),
                manageCCR: manageCCR(state.manageCCR, action),
                manageApprovers: manageApprovers(state.manageApprovers, action)
            }
        case Actions.EDIT_CCR_RESET:
            return initialState;
        default:
            return {
                ...state,
                manageHeadcount: manageHeadcount(state.manageHeadcount, action),
                manageCCR: manageCCR(state.manageCCR, action),
                manageApprovers: manageApprovers(state.manageApprovers, action)
            };
    }
}

export { editCCR };
