import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';

export default class Datepicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateStart: null,
      dateEnd: null,
      dateFocused: null,
    };
  }

  updateDates(dateStart, dateEnd) {
    const { onSet } = this.props;
    this.setState({
      dateStart,
      dateEnd,
    });
    onSet({
      startDate: dateStart,
      endDate: dateEnd,
    });
  }

  updateDateFocus(dateFocused) {
    this.setState({
      dateFocused,
    });
  }

  render() {
    const {
      dateEnd,
      dateFocused,
      dateStart,
    } = this.state;
    const { disabled } = this.props;
    return (
      <DateRangePicker
        startDate={dateStart}
        startDateId="startDate"
        endDate={dateEnd}
        endDateId="endDate"
        onDatesChange={({ startDate, endDate }) => this.updateDates(startDate, endDate)}
        focusedInput={dateFocused}
        onFocusChange={focused => this.updateDateFocus(focused)}
        disabled={disabled ? 'endDate' : false}
        numberOfMonths={3}
        small
        required
        showClearDates
        reopenPickerOnClearDates     
        isOutsideRange={() => false}
      />
    );
  }
}
