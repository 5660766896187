import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import user from './user';
import { selectedCCR } from './selectedCcr';
import { errorState } from './errorMessage';
import { ccrList } from './ccrList';
import { editCCR } from './EditCCR';
import { approveCcr } from './approveCcr';
import { reassignCCR } from './reassignCCR';


const persistConfigEdit = {
  key: 'edit',
  storage,
}

const rootReducer = combineReducers({
  user,
  selectedCCR,
  editCCR: persistReducer(persistConfigEdit, editCCR),
  reassignCCR,
  errorState,
  ccrList,
  approveCcr
});

export default rootReducer;
