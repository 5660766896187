import React, { PureComponent } from 'react';
import {
    Col,
    TabContent,
    TabPane,
    Row,
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
import classnames from 'classnames';

export default class Wizard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { activeTab : 0 };
        this.toggle = this.toggle.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }

    toggle(tab) {
        if (tab < this.state.activeTab) {
                this.setState({ activeTab : tab })
        }
    }

    next() {
        this.setState({
            activeTab : this.state.activeTab + 1
        })
    }

    back(){
        if (this.state.activeTab > 0)
        this.setState({
            activeTab : this.state.activeTab - 1
        })
    }

    render() {
        const { headers, components } = this.props;
        return (
            <React.Fragment>
                <Nav tabs>
                    {
                        headers.map((item, ind) => {
                            return (
                            <NavItem key={'tab' + ind}>
                            <NavLink key={'tab' + ind}
                            className={`${classnames({ active: this.state.activeTab === ind })} tabs`}
                            onClick={() => { this.toggle(ind); }}
                            disabled={this.state.activeTab < ind }
                            > {item} </NavLink></NavItem>);
                        })
                    }
                </Nav>
                <br />
                <TabContent activeTab={this.state.activeTab}>
                    {
                        components.map((item, ind) => {
                            const extendedElement = React.cloneElement(item, {next: this.next, back: this.back})
                            return (<TabPane tabId={ind} key={'tabpane' + ind}>
                                <Row>
                                    <Col sm="12">
                                        {extendedElement}
                                    </Col>
                                </Row>
                            </TabPane>);
                        })
                    }
                </TabContent>
            </React.Fragment>
        );
    }
}
