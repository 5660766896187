import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Col,
} from 'reactstrap';
import {
  find,
  propEq,
} from 'ramda';
import UnityButton from '../../presentation/unity/Button';
import Loading from '../../presentation/Loading';
import Dropdown from '../Input/Dropdown';
import { download } from './helper';
import { dfr } from '../../../api';

export default class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyID: null,
    };
    this.setSelected = this.setSelected.bind(this);
    this.getDFR = this.getDFR.bind(this);
  }

  componentDidMount() {
    this.setState({ companyID: null });
  }

  setSelected(company) {
    const { companies } = this.props;
    const selected = find(propEq('name', company))(companies);
    if (selected !== null) {
      this.setState({ companyID: selected.companyID });
    } else {
      this.setState({ companyID: null });
    }
  }

  async getDFR() {
    const { companyID } = this.state;
    this.setState({ isDownloading: true });
    await dfr.get.dfr(companyID)
      .then(res => download(res.data, res.headers))
      .catch(() => false);
    this.setState({ isDownloading: false });
  }

  render() {
    const { companies } = this.props;
    const { companyID, isDownloading } = this.state;
    return (
      <div>
        <Loading isShowing={isDownloading} text="Fetching the DFR..." />
        <Form>
          <FormGroup row>
            <Dropdown
              options={companies}
              label="Company Name"
              onSelect={value => this.setSelected(value)}
              nullable
            />
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <UnityButton
                disabled={companyID === null || companyID === undefined}
                onClick={this.getDFR}
                text="Export DFR"
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
