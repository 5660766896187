import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import icons from "../../../../../assets/images/em-icons.svg";
import TableLayout from "../../../../containers/Table";
import UnityButton from "../../../../presentation/unity/Button";
import BreadcrumbNav from "../../../../presentation/Breadcrumb";
import CCRView from "../../../../containers/Modal/CCRView";
import { isUser } from "../../../../../js/msal/msalHelper";
import ToggleButton from "../../../../presentation/Input/ToggleButton";
import { Input } from "reactstrap";
import CCRRecoverStateAlert from "../CCRRecoverStateAlert/CCRRecoverStateAlert";
import Loading from "../../../../presentation/Loading";

const table = {
  headers: ["Request#", "Title", "Status", "Last Modified"],
  fields: ["title", "ccrStatus.name", "dateModified"],
  renderFunctions: {
    "ccrStatus.name": (status) => {
      let badgeTypeClass = "";
      switch (status) {
        case "Approved":
          badgeTypeClass = "em-c-badge--positive";
          break;
        case "Denied":
          badgeTypeClass = "em-c-badge--negative";
          break;
        case "Submitted":
        case "For Review":
          badgeTypeClass = "em-c-badge--caution";
          break;
        default:
          break;
      }
      return <span className={`em-c-badge ${badgeTypeClass}`}>{status}</span>;
    },
    title: (value) => {
      let val = value.split("&amp;").join("&");
      val = val.split("&#160;").join("&");
      val = val.split("&lt;").join("<");
      return val.split("&gt;").join(">");
    },
  },
};

const breadcrumb = [{ name: "Home", isActive: true }];

export default class CCRListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalCCRID: null,
      selectedFilterButton: "All",
    };
    this.setModalCCRID = this.setModalCCRID.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  setSelectedFilterButton(filter) {
    this.setState({
      ...this.state,
      selectedFilterButton: filter,
    });
    this.props.actions.search();
  }
  setModalCCRID(modalCCRID) {
    this.setState({ ...this.state, modalCCRID });
  }

  hideModal() {
    this.setState({ ...this.state, modalCCRID: null });
  }

  _handleKeyDown(e) {
    if (e.key === "Enter") {
      this.props.actions.search();
    }
  }
  checkApproveFilter(showApproveFilter) {
    if (showApproveFilter) return ["All", "My Requests", "My Approval"];
    else return ["All", "My Requests"];
  }

  OptionItem = (num, text) => (
    <div className="em-c-toolbar__item ">
      <label
        className="em-c-input-group em-u-display-inline-block util-margin-auto"
        htmlFor={`option-${num}`}
      >
        <span className="em-c-input-group__text ">{text}</span>
      </label>
    </div>
  );

  render() {
    const { items, actions, isLoading } = this.props;
    const { modalCCRID } = this.state;

    const showApproveFilter = isUser.approver();

    return (
      <div className="homeContainer ">
        <BreadcrumbNav items={breadcrumb} />
        <div className="ccrContainer em-c-em-u-margin-bottom-double">
          <div className="util-overflow-hidden em-u-margin-bottom">
            <h1 className="util-float-left">CCRs</h1>
            <UnityButton
              className="float-right"
              text="New Request"
              icon="upload"
              color="primary"
              to="/ccr/new"
              href="/ccr/new"
            />
          </div>
          <CCRRecoverStateAlert />

          {modalCCRID !== null ? (
            <CCRView ccrID={modalCCRID} hide={() => this.hideModal()} />
          ) : null}
          <div className="em-c-collapsible-toolbar em-js-collapsible-toolbar util-clear-both em-u-margin-top util-box-shadow">
            <div className="em-c-collapsible-toolbar__panel em-js-toolbar-panel">
              <div className="em-c-toolbar util-border-none">
                {/* this.OptionItem(1, "My Requests") */}
                {/* showEndorseFilter === true && this.OptionItem(2, "Waiting For My Endorse")*/}
                {/* showApproveFilter === true && this.OptionItem(2, "Waiting For My Approval")*/}

                <div className="em-u-margin-left-half em-u-margin-right-half">
                  <ToggleButton
                    name={"FilterRequests"}
                    options={this.checkApproveFilter(showApproveFilter)}
                    onChange={(e) => this.setSelectedFilterButton(e)}
                    selected={this.state.selectedFilterButton}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <Input
                    name="searchInput"
                    id="searchInput"
                    onChange={(e) => this.props.onSearchChange(e.target.value)}
                    onKeyDown={this._handleKeyDown.bind(this)}
                  />
                </div>

                <div className="em-c-toolbar__item em-is-aligned-right em-c-field em-c-field--inline em-c-field--small">
                  <button
                    className="em-c-btn  em-c-btn--primary em-c-btn--small em-u-margin-left-half em-js-hidden"
                    type="button"
                    onClick={() => actions.search()}
                  >
                    <div className="em-c-btn__inner">
                      <span className="em-c-btn__text">Filters and Sort</span>
                      <svg className="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                        <use
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref={`${icons}#search`}
                          href={`${icons}#search`}
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <TableLayout
            headers={table.headers}
            fields={table.fields}
            renderFunctions={table.renderFunctions}
            list={items}
            theadClass={"thead-dark"}
            tableMainClass={"em-u-margin-top"}
            isCCRList
            setModal={(ccrID) => this.setModalCCRID(ccrID)}
          />

          {isLoading && (
            <div className="em-u-margin-double">
              <Loading isShowing inLine text={"Loading CCRs..."} />
            </div>
          )}
          {!isLoading && !items.length && (
            <div
              className="em-c-alert em-c-alert--warning"
              role="alert"
              style={{ width: "fit-content", margin: "auto" }}
            >
              <svg className="em-c-icon em-c-icon--small em-c-alert__icon">
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="../../images/em-icons.svg#warning"
                ></use>
              </svg>
              <div className="em-c-alert__body">
                Sorry, No CCRs Found! Try changing the search criteria.
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

CCRListView.defaultProps = {
  items: [],
  isLoading: true,
  isError: false,
};

CCRListView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};
