import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import UnityButton from '../../presentation/unity/Button';
import CCRReview from '../../presentation/CCR/Review';
import { resetSelectedCCRAction, showErrorAction, getCcrAction } from '../../../redux/actions';
import { getCcr, getSelectedHeadlines, getSelectedLoading,
  getSelectedPrevious, getDiffPreviousCurrent } from '../../../redux/selectors/selectedCcr';
import { generateEmlFile, clone } from '../../../utils/email';
import { ccr as ccrApi } from '../../../api';
import Tabs from '../Nav/Tabs'
import Loading from '../../presentation/Loading';
import { filterDuplicateIndefiniteHeadCounts, filterDuplicaterecords } from '../../../redux/reducers/EditCCR/manageHeadcount.logic';

export class CCRView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ccr: null,
      headcounts: [],
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.drawCanvas = this.drawCanvas.bind(this);
    this.downloadLink = React.createRef();
    this.revert = this.revert.bind(this);
  }

  componentDidMount() {
    const { ccrID } = this.props;
    this.props.getCcrAction(ccrID);
    //this.props.setSelectedCCRAsync(ccrID);
    this.setState({
      modal: true,
    });
  }

  toggle() {
    const { hide } = this.props;
    const { modal } = this.props;
    this.props.resetSelectedCCRAction();
    if (!modal) {
      hide();
    }
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  downloadEmail(imgData) {
    const { ccrNumber } = this.props.ccr;
    let fls = [
      { fileType: 'image/png', fileName: `current_ccr_${ccrNumber}.png`, data: imgData[0] }
    ]
    if (imgData.length > 1) {
      fls.push({ fileType: 'image/png', fileName: `previous_ccr_${ccrNumber}.png`, data: imgData[1] })
    }
    var encodedUri = generateEmlFile(`CCR ${ccrNumber}`, '', fls);
    var a = document.createElement('a');
    a.href = encodedUri;
    a.download = `email-ccr-${ccrNumber}.eml`;
    a.click();
  }

  async revert() {
    const response = window.confirm(`Are you sure you want to revert this CCR? The current CCR will be deleted and the data will revert to the original.`);
    if (response === true) {
      const { ccr: { ccrID}} = this.props;
      try {
        await ccrApi.post.revert(ccrID);
        window.location.reload(true);
      } catch(e) {
        this.props.showErrorAction("Cannot revert that CCR", e)
      } 
    }
  }

  drawCanvas() {
    clone().then(imgs => {
      this.downloadEmail(imgs)
    });
  }

  render() {   
    const { modal } = this.state;
    const { ccr, loading, previous, headlines, diff } = this.props;
    let components = [ (<CCRReview {...ccr} headcounts={ccr.headcountRequests} id="currentCCRView" highlights={diff} mode={'current'}/>)];
    if (previous) 
      components.push((<CCRReview {...previous} headcounts={previous.headcountRequests} id="previousCCRView" highlights={diff} mode={'previous'}/>))
    
    return (
      <div>
        <Modal isOpen={modal} toggle={this.toggle} className='modal-xxl'>
          <ModalHeader toggle={this.toggle}>
            View CCR {ccr ? ccr.ccrNumber : ''}
          </ModalHeader>
          <ModalBody id="ccrBoxView" className='kmod-view-ccr-modal-body'>
            {
              loading ? (
                <div style={{ minHeight: "300px" }}>
                  <Loading isShowing inLine absCenter text={"Loading CCR..."} />
                </div>
              ) : <Tabs headers={headlines} components={components} />
            }
            <div id="ccc"></div>
          </ModalBody>
          <ModalFooter>
            {
              ccr && !loading && (<React.Fragment>
              { ccr && ccr.isEditedDenied && (<UnityButton text="Revert" onClick={this.revert} />) }
              { ccr && ccr.isEditable && (<Link to={'/ccr/edit/' + ccr.ccrID}><UnityButton text="Edit" /></Link>) }
              <UnityButton text="Email" id="backButton" onClick={this.drawCanvas} />
              <Link to='/ccr/print'><UnityButton text="Print" /></Link>
              </React.Fragment>)
            }
            <UnityButton onClick={this.toggle} text="Close" />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ccr: getCcr(state),
  loading: getSelectedLoading(state),
  headlines: getSelectedHeadlines(state),
  previous: getSelectedPrevious(state),
  diff: getDiffPreviousCurrent(state)
});

const mapDispatchToProps = {
  getCcrAction,
  resetSelectedCCRAction,
  showErrorAction
};


export default connect(mapStateToProps, mapDispatchToProps)(CCRView);