import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input } from "reactstrap";
import {
  ccrReadData,
  lookup,
  getRequestor,
} from "../../../redux/selectors/editCcr";
import { updateRequirements } from "../../../redux/actions/editCcrActions";
import { quitCcr } from "../../../redux/actions/editCcrActions";
import {
  Dropdown,
  PriceInput,
  FormField,
  TextArea
} from "../../presentation/Input";
import WizardButtonBar from "../Nav/Wizard/WizardButtonBar";
import { validateRequestForm } from "./Validator";

export class RequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        ...props.ccr,
        requestorID: props.requestor.requestorID,
        requestor: props.requestor,
      },
      lists: {
        sites: props.lookup.sites,
        requestors: [this.props.requestor].map((x) => ({
          value: x.id,
          label: x.name,
        })),
        endorsors: props.lookup.endorsors,
      },
      validations: {
        title: {
          validationMessage: "",
          isValid: true,
          maxLength: 156,
          minLength: 5,
        },
        justification: { isValid: true },
        annualEstimatedCost: { isValid: true },
        equipmentVehicleEstimatedCost: { isValid: true },
        equipmentDetails: { isValid: true },
      },
    };
    this.onContinue = this.onContinue.bind(this);
  }

  escapeHTML(value) {
    return value
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  convertHTML(value) {
    let val = value.split("&amp;").join("&");
    val = val.split("&lt;").join("<");
    return val.split("&gt;").join(">");
  }

  setValue(field, value) {
    this.setState({
      values: {
        ...this.state.values,
        [field]: value,
      },
    });
  }

  onPriceCheck(checkFieldName, value) {
    if (checkFieldName === "purchaseOrderUpdateRequired") {
      this.setState({
        values: {
          ...this.state.values,
          purchaseOrderUpdateRequired: value,
          annualEstimatedCost: "",
        },
      });
    } else {
      this.setState({
        values: {
          ...this.state.values,
          equipmentVehicleInvolved: value,
          equipmentDetails: "",
          equipmentVehicleEstimatedCost: "",
        },
      });
    }
  }

  onContinue() {
    const validations = validateRequestForm(
      this.state.values,
      this.state.validations
    );

    this.setState({ validations });
    if (Object.values(validations).every((x) => x.isValid)) {
      this.props.updateRequirements(this.state.values);
      this.props.next();
    }
  }

  render() {

    let {
      title,
      justification,
      equipmentDetails,
      annualEstimatedCost,
      equipmentVehicleEstimatedCost,
    } = this.state.validations;

    const values = this.state.values;
    const lists = this.state.lists;

    if (values.title) values.title = this.convertHTML(values.title);

    return (
      <div>
        <h5> Request Information </h5>
        <br />
        <FormField
          label="Title"
          id="CCRTitle"
          isValid={title.isValid}
          validationMessage={title.validationMessage}
        >
          <Input
            name="Title"
            id="CCRTitle"
            maxLength={title.maxLength}
            minLength={title.minLength}
            placeholder="Please title the CCR request."
            invalid={!title.isValid}
            value={values.title}
            onChange={(e) =>
              this.setValue("title", this.escapeHTML(e.target.value))
            }
          />
        </FormField>
        <FormField label="Asset" id="Asset">
          <Dropdown
            name="Asset"
            id="Asset"
            disabled={true}
            onChange={(e) => this.setValue("asset", e.target.value)}
            items={lists.sites}
          />
        </FormField>
        <FormField
          label="Justification"
          id="Justification"
          isValid={justification.isValid}
          validationMessage={justification.validationMessage}
        >
          <TextArea
            type="textarea"
            name="justificationText"
            id="Justification"
            enableToolbar
            value={values.justification}
            placeholder="Justification..."
            onChange={(val) => this.setValue("justification", val)}
          />
        </FormField>
        <FormField label="Requestor" id="Requestor">
          <Dropdown
            name="Requestor"
            id="Requestor"
            items={lists.requestors}
            value={values.requestorID}
            disabled={true}
            style={{ background: "white" }}
          />
        </FormField>
        <FormField
          label="PO Update"
          id="poupdate"
          isValid={annualEstimatedCost.isValid}
          validationMessage={annualEstimatedCost.validationMessage}
        >
          <PriceInput
            label="poupdate"
            isRequired={values.purchaseOrderUpdateRequired}
            onCheck={(val) =>
              this.onPriceCheck("purchaseOrderUpdateRequired", val)
            }
            onChange={(val) =>
              this.setValue("annualEstimatedCost", Number(val))
            }
            placeholder="Annual Estimated Cost"
            value={values.annualEstimatedCost}
          />
        </FormField>
        <FormField
          label="Equipment or Vehicle"
          id="equipmentOrVehicle"
          isValid={equipmentVehicleEstimatedCost.isValid}
          validationMessage={equipmentVehicleEstimatedCost.validationMessage}
        >
          <PriceInput
            label="equipmentOrVehicle"
            isRequired={values.equipmentVehicleInvolved}
            onCheck={(val) =>
              this.onPriceCheck("equipmentVehicleInvolved", val)
            }
            onChange={(val) =>
              this.setValue("equipmentVehicleEstimatedCost", Number(val))
            }
            placeholder="Annual Estimated Cost"
            value={values.equipmentVehicleEstimatedCost}
          />
        </FormField>
        <FormField
          label="Equipment or Vehicle Details"
          id="equipmentOrVehicleDetails"
          isValid={equipmentDetails.isValid}
          validationMessage={equipmentDetails.validationMessage}
        >
          <TextArea
            type="textarea"
            name="equipmentOrVehicleDetailsText"
            id="equipmentOrVehicleDetails"
            placeholder={values.equipmentVehicleInvolved ? "Please enter details..." : ""}
            value={values.equipmentDetails || ""}
            disabled={!values.equipmentVehicleInvolved}
            onChange={(val) => this.setValue("equipmentDetails", val)}
          />
        </FormField>
        <WizardButtonBar
          continueMethod={this.onContinue}
          disableBackButton
          quitMethod={() => {
            this.props.quitCCR(this.props.history);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ccr: ccrReadData(state),
  lookup: lookup(state),
  requestor: getRequestor(),
});

const mapDispatchToProps = {
  updateRequirements,
  quitCCR: quitCcr,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RequestForm));
