import React from 'react';
import { Alert } from 'reactstrap';

const Error = (props) => {
  const { show, dismiss, copy } = props;
  return (
    <Alert color="danger" isOpen={show} toggle={dismiss}>
      {copy}
    </Alert>
  );
};

export default Error;
