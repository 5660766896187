import React, { Component } from 'react';
import Select from 'react-select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
} from 'reactstrap';
import UnityButton from '../../../presentation/unity/Button';

export default class AddRoleView extends Component {
  render() {
    const {
      set,
      isOpen,
      hide,
      submit,
      validRole,
      organizations,
      validOrganization,
    } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => hide()} className="modal-xl">
          <ModalHeader toggle={() => hide()}>
            Add Role
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="RoleName" sm={2}>
                  Role&nbsp;Name
                </Label>
                <Col sm={10}>
                  <Input
                    name="RoleName"
                    id="RoleName"
                    onChange={e => set.role(e.target.value)}
                    invalid={!validRole}
                  />
                  <FormFeedback>
                    {'Please enter a role name.'}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm="2">
                  <p className="section-heading" key="label">
                    Organization(s)
                  </p>
                </Col>
                <Col sm="10">
                  <Select
                    isMulti
                    options={organizations}
                    onChange={value => set.organization(value)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.organizationID}
                    invalid={!validOrganization}
                    className={`over-button ${!validOrganization ? 'is-invalid' : ''}`}
                  />
                  <div className={!validOrganization ? 'invalid-feedback show' : 'invalid-feedback'}>
                    {'Please select at least one organization.'}
                  </div>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <UnityButton color="primary" onClick={() => submit()} text="OK" />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
