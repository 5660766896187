
export const EDIT_CCR_NEW = 'EDIT_CCR_NEW';
export const EDIT_CCR_SET_LOADING = 'EDIT_CCR_SET_LOADING';
export const EDIT_CCR_RESET = 'EDIT_CCR_RESET';
export const EDIT_CCR_SET_DATA = 'EDIT_CCR_SET_DATA';
export const EDIT_CCR_QUIT = 'EDIT_CCR_QUIT';
export const EDIT_CCR_UPDATE_REQUIREMENTS = 'EDIT_CCR_UPDATE_REQUIREMENTS';
export const EDIT_CCR_SUBMIT = 'EDIT_CCR_SUBMIT';
export const EDIT_CCR_GET = 'EDIT_CCR_GET';

export const newCCRAction = () => ({
    type: EDIT_CCR_NEW
});

export const setEditCcrloading = (loading) => ({
    type: EDIT_CCR_SET_LOADING,
    payload: loading
});

export const resetEditCcr = () => ({
    type: EDIT_CCR_RESET
});

export const setEditCcrData = data => ({
    type: EDIT_CCR_SET_DATA,
    payload: data
});

export const quitCcr = history => ({
    type: EDIT_CCR_QUIT,
    payload: history
});

export const updateRequirements = data => ({
    type: EDIT_CCR_UPDATE_REQUIREMENTS,
    payload: data
})

export const submitCCRAction = ccr => ({
    type: EDIT_CCR_SUBMIT,
    payload: ccr
});

export const getEditCCRAsync = ccrid => ({
    type: EDIT_CCR_GET,
    payload: ccrid
});
