import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  FormFeedback,
  Col,
  Input,
  Label,
  Alert,
} from 'reactstrap';
import UnityButton from '../../../../presentation/unity/Button';
import handleBadgeLength from '../../../../../utils/number/handleBadgeLength';

export default class BadgeIDEntryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ isOpen: false });
  }

  render() {
    const {
      headcountIDValid,
      searchedHeadcountID,
      positionRole,
      usageType,
      handleUserInput,
      addBadgeID,
      badgeIDValid,
      badgeID,
      validationMessage,
    } = this.props;
    const {
      isOpen,
    } = this.state;
    return (
      <div className="homeContainer">
        <div className="ccrContainer">
          {headcountIDValid
            ? (
              <Form onSubmit={(e) => { e.preventDefault(); addBadgeID(); }}>
                <FormGroup row>
                  <Label for="HeadcountID" sm={2}>
                    Headcount ID
                  </Label>
                  <Col sm={10}>
                    <Input
                      name="HeadcountID"
                      placeholder={searchedHeadcountID}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="Role" sm={2}>
                    Role
                  </Label>
                  <Col sm={10}>
                    <Input
                      name="Role"
                      placeholder={positionRole}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="UsageType" sm={2}>
                    Usage Type
                  </Label>
                  <Col sm={10}>
                    <Input
                      name="UsageType"
                      placeholder={usageType}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="BadgeID" sm={2}>
                    Badge ID
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="number"
                      name="BadgeID"
                      id="BadgeID"
                      min="0"
                      maxLength="10"
                      onKeyDown={e => handleBadgeLength(e)}
                      onKeyUp={e => handleUserInput(e)}
                      invalid={!badgeIDValid}
                      defaultValue={badgeID}
                    />
                    <FormFeedback>
                      { validationMessage }
                    </FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <UnityButton text="Add Badge ID" disabled={badgeID === null || badgeID === ''} />
                </FormGroup>
              </Form>
            )
            : (
              <Alert color="danger" isOpen={isOpen} toggle={this.onDismiss}>
                Headcount ID not found
              </Alert>
            )
            }
        </div>
      </div>
    );
  }
}
