import { createSelector } from 'reselect';

import { getUser, getDBUser } from '../../js/msal/msalHelper';
import { siteDateRestructure } from './editCcr.logic';

// ************************************* User Data ************************* 
export const getRequestor = () => {
    const user = getUser();
    return {
        name: `${user.name} (${user.username})`,
        id: user.idTokenClaims.oid,
        email: user.username,
        requestorID: (getDBUser()).userID
    }
}

// ************************************* Edit CCR ************************* 

export const editCCR = (state) => state.editCCR;

export const manageCcr = createSelector([editCCR], editCCR => editCCR.manageCCR)

export const ccrData = createSelector([manageCcr], manageCcr => manageCcr.ccr)

export const ccrReadData = createSelector([ccrData], ccrData => {
    let ccr = Object.assign({}, ccrData);
    return ccr;
});

export const editCcrLoading = createSelector([editCCR, ccrData], (editCCR, ccr) => editCCR.loading)


// ************************************* Lookup Data *************************

export const lookup = createSelector([editCCR], editCCR => editCCR.lookup);
export const lookupSiteStructure = createSelector([lookup], lookup => siteDateRestructure(lookup.siteStructure[0]));
