export const showErrorAction = (errorMessage, errorObj = null) => ({
    type: 'SHOW_ERROR',
    errorMessage,
    errorObj
});

export const hideErrorAction = () => ({
    type: 'HIDE_ERROR'
});

