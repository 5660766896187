import React from 'react';
import icons from '../../../assets/images/em-icons.svg';

export default (props) => {
  const { name, className } = props;
  return (
    <svg className={`em-c-icon em-c-icon--small em-c-btn__icon ${className}`} data-em-icon-path={icons}>
      <use xmlns="http://www.w3.org/2000/svg" xlinkHref={`${icons}#${name}`} href={`${icons}#${name}`} />
    </svg>
  );
};
