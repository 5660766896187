const handleKeyPress = (e) => {
  if (e.key !== '0' // e
    && e.key !== '1'
    && e.key !== '2'
    && e.key !== '3'
    && e.key !== '4'
    && e.key !== '5'
    && e.key !== '6'
    && e.key !== '7'
    && e.key !== '8'
    && e.key !== '9'
    && e.keyCode !== 8 // backspace
    && e.keyCode !== 9 // tab
    && e.keyCode !== 13 // enter
    && e.keyCode !== 37 // arrow
    && e.keyCode !== 38 // arrow
    && e.keyCode !== 39 // arrow
    && e.keyCode !== 40 // arrow
    && e.keyCode !== 46 // delete
  ) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
  }
};

export default handleKeyPress;
