import { createSelector } from "reselect";

// Pointers
const manageApprovers = (state) => state.editCCR.manageApprovers;
const requestorID = (state) => state.editCCR.manageCCR.ccr.requestorID;
const allApprovers = (state) => state.editCCR.manageApprovers.all_approvers;
const headcounts = (state) => state.editCCR.manageHeadcount.headcounts;

export const defaultHint = (state) =>
  state.editCCR.manageApprovers.show_default_hint;
export const originalApprovers = (state) =>
  state.editCCR.manageApprovers.original_approvers;
export const selectedApprovers = (state) =>
  state.editCCR.manageApprovers.selected_approvers;
export const lastApprovedApprovers = (state) =>
  state.editCCR.manageApprovers.last_approved_approvers;

export const getSelectedCompanies = createSelector(
  [headcounts],
  (headcounts) => {
    if (!allApprovers || !headcounts) return [];
    return headcounts.reduce((agg, hc) => {
      agg.add(hc.companyID);
      return agg;
    }, new Set());
  }
);

export const getApplicableApprovers = createSelector(
  [allApprovers, getSelectedCompanies, requestorID],
  (allApprovers, selectedCompanies, requestorID) => {
    const applicableApprovers = allApprovers.reduce((agg, approver) => {
      if (
        approver.userID !== requestorID &&
        approver.companyID.some((id) => selectedCompanies.has(id))
      ) {
        agg.push(approver);
      }
      agg.sort();
      return agg;
    }, []);

    // Sort
    applicableApprovers.sort((a, b) =>
      getLowerCaseName(a).localeCompare(getLowerCaseName(b))
    );

    return applicableApprovers;
  }
);

export const getRecommendedApprovers = createSelector(
  [allApprovers, getSelectedCompanies],
  (allApprovers, selectedCompanies) => {
    let recommendedApprovers = [];

    selectedCompanies.forEach((company) => {
      for (let i = 0; i < allApprovers.length; i++) {
        if (allApprovers.at(i).companyID.some((id) => company === id)) {
          if (
            !recommendedApprovers.some(
              (approver) => approver.userID === allApprovers.at(i).userID
            )
          ) {
            recommendedApprovers.push({ ...allApprovers.at(i) });
          }
          break;
        } else {
          continue;
        }
      }
    });

    return recommendedApprovers;
  }
);

const getLowerCaseName = (user) =>
  `${user.firstName} ${user.lastName}`.toLowerCase();

export const getSelectedApprovers = createSelector(
  [manageApprovers],
  (x) => x.selected_approvers
);

export const getOriginalApprovers = createSelector(
  [manageApprovers],
  (x) => x.original_approvers
);

export const getLastApprovedApprovers = createSelector(
  [manageApprovers],
  (x) => x.last_approved_approvers
);
