import { all } from 'redux-saga/effects';
import selectedCcr from './selectCcr';
import editCcr from './editCcr';
import approveCcr from './approveCcr';
import reassignCCR from './reassignCCR';

export default function* root() {
    yield all([
        ...selectedCcr.map(saga => saga()),
        ...editCcr.map(saga => saga()),
        ...approveCcr.map(saga => saga()),
        ...reassignCCR.map(saga => saga())
    ]);
}