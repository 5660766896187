import { APPROVE_CCR_LOADING_ACTION } from '../actions/ccrApprovals'
const initialState = {
    loading: false,
    isOpen: false
};
export const approveCcr = (state = initialState, action) => {
    switch (action.type) {
        case APPROVE_CCR_LOADING_ACTION:
            return { loading: action.payload };
        default:
            return state;
    }
};