
export const DECLINE_CCR = 'DECLINE_CCR';
export const APPROVE_CCR = 'APPROVE_CCR';
export const APPROVE_CCR_LOADING_ACTION = 'APPROVE_CCR_LOADING_ACTION';

export const declineCCRAction = (data, history) => ({
    type: DECLINE_CCR,
    payload: {data, history},
});

export const approveCCRAction = (data, history) => ({
    type: APPROVE_CCR,
    payload: {data, history},
});


export const setApproveCcrLoadingAction = loading => ({
    type: APPROVE_CCR_LOADING_ACTION,
    payload: loading
});

