import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ManageApprovers from './ManageApprovers';
import WizardButtonBar from '../../Nav/Wizard/WizardButtonBar';
import { quitCcr } from '../../../../redux/actions/editCcrActions';
import { updateSelectedApprovers, setDefaultApprovers } from '../../../../redux/actions/manageApproversActions';
import { getApplicableApprovers, getSelectedApprovers, getRecommendedApprovers } from '../../../../redux/selectors/manageApprovers';

export const ManageApproversContainer = props => {

    const { applicableApprovers, selectedApprovers, updateSelectedApprovers, setDefaultApprovers, recommendedApprovers } = props;
    const history = useHistory();
    const [defaultHint, setDefaultHint] = useState(false);

    const setDefaultHintTimed = () => {
        setDefaultHint(true);
        setTimeout(() => {
            setDefaultHint(false);
        }, 5000);
    }

    return (
        <React.Fragment>
            <ManageApprovers 
                applicableApprovers = {applicableApprovers} 
                selectedApprovers = {selectedApprovers} 
                recommendedApprovers = {recommendedApprovers}
                showDefaultHint = {defaultHint}
                onChange = {updateSelectedApprovers} 
                setDefault = { x => {setDefaultHintTimed(); setDefaultApprovers(x);}}
                {...props} 
            />
            <WizardButtonBar 
                isContinueDisabled = {!selectedApprovers.length}
                continueMethod = {props.next}
                backMethod = {props.back}
                quitMethod={()=>props.quitCcr(history)}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    applicableApprovers: getApplicableApprovers(state),
    selectedApprovers: getSelectedApprovers(state),
    recommendedApprovers: getRecommendedApprovers(state)
});

const mapDispatchToProps = {
    updateSelectedApprovers: updateSelectedApprovers,
    setDefaultApprovers: setDefaultApprovers,
    quitCcr: quitCcr
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageApproversContainer);
