import { convertHTML } from "../../../utils/string/htmlParse";

const editProps = [
  "ccrID",
  "ccrNumber",
  "title",
  "ccrStatusID",
  "requestorID" /*'endorserDifferentThanApprover', 'endorserID', */,
  "approverID",
  "justification",
  "purchaseOrderUpdateRequired",
  "annualEstimatedCost",
  "equipmentVehicleInvolved",
  "equipmentVehicleEstimatedCost",
  "equipmentDetails",
  "prevID",
  "nextID",
  "acchiveID",
];

const ccrViewProps = ["approver", /* 'endorser', */ "requestor"];

export const getCcrFromApiData = (data) => {
  if (!data) return null;
  let ccr = {};
  editProps.concat(ccrViewProps).forEach((prop) => {
    _.assign(ccr, { [prop]: _.get(data, prop) });
  });
  ccr.title = convertHTML(ccr.title);
  ccr.justification = convertHTML(ccr.justification);
  if (ccr.equipmentDetails) convertHTML(ccr.equipmentDetails);
  return ccr;
};
