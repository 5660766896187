import { manageCalls } from './endpoints/manage';
import { userCalls } from './endpoints/user';
import {
  badgeCalls,
  ccrCalls,
  dfrCalls,
  siteCalls,
  unassignedPersonnelCalls,
  workcenterCalls,
} from './endpoints/ccr';

export const moc = {
  get: {
    usageTypes: ccrCalls.usageType,
    list: ccrCalls.list,
    individual: ccrCalls.individual,
    statusList: ccrCalls.statusList,
    area: ccrCalls.areas,
    section: ccrCalls.section,
    headcounts: ccrCalls.headcounts,
  },
  post: {
    new: ccrCalls.new,
    submit: ccrCalls.submit,
  },
  put: {
    update: ccrCalls.update,
  },
};

export const ccr = {
  get: {
    usageTypes: ccrCalls.usageType,
    list: ccrCalls.list,
    individual: ccrCalls.individual,
    allApprovers: ccrCalls.allApprovers,
    statusList: ccrCalls.statusList,
    area: ccrCalls.areas,
    section: ccrCalls.section,
    roles: ccrCalls.roles,
    department: ccrCalls.department,
    matchingPositions: ccrCalls.matchingPositions,
    matchingHeadcounts: ccrCalls.matchingHeadcounts,
    shifts: ccrCalls.shifts,
    headcounts: ccrCalls.headcounts,
    currentAndPrevious: ccrCalls.currentAndPrevious
  },
  post: {
    listFilterByUserType: ccrCalls.listFilterByUserType,
    new: ccrCalls.new,
    submit: ccrCalls.submit,
    impact: ccrCalls.impactHeadcounts,
    revert: ccrCalls.revert,
  },
  put: {
    update: ccrCalls.update,
    reassign: ccrCalls.reassign,
    approve: ccrCalls.approve,
    approveccr: ccrCalls.approveccr,
    declineccr: ccrCalls.declineccr
  },
  delete: {
    ccr: ccrCalls.delete,
  },
};

export const sites = {
  get: {
    list: siteCalls.list,
    individual: siteCalls.individual,
  },
};

export const badge = {
  get: {
    list: badgeCalls.list,
    individual: badgeCalls.individual,
    search: badgeCalls.searchByNameCompany,
  },
  post: {
    badge: badgeCalls.add,
  },
  put: {
    badge: badgeCalls.edit,
  },
};

export const user = {
  get: {
    list: userCalls.list,
    individual: userCalls.byRole,
    byEmail: userCalls.byEmail,
    companies: userCalls.getCompanies,
    activeCompanies: userCalls.getActiveCompanies,
    getCandidateCompanies: userCalls.getCandidateCompanies,
  },
  post: {
    user: userCalls.add,
  },
  put: {
    user: userCalls.edit,
    userFromRole: userCalls.deletefromRole,
  },
};

export const dfr = {
  get: {
    dfr: dfrCalls.export,
    historical: dfrCalls.historical
  },
  post: {
    dfr: dfrCalls.import,
  }
};

export const workcenter = {
  get: {
    export: workcenterCalls.export,
  },
  post: {
    import: workcenterCalls.import,
  },
};

export const unassignedPersonnel = {
  get: {
    export: unassignedPersonnelCalls.export,
  },
};

export const manage = {
  get: {
    assets: manageCalls.assets.list,
    asset: manageCalls.assets.individual,
    organizations: manageCalls.organizations.list,
    organization: manageCalls.organizations.individual,
    'usage-types': manageCalls.usageTypes.list,
    'usage-type': manageCalls.usageTypes.individual,
    roles: manageCalls.roles.list,
    role: manageCalls.roles.individual,
    departments: manageCalls.departments.list,
    department: manageCalls.departments.individual,
    sections: manageCalls.sections.list,
    section: manageCalls.sections.individual,
    areas: manageCalls.areas.list,
    area: manageCalls.areas.individual,
    companies: manageCalls.companies.list,
    company: manageCalls.companies.individual,
  },
  post: {
    asset: manageCalls.assets.create,
    organization: manageCalls.organizations.create,
    'usage-type': manageCalls.usageTypes.create,
    role: manageCalls.roles.create,
    department: manageCalls.departments.create,
    section: manageCalls.sections.create,
    area: manageCalls.areas.create,
    company: manageCalls.companies.create,
  },
  put: {
    asset: manageCalls.assets.update,
    organization: manageCalls.organizations.update,
    'usage-type': manageCalls.usageTypes.update,
    role: manageCalls.roles.update,
    department: manageCalls.departments.update,
    section: manageCalls.sections.update,
    area: manageCalls.areas.update,
    company: manageCalls.companies.update,
    'batch-company': manageCalls.companies.batchUpdate,
  },
  delete: {
    assets: manageCalls.assets.delete,
    organizations: manageCalls.organizations.delete,
    'usage-types': manageCalls.usageTypes.delete,
    roles: manageCalls.roles.delete,
    departments: manageCalls.departments.delete,
    sections: manageCalls.sections.delete,
    areas: manageCalls.areas.delete,
    companies: manageCalls.companies.delete,
  },
};
