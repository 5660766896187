import { chain, type, map, toPairs, fromPairs } from "ramda";

const flattenObj = (obj) => {
  const go = (obj_) =>
    chain(([k, v]) => {
      if (type(v) === "Object" || type(v) === "Array") {
        return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
      }
      return [[k, v]];
    }, toPairs(obj_));

  return fromPairs(go(obj));
};

const obj = {
  flatten: flattenObj,
};

export default obj;
