import React, { Component } from 'react';
import {
  Label,
  Form,
  Col,
  Row
} from 'reactstrap';
import Dropdown from '../../../../../containers/Input/Dropdown';
import Breadcrumb from '../../../../../presentation/Breadcrumb';
import UnityButton from '../../../../../presentation/unity/Button';
import Datepicker from '../../../../../containers/Input/Datepicker/DateRange';

const breadcrumb = [
  { name: 'Home', link: '/' },
  { name: 'Reports', isActive: true },
  { name: 'Historical DFR', isActive: true },
];

export default class HistoricalDFRView extends Component {
  
  render() {
    const {
      label,
      companies,
      companyLink,
      getReport, 
      setSelected, 
      setDateSelected,
      startDate,
      endDate, 
    } = this.props;
    return (
      <div style={{margin: "10px"}}>
        <Breadcrumb items={breadcrumb} />
        <Form>
          <Row style={{marginBottom: "10px"}}>
            <Col sm="1">
              <Label for={label} key="Company">
                Company
              </Label>
            </Col>      
            <Col sm="3">            
              <Dropdown 
                options={companies}
                label="Company Name"
                onSelect={value => setSelected(value)}
                nullable
              />
            </Col>
          </Row>
          <Row style={{marginBottom: "10px"}}>
            <Col sm="1">
              <Label for={label} key="Date">
                Date
              </Label>
            </Col> 
            <Col sm="3" style={{paddingLeft: "30px"}}>
              <Datepicker
                startDate={startDate}
                endDate={endDate}   
                onSet={(data) => setDateSelected(data)}
              />
            </Col>        
          </Row>
          <Row style={{marginBottom: "10px"}}>
            <UnityButton text="Export" onClick={() => getReport()} disabled={companyLink === null} />
          </Row>
        </Form>
      </div>
    );
  }
}
