import React from "react";
import { Button } from "../../../presentation/unity/Button";

export default (props) => {
  const { item, keys, priority, deactivate } = props;
  return (
    <tr>
      <td className="em-c-btn-group">
        <Button
          icon="chevron-up"
          size="small"
          className="priority-button"
          onClick={() => priority.increase(item)}
        />
        <Button
          icon="chevron-down"
          size="small"
          className="priority-button"
          onClick={() => priority.decrease(item)}
        />
      </td>
      {keys.map((key) => (
        <td key={_.get(item, key)}>{_.get(item, key)}</td>
      ))}
      <td>
        <Button
          className="float-right"
          size="small"
          onClick={() => deactivate(item)}
          icon="circle-minus"
          text="Deactivate"
        />
      </td>
    </tr>
  );
};
