import React from 'react';

import { Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';

export default function PriceInput (props) {
    const { label, placeholder, onCheck, 
        isRequired, onChange, value } = props;
    return (<InputGroup>
        <InputGroupAddon addonType="prepend">
            <InputGroupText>
                <Label for={`required-${label}`} className="in-field-label-left">
                Required
                </Label>
                <Input
                    addon
                    type="checkbox"
                    id={`required-${label}`}
                    name={`required-${label}`}
                    onChange={e => onCheck(e.target.checked)}
                    aria-label="Checkbox for following text input"
                    checked={isRequired}
                />
            </InputGroupText>
            <InputGroupText>$</InputGroupText>
        </InputGroupAddon>
        <Input
            placeholder={placeholder}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            disabled={!isRequired}
            id={`required-${label}-input`}
            name={`required-${label}-input`}
            step="1000"
            min="0"
            value={value}
            onChange={ e => onChange(e.target.value)}
        />
        <InputGroupAddon addonType="append"> CAD </InputGroupAddon>
    </InputGroup>);
}