import {SendErrorToApplicationInsights} from '../../components/containers/ErrorPage/ErrorPage';

const initialState = { 
  showError: false,
  errorMessage: "",
  errorObj: null 
};

export const errorState = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ERROR':
      SendErrorToApplicationInsights(action.errorObj ? action.errorObj : action.errorMessage);
      return { showError: true, errorMessage: action.errorMessage };
    case 'HIDE_ERROR':
      return { ...initialState };
    default:
      return state;
  }
};