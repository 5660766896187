import React, { Component } from 'react';
import {
  find,
  propEq,
} from 'ramda';
import AddSectionView from './presentation/AddSection';
import { manage } from '../../../api';

export default class AddSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departments: [],
      selectedDepartment: null,
      section: '',
      validDepartment: true,
      validSection: true,
    };
    this.getDepartment = this.getDepartment.bind(this);
    this.addSection = this.addSection.bind(this);
    this.setDepartment = this.setDepartment.bind(this);
    this.setSection = this.setSection.bind(this);
  }

  componentDidMount() {
    this.getDepartment();
  }

  async getDepartment() {
    const departments = await manage.get.departments();
    if (departments.data !== null) {
      departments.data = departments.data.filter(dep => dep.isDeleted === false);
      // Renaming the departments for clarity
      // eslint-disable-next-line
      departments.data.map(dep => dep.name = `${dep.name} (${dep.organization.name} - ${dep.organization.asset.name})`);
      this.setState({ departments: departments.data });
    }
  }

  setDepartment(dep) {
    const { departments } = this.state;
    const selectedDepartment = find(propEq('name', dep))(departments);
    this.setState({ selectedDepartment });
  }

  setSection(sec) {
    this.setState({ section: sec });
  }

  isValid() {
    const { section, selectedDepartment } = this.state;
    const validSection = section !== '';
    const validDepartment = selectedDepartment !== null;
    this.setState({ validSection, validDepartment });
    return validDepartment && validSection;
  }

  async addSection() {
    const { selectedDepartment, section } = this.state;
    const { hide } = this.props;
    if (this.isValid()) {
      const res = await manage.post.section(
        section,
        selectedDepartment.departmentID,
      ).catch((err) => alert(err.response.data)); // eslint-disable-line
      // eslint-disable-next-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ section: '' });
      }
    }
  }

  render() {
    return (
      <div>
        <AddSectionView
          {...this.props}
          {...this.state}
          set={{
            section: this.setSection,
            department: this.setDepartment,
          }}
          submit={this.addSection}
        />
      </div>
    );
  }
}
