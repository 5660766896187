import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
} from 'reactstrap';
import UnityButton from '../../../presentation/unity/Button';
import Dropdown from '../../Input/Dropdown';

export default class AddOrganizationView extends Component {
  render() {
    const {
      set,
      isOpen,
      hide,
      submit,
      assets,
      validAsset,
      validOrganization,
    } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => hide()} className="modal-xl">
          <ModalHeader toggle={() => hide()}>
            Add Organization
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="OrganizationName" sm={2}>
                  Organization
                </Label>
                <Col sm={10}>
                  <Input
                    name="OrganizationName"
                    id="OrganizationName"
                    onChange={e => set.organization(e.target.value)}
                    invalid={!validOrganization}
                  />
                  <FormFeedback>
                    {'Please enter an organization name.'}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Dropdown
                  options={assets}
                  label="Asset"
                  onSelect={value => set.asset(value)}
                  nullable
                  invalid={!validAsset}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <UnityButton color="primary" onClick={() => submit()} text="OK" />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
