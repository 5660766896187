import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import {
    EDIT_CCR_QUIT, resetEditCcr, setEditCcrloading,
    setEditCcrData, EDIT_CCR_SUBMIT, EDIT_CCR_GET
} from '../actions/editCcrActions';
import { ccr as ccrAPI, sites, user } from '../../api';
import { loadHeadcounts } from '../actions/manageHeadcountActions'
import { loadApprovers } from '../actions/manageApproversActions'
import { showErrorAction } from '../actions/errorMessageActions'

export function* queryCcrAsync(action) {
    yield put(setEditCcrloading(true));
    try {
        const calls = {
            sites: call(sites.get.list),
            usageTypes: call(ccrAPI.get.usageTypes),
            approvers: call(ccrAPI.get.allApprovers),
            statuses: call(ccrAPI.get.statusList),
            companies: call(user.get.companies),
            shifts: call(ccrAPI.get.shifts)
        };

        if (action.payload) {
            calls.ccr = call(ccrAPI.get.currentAndPrevious, action.payload);
        }

        const data = yield all(calls);

        if (data.ccr){
            data.lastApproved = { data: data.ccr.data.previous}
            data.ccr = { data: data.ccr.data.ccr};
        }

        yield put(setEditCcrData(data));
        yield put(loadHeadcounts(data));
        yield put(loadApprovers(data));

    } catch (ex) {
        yield put(resetEditCcr())
        yield put(showErrorAction('Error Occured. Please contact application support', ex))
    } finally {
    }

}

const convertToNumber = (val) => Boolean(val) ? val : 0;

function extractInfo(ccr, headcounts, approvers) {
    ccr.headcountRequests = headcounts.map(x => ({
        ...x,
        ccrID: ccr.ccrID,
        headcountRequestID: x.status === 'New' ? 0 : x.headcountRequestID
    }))
    ccr.ccrApprovers = approvers.map((approver, idx) => ({
        approverID: approver.userID,
        approvalSequence: idx + 1
    }));
    ccr.equipmentVehicleEstimatedCost =  convertToNumber(ccr.equipmentVehicleEstimatedCost)
    ccr.annualEstimatedCost = convertToNumber(ccr.annualEstimatedCost)

    return ccr;
}

export function* submitCcr(action) {
    let ccr = yield select((state) => state.editCCR.manageCCR.ccr);
    let headcounts = yield select((state) => state.editCCR.manageHeadcount.headcounts);
    let approvers = yield select((state) => state.editCCR.manageApprovers.selected_approvers);

    const ccrData = extractInfo(ccr, headcounts, approvers)
    yield put(setEditCcrloading(true))
    try {
        yield ccrAPI.post.submit(ccrData);
        yield put(resetEditCcr())
    } catch (ex) {
        yield put(showErrorAction('Error Occured. Please contact application support', ex))
    } finally {
        const history = action.payload;
        history.push('/')
        yield put(setEditCcrloading(false))
    }
}

export function* quitCcr(action) {
    const response = yield window.confirm(`Are you sure you want to quit this CCR? The CCR will not be saved.`);
    if (response === true) {
        const history = yield action.payload;
        yield history.push('/');
        yield put(resetEditCcr())
    }
}

export function* watchQuitCcr() {
    yield takeEvery(EDIT_CCR_QUIT, quitCcr)
}

export function* watchSetEditingCcr() {
    yield takeEvery(EDIT_CCR_GET, queryCcrAsync)
}

export function* watchSubmitCcr() {
    yield takeEvery(EDIT_CCR_SUBMIT, submitCcr)
}

export default [watchSetEditingCcr, watchSubmitCcr, watchQuitCcr]
