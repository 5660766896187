import React, { useState, useEffect, useMemo } from "react";
import WizardButtonBar from "../../containers/Nav/Wizard/WizardButtonBar";
import * as Actions from "../../../redux/actions/reassignActions";
import ManageApprovers from "../CCREdit/ManageApprovers/ManageApprovers";
import Loading from "../../presentation/Loading";
import UnityButton from "../../presentation/unity/Button";
import moment from "moment";
import BreadcrumbNav from "../../presentation/Breadcrumb";
import { toDisplayDate } from "../../../utils/date/reformat";
import CCRView from "../../presentation/CCR/Review";
import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

export const CCRReasign = (props) => {
  const breadcrumb = [
    { name: "Home", link: "/" },
    { name: "CCR", isActive: "true" },
    { name: "Reassign CCR", isActive: "true" },
  ];

  // Destructuring Props
  const {
    match: {
      params: { ccrID },
    },
  } = props;
  const {
    getReassignDataFromAPI,
    applicableApprovers,
    originalApprovers,
    ccr,
    isLoading,
    approvedApprovers,
  } = props;

  // Hooks
  const history = useHistory();
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const headcounts = useMemo(
    () => getCuratedHeadcounts(ccr.headcountRequests),
    [ccr.headcountRequests]
  );
  const hasChanged = useMemo(
    () => getApproverDiff(originalApprovers, selectedApprovers),
    [originalApprovers, selectedApprovers]
  );

  // Effects
  useEffect(() => {
    getReassignDataFromAPI(ccrID);
  }, [ccrID, getReassignDataFromAPI]);
  useEffect(() => {
    setSelectedApprovers([...originalApprovers]);
  }, [originalApprovers]);

  // Methods
  const resetSelected = () => setSelectedApprovers([...originalApprovers]);

  if (isLoading) return <Loading isShowing={true} text="Loading ...." />;

  return (
    <div>
      <BreadcrumbNav items={breadcrumb} />
      <h1 className="em-u-margin-bottom"> Update Approvers </h1>
      <ManageApprovers
        approvedApprovers={approvedApprovers}
        applicableApprovers={applicableApprovers}
        selectedApprovers={selectedApprovers}
        showDefaultHint={false}
        onChange={setSelectedApprovers}
        {...props}
      />
      <div className="em-u-margin-double" style={{ clear: "both" }}>
        <h5>CCR Details for Reference</h5>
        <CCRView {...ccr} headcounts={headcounts} preTitle="" />
      </div>
      <WizardButtonBar
        isContinueDisabled={
          !(selectedApprovers.length + approvedApprovers.length) || !hasChanged
        }
        continueMethod={() =>
          props.submitReassignRequest(
            ccrID,
            approvedApprovers,
            selectedApprovers,
            history
          )
        }
        disableBackButton
        continueButtonText="Submit"
        quitMethod={() => history.push("/")}
      >
        <UnityButton
          text="Reset Everything"
          color="inverted"
          className="em-u-margin-right"
          onClick={resetSelected}
        />
        {!hasChanged && <Alert color="warning"> No Change Detected! </Alert>}
        {!(selectedApprovers.length + approvedApprovers.length) && (
          <Alert color="danger"> Please select at least 1 approver. </Alert>
        )}
      </WizardButtonBar>
    </div>
  );
};

const getApproverDiff = (original, selected) => {
  if (original.length !== selected.length) return true;
  for (let i = 0; i < original.length; i++) {
    if (original.at(i).userID !== selected.at(i).userID) return true;
  }
  return false;
};

const getCuratedHeadcounts = (headcountRequests) => {
  const mappedHRs = headcountRequests?.map((hc) => ({
    organizationName: hc.organization ? hc.organization.name : null,
    usageTypeName: hc.usageType ? hc.usageType.name : null,
    positionRoleName: hc.positionRole ? hc.positionRole.name : null,
    departmentName: hc.department ? hc.department.name : null,
    sectionName: hc.section ? hc.section.name : null,
    areaName: hc.area ? hc.area.name : null,
    shiftName: hc.shift ? hc.shift.name : null,
    startDate: hc.startDate ? toDisplayDate(hc.startDate) : null,
    endDate: hc.endDate ? toDisplayDate(hc.endDate) : null,
    companyName: hc.company ? hc.company.name : null,
    billable: hc.billable === true ? "Yes" : "No",
    totalHeadcount: hc.quantity,
    schedulable: hc.schedulable === true ? "Yes" : "No",
    removalImpactedCcrID: hc.removalImpactedCcrID,
  }));
  const filteredHRs = mappedHRs?.filter(
    (hc) => !hc.endDate || moment(hc.endDate).diff(moment(), "months") > -2
  );

  return filteredHRs;
};

const mapStateToProps = (state) => ({
  isLoading: state.reassignCCR.loading,
  ccr: state.reassignCCR.ccr,
  approvedApprovers: state.reassignCCR.approved_approvers,
  originalApprovers: state.reassignCCR.original_approvers,
  applicableApprovers: state.reassignCCR.applicable_approvers,
});

const mapDispatchToProps = {
  getReassignDataFromAPI: Actions.loadReassignData,
  submitReassignRequest: Actions.submitReassignCCR,
};

export default connect(mapStateToProps, mapDispatchToProps)(CCRReasign);
