import React, { Component } from 'react';
import {
  find,
  propEq,
} from 'ramda';
import AddAreaView from './presentation/AddArea';
import { manage } from '../../../api';

export default class AddArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      selectedSection: null,
      area: '',
      validArea: true,
      validSection: true,
    };
    this.getSection = this.getSection.bind(this);
    this.addArea = this.addArea.bind(this);
    this.setSection = this.setSection.bind(this);
    this.setArea = this.setArea.bind(this);
  }

  componentDidMount() {
    this.getSection();
  }

  async getSection() {
    const sections = await manage.get.sections();
    if (sections.data !== null) {
      sections.data = sections.data.filter(section => section.isDeleted === false);
      // Renaming the sections for clarity
      // eslint-disable-next-line
      sections.data.map(sec => sec.name = `${sec.name} (${sec.department.name} - ${sec.department.organization.name} - ${sec.department.organization.asset.name})`);
      this.setState({ sections: sections.data });
    }
  }

  setSection(sec) {
    const { sections } = this.state;
    const selectedSection = find(propEq('name', sec))(sections);
    this.setState({ selectedSection });
  }

  setArea(area) {
    this.setState({ area });
  }

  isValid() {
    const { area, selectedSection } = this.state;
    const validArea = area !== '';
    const validSection = selectedSection !== null;
    this.setState({ validArea, validSection });
    return validArea && validSection;
  }

  async addArea() {
    const { selectedSection, area } = this.state;
    const { hide } = this.props;
    if (this.isValid()) {
      const res = await manage.post.area(
        area,
        selectedSection.sectionID,
      ).catch((err) => alert(err.response.data)); // eslint-disable-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ area: '', selectedSection: null });
      }
    }
  }

  render() {
    return (
      <div>
        <AddAreaView
          {...this.props}
          {...this.state}
          set={{
            area: this.setArea,
            section: this.setSection,
          }}
          submit={this.addArea}
        />
      </div>
    );
  }
}
