import { toDisplayDate } from '../../utils/date/reformat';

const allowedEdit = ['Approved', 'Denied'];

const emptyValue = {value: 0, label: ''}
const emptySection = {
    ...emptyValue,
    areas: [emptyValue]
}
const emptyDepartment = {...emptyValue, sections : [
    emptySection
]}


export function getReadonlytHeadcount(hc) {
    return {
        headcountRequestID: hc.headcountRequestID,
        organizationName: hc.organization ? hc.organization.name: null,
        usageTypeName: hc.usageType ? hc.usageType.name: null,
        positionRoleName: hc.positionRole ? hc.positionRole.name : null,
        departmentName: hc.department ? hc.department.name : null,
        sectionName: hc.section ? hc.section.name : null,
        areaName: hc.area ? hc.area.name : null,
        shiftName: hc.shift ? hc.shift.name : null,
        startDate: hc.startDate ? toDisplayDate(hc.startDate) : null,
        endDate: hc.endDate ? toDisplayDate(hc.endDate) : null,
        companyName: hc.company ? hc.company.name : null,
        billable: hc.billable === true ? 'Yes' : 'No',
        totalHeadcount: hc.quantity,
        schedulable: hc.schedulable === true ? 'Yes' : 'No',
        removalImpactedCcrID: hc.removalImpactedCcrID,
        prevID : hc.prevID
    }
}

export function getReadonlyCcr(inputCCR, previousCCR) {
    let ccr;
    if (inputCCR) {
        ccr = {...inputCCR, organization: inputCCR.headcountRequests && inputCCR.headcountRequests.length > 0 ? inputCCR.headcountRequests[0].organization : {asset: {}} }
        ccr.headcountRequests = inputCCR.headcountRequests.map(getReadonlytHeadcount)
        ccr.isEditable = allowedEdit.includes(inputCCR.ccrStatus.name) 
        ccr.isEditedDenied = inputCCR.ccrStatus.name === 'Denied' && !!previousCCR
    } else {
        ccr = { headcountRequests : []}
    }
    return ccr;
}

export function siteDateRestructure (siteData) {
    var sites = siteData.organizations.map(org => (
        {   
            value: org.organizationID , label: org.name,
            departments: org.departments.length ?
                [emptyDepartment].concat(org.departments.map(dep => ({
                    value: dep.departmentID, label: dep.name,
                    sections: [emptySection].concat(dep.sections.map(sec => ({
                        value: sec.sectionID, label: sec.name,
                        areas: [emptyValue].concat(sec.areas.map(ar => ({value: ar.areaID, label: ar.name}) ))
                    }) ))
                }))) : [emptyDepartment],
            roles: org.organizationPositionRoles.length ?
                [emptyValue].concat(org.organizationPositionRoles.map(rl => ({value: rl.positionRoleID, label: rl.positionRole.name}))) : [emptyValue]
                
        }
    ))
    return sites;
}