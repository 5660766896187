import React from 'react';
import { Link } from 'react-router-dom';
import UnityButton from '../../../presentation/unity/Button';

export default (props) => {
  const {
    show,
    canClick,
    path,
    item,
    text,
  } = props;
  if (show) {
    if (canClick) {
      return (
        <Link
          to={{
            pathname: path,
            state: item,
          }}
        >
          <UnityButton size="small" text={text} />
        </Link>
      );
    }
  }
  return null;
};
