import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Col,
  Input,
  Label,
} from 'reactstrap';
import UnityButton from '../../../../presentation/unity/Button';
import BreadcrumbNav from '../../../../presentation/Breadcrumb';
import handleKeyPress from '../../../../../utils/number/handleKeyPress';

const breadcrumb = [
  { name: 'Home', link: '/' },
  { name: 'Badge Data Entry', isActive: true },
];

export default class HeadcountIDEntryView extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { searchBadgeID, handleUserInput } = this.props;
    return (
      <div className="homeContainer">
        <BreadcrumbNav items={breadcrumb} />
        <div className="ccrContainer">
          <Form id="headcount-search" onSubmit={(e) => { e.preventDefault(); searchBadgeID(); }}>
            <FormGroup row>
              <Label for="HeadcountID" sm={2}>
                Headcount ID
              </Label>
              <Col sm={10}>
                <Input
                  type="number"
                  name="headcountID"
                  id="headcountID"
                  min="0"
                  onKeyDown={e => handleKeyPress(e)}
                  onKeyUp={e => handleUserInput(e)}
                  disabled={false}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col>
                <UnityButton text="Search" icon="search" className="float-right" />
              </Col>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}
