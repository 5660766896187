import React, { PureComponent } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';


const BreadcrumbLink = ({ name, link }) => (
  <BreadcrumbItem>
    <a href={link} to={link}>
      {name}
    </a>
  </BreadcrumbItem>
);

const BreadcrumbActive = ({ name }) => (
  <BreadcrumbItem active>
    {name}
  </BreadcrumbItem>
);

export default class BreadcrumbNav extends PureComponent {
  render() {
    const { items } = this.props;
    return (
      <Breadcrumb>
        {
          items.map((item) => {
            if (item.isActive) {
              return (<BreadcrumbActive {...item} key={item.name} />);
            }
            return (<BreadcrumbLink {...item} key={item.name} />);
          })
        }
      </Breadcrumb>
    );
  }
}
