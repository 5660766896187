import React, { Component } from 'react';
import Select from 'react-select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Form,
  FormText,
  FormGroup,
} from 'reactstrap';
import UnityButton from '../../../presentation/unity/Button';

class UserModalView extends Component {
  save() {
    const {
      addUser,
      editUser,
      addOrEdit,
    } = this.props;
    if (addOrEdit) {
      addUser();
    } else {
      editUser();
    }
  }

  render() {
    const {
      close,
      tabName,
      className,
      companies,
      showContract,
      addOrEdit,
      isOpen,
      selected,
      setEmail,
      setFirstName,
      setLastName,
      setCompanies,
      emailValid,
      firstNameValid,
      lastNameValid
    } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={close} className={className}>
          <ModalHeader toggle={close}>
            {` ${addOrEdit ? 'Add' : 'Edit'} ${tabName}`}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.save(); }}>
              <FormGroup>
              <Label for="firstName">
                  Firstname
                </Label>
                <Input
                  name="firstname"
                  id="firstname"
                  placeholder="Firstname"
                  invalid={!firstNameValid}
                  onChange={e => setFirstName(e.target.value)}
                  defaultValue={selected ? selected.firstName : null}
                />
                <Label for="lastName">
                  Lastname
                </Label>
                <Input
                  name="lastname"
                  id="lastname"
                  placeholder="Lastname"
                  invalid={!lastNameValid}
                  onChange={e => setLastName(e.target.value)}
                  defaultValue={selected ? selected.lastName : null}
                />
                <Label for="exampleEmail">
                  User E-mail
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email Address"
                  invalid={!emailValid}
                  onChange={e => setEmail(e.target.value)}
                  defaultValue={selected ? selected.email : null}
                />
                <FormText color="muted">
                  {'Email Addresses must end with "@esso.ca" or "@exxonmobil.com"'}
                </FormText>
              </FormGroup>
            </Form>
            {
              showContract ? [
                <p className="section-heading" key="label">
                  Assign Vendor Owner
                </p>,
                <Select
                  isMulti
                  placeholder="Companies"
                  options={companies}
                  onChange={setCompanies}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.companyID}
                  defaultValue={selected ? selected.userCompanies : null}
                  className="over-button"
                />,
              ] : null
            }
          </ModalBody>
          <ModalFooter className="em-c-btn-group">
            <UnityButton color="primary" onClick={() => this.save()} text="Save" disabled={!emailValid} />
            <UnityButton color="secondary" onClick={close} text="Cancel" />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UserModalView;
