import React from 'react'

const IconOnlyButton = props => {

    const { icon, btnClass, onClick, iconColor, title, disabled } = props;
    
    return (
        <button className={`em-c-btn ${btnClass} ${disabled && 'em-c-btn--disabled'}`} onClick={()=> !disabled && onClick()} title={title}>
            <div className="em-c-btn__inner">
                <svg className="em-c-icon em-c-icon--small em-c-btn__icon kmod-disable-pointer-events" style={{color: iconColor}}>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`/images/em-icons.svg#${icon}`}></use>
                </svg>
            </div>
        </button>
    )
}

IconOnlyButton.defaultProps = {
    icon: "",
    btnClass: "",
    onClick: () => console.log("Not Implemented"),
    title: "",
    disabled: false

}

export default IconOnlyButton
