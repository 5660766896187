import React from 'react';
import { isUser } from '../../../../js/msal/msalHelper';

const Admin = () => {
  if (isUser.admin()) {
    return (
      <li className="em-c-tree__item">
        <span className="em-c-tree__link em-c-tree__item--has--children" role="tab">
          <span className="em-c-tree__text em-c-tree__link--has--children">
          Admin
          </span>
        </span>
        <ul className="em-c-tree__list em-js-tree-dropdown">
          <li className="em-c-tree__item">
            <span className="em-c-tree__link em-c-tree__item--has--chidldren" role="tab">
              <span className="em-c-tree__text em-c-tree__link--has--chidldren em-u-font-style-semibold">
                Manage
              </span>
            </span>
            <ul className="em-c-tree__list em-js-tree-dropdown">
              <li className="em-c-tree__item">
                <a
                  href="/manage/users"
                  className="em-c-tree__link"
                >
                  Users
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/manage/companies"
                  className="em-c-tree__link"
                >
                  Companies
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/manage/usage-types"
                  className="em-c-tree__link"
                >
                  Usage Types
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/manage/assets"
                  className="em-c-tree__link"
                >
                  Assets
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/manage/organizations"
                  className="em-c-tree__link"
                >
                  Organizations
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/manage/departments"
                  className="em-c-tree__link"
                >
                  Departments
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/manage/sections"
                  className="em-c-tree__link"
                >
                  Sections
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/manage/areas"
                  className="em-c-tree__link"
                >
                  Areas
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/manage/roles"
                  className="em-c-tree__link"
                >
                  Roles
                </a>
              </li>
            </ul>
          </li>
          <li className="em-c-tree__item">
            <span className="em-c-tree__link em-c-tree__item--has--childdren" role="tab">
              <span className="em-c-tree__text em-c-tree__link--has--chidldren em-u-font-style-semibold">
                Reports
              </span>
            </span>
            <ul className="em-c-tree__list em-js-tree-dropdown">
              <li className="em-c-tree__item">
                <a
                  href="/reports/workcenter"
                  className="em-c-tree__link"
                >
                  Work Center
                </a>
              </li>
              <li className="em-c-tree__item">
                <a
                  href="/reports/unassigned-personnel"
                  className="em-c-tree__link"
                >
                  Unassigned Personnel
                </a>
              </li>              
              <li className="em-c-tree__item">
                <a
                  href="/reports/historicalDFR"
                  className="em-c-tree__link"
                >
                  Historical DFR
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    );
  }
  return null;
};

export default Admin;
