import React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Config from "../../../config";
import { getUser } from "../../../js/msal/msalHelper";
import { connect } from "react-redux";
import styles from "./ErrorPage.module.css";

const getInstrumentationKey = () => Config.app_insights_instrumentation_key;

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }

  // Catch JS script errors
  componentDidMount() {
    window.onerror = (msg, file, line, column, errorObj) => {
      if (!this.state.hasError) {
        this.setState({
          hasError: true,
          errorMessage: msg,
        });
        LogErrorApplicationInsights(
          errorObj,
          getInstrumentationKey(),
          this.props.userProfile
            ? this.props.userProfile
            : getUserProfileFromAuth()
        );
      }
    };
  }

  // Error Boundary for catching all React errors
  componentDidCatch(error) {
    if (this.state.hasError) return;
    if (error.name) {
      this.setState({
        hasError: true,
        errorMessage: `${error.name}: ${error.message}`,
      });
    } else {
      this.setState({ hasError: true, errorMessage: error });
    }
    LogErrorApplicationInsights(
      error,
      getInstrumentationKey(),
      this.props.userProfile ? this.props.userProfile : getUserProfileFromAuth()
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.kmod_error_container}>
          <h1 className="em-u-text-align-center">Oops!</h1>
          <h2 className="em-u-text-align-center">Something went Wrong!</h2>
          <h4 className="em-u-margin-top-double">
            Please try logging in again, or contact support if the issue
            persists.
          </h4>
          <h6 className={styles.kmod_error_details}>
            {this.state.errorMessage}
          </h6>
        </div>
      );
    }
    return this.props.children;
  }
}

const LogErrorApplicationInsights = (
  error,
  instrumentationKey,
  userProfile
) => {
  const appInsights = new ApplicationInsights({
    config: { instrumentationKey: instrumentationKey },
  });
  appInsights.loadAppInsights();

  let properties = {
    AppName: "KMOD.UI",
    Browser: window.navigator.userAgent,
    ...userProfile,
  };

  appInsights.trackException({
    exception: error.name ? error : new Error(error),
    properties: properties,
  });

  appInsights.flush();
};

const getUserProfileFromUser = (user) => {
  if (!user) return null;
  return {
    userId: user.userID,
    userName: `${user.firstName} ${user.lastName}`,
    userEmail: user.email,
    userRoles: user.roleNames,
  };
};

const getUserProfileFromAuth = () => {
  const userData = getUser();
  return {
    userId: "-",
    userName: userData.name,
    userEmail: userData.idTokenClaims.preferred_username,
    userRoles: "NA",
  };
};

export const SendErrorToApplicationInsights = (err) =>
  LogErrorApplicationInsights(
    err,
    getInstrumentationKey(),
    getUserProfileFromAuth()
  );

const mapStateToProps = (state) => {
  return {
    userProfile: getUserProfileFromUser(state.user),
  };
};

export default connect(mapStateToProps)(ErrorPage);
