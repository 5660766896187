import React from 'react';

import {
    Col,
    FormGroup,
    Label
  } from 'reactstrap';

export default function FormField(props) {
    let { isValid } = props;
    if (isValid === null || isValid === undefined) {
        isValid = true
    }
    return (
        <FormGroup row>
            <Label for={props.id} sm={2}>
                {props.label}
            </Label>
            <Col sm={10}>
                {props.children}
                <div className={!isValid ? 'invalid-feedback show' : 'invalid-feedback'}>
                    {props.validationMessage}
                </div>
            </Col>
      </FormGroup>
    )
}