

export const GET_CCR = 'GET_CCR';
export const GET_EDITING_CCR = 'GET_EDITING_CCR';
export const SET_SELECTED_CCR = 'SET_SELECTED_CCR';
export const SET_SELECTED_CCR_LOADING = 'SET_SELECTED_CCR_LOADING';
export const RESET_SELECTED_CCR = 'RESET_SELECTED_CCR';

export const UPDATE_CCR_LIST = 'UPDATE_CCR_LIST';

export const setSelectedCCRAction = ccr => ({
    type: SET_SELECTED_CCR,
    payload: ccr,
  });

export const getCcrAction = id => ({
  type: GET_CCR,
  payload: id
})

export const setSelectedCCRLoadingAction = loading => ({
  type: SET_SELECTED_CCR_LOADING,
  payload: loading
});

export const resetSelectedCCRAction = () => ({
    type: RESET_SELECTED_CCR
});


export const updateCCRList = (list, pageInfo) => ({
  type: UPDATE_CCR_LIST,
  payload: {
    list,
    pageInfo
  }
});


