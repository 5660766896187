import React, { Component } from 'react';
import AddUsageView from './presentation/AddUsage';
import { manage } from '../../../api';

export default class AddUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usage: '',
      validUsage: true,
    };
    this.addUsage = this.addUsage.bind(this);
    this.setUsage = this.setUsage.bind(this);
  }

  setUsage(usageName) {
    this.setState({ usage: usageName });
  }

  isValid() {
    const { usage } = this.state;
    const validUsage = usage !== '';
    this.setState({ validUsage });
    return validUsage;
  }

  async addUsage() {
    const { usage } = this.state;
    const { hide } = this.props;
    if (this.isValid()) {
      const res = await manage.post['usage-type'](
        usage,
      ).catch((err) => alert(err.response.data)); // eslint-disable-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ usage: '' });
      }
    }
  }

  render() {
    return (
      <div>
        <AddUsageView
          {...this.props}
          {...this.state}
          set={{
            usage: this.setUsage,
          }}
          submit={this.addUsage}
        />
      </div>
    );
  }
}
