import React, { useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import {
  PublicClientApplication, 
  EventType,
} from '@azure/msal-browser';
import Loading from '../../components/presentation/Loading';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const { payload } = event;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

function AuthProvider({ children, onLoggedIn }) {
  const [user, setUser] = useState();
  const [dbUser, setDbUser] = useState();

  const login = async () => {
    await msalInstance.handleRedirectPromise();

    const account = msalInstance.getActiveAccount();
    if (!account) {
      msalInstance.loginRedirect();
    } else {
      setUser(account);
    }
  };

  useEffect(() => {
    if(!user) {
      login();
    }

    if(user) { 
      onLoggedIn((dbUser) => 
      { 
        setDbUser(dbUser)
      }); 
    }
  }, [user]);

  if (user && dbUser) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  }

  return (
    <div>
      <Loading isShowing={true} text='Logging in ...' />
    </div>
  );
}

export default AuthProvider;
