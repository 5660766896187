import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
} from 'reactstrap';
import UnityButton from '../../../presentation/unity/Button';
import Dropdown from '../../Input/Dropdown';

export default class AddAreaView extends Component {
  render() {
    const {
      set,
      isOpen,
      submit,
      hide,
      sections,
      validSection,
      validArea,
    } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => hide()} className="modal-xl">
          <ModalHeader toggle={() => hide()}>
            Add Area
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="AreaName" sm={2}>
                  Area Name
                </Label>
                <Col sm={10}>
                  <Input
                    name="AreaName"
                    id="AreaName"
                    onChange={e => set.area(e.target.value)}
                    invalid={!validArea}
                  />
                  <FormFeedback>
                    {'Please enter an area name.'}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Dropdown
                  options={sections}
                  label="Section"
                  onSelect={value => set.section(value)}
                  nullable
                  invalid={!validSection}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <UnityButton color="primary" onClick={() => submit()} text="OK" />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
