import React from 'react'
import PropTypes from 'prop-types'

/**
 * A unity themed toggle button, can take any number of options.
 * @param {string}          name        - Name of the button, used for tracking react keys
 * @param {arrayOf strings} options     - List of options to be displayed
 * @param {string}          selected    - the select value
 * @param {bool}            disabled    - bool to render component unclickable
 * @param {func}            onChange    - callback method when a user input is detected
 */

const ToggleButton = props => {

    const {name, options, selected, disabled, onChange } = props;

    const handleClick = value => !disabled && onChange(value);

    return (
        <div className={`em-c-toggle ${disabled && 'kmod-disabled'}`}>
            {options.map((option) =>
                <React.Fragment key={option}> 
                    <input
                        className="em-c-toggle__input em-u-is-vishidden"
                        type="radio"
                        id={name + option}
                        value={option} 
                        checked={selected === option}
                        onChange={e => handleClick(e.target.value)}
                    />
                    <label className="em-c-toggle__label " htmlFor={name + option} style={{padding: '0.3rem 1.1rem', marginBottom: 0}}>
                        {option}
                    </label>
                </React.Fragment>
            )}
        </div>
    )
}

ToggleButton.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    selected: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}

ToggleButton.defaultProps = {
    disabled: false
}

export default ToggleButton