import isIE from '../../../../utils/isIE';

export const xlsx = {
  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const download = (data, headers) => {
  const contentDisposition = headers['content-disposition'];
  let fileName = contentDisposition;
  if (contentDisposition !== undefined) {
    const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    if (fileNameMatch.length >= 2) {
      // eslint-disable-next-line
      fileName = fileNameMatch[1];
    }
  }

  const blob = new Blob([data], { encoding: 'UTF-8', type: xlsx.type });
  if (isIE) {
    window.navigator.msSaveBlob(blob, fileName);
    return true;
  }
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
  return true;
};

export default download;
