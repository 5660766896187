import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as Actions from '../actions/reassignActions';
import { ccr as ccrAPI } from '../../api';
import { showErrorAction } from '../actions/errorMessageActions';

const APPROVED_STATUS_ID = 4;
const FOR_REVIEW_STATUS_ID = 3;

export function* loadReassignData(action) {
    try {
        const calls = {
            approvers: call(ccrAPI.get.allApprovers)
        };
        if (action.payload) calls.ccr = call(ccrAPI.get.individual, action.payload);
        const data = yield all(calls);

        yield put(Actions.loadReassignDataSuccess(data));

    } catch (ex) {
        yield put(Actions.loadReassignDataError(ex.message))
        yield put(showErrorAction('Error Occured. Please contact application support', ex));
    } 
}

export function* submitReassignCCR(action){
    const { ccrID, approvedApprovers, selectedApprovers, history} = action.payload;
    try {
        let putBody = {
            ccrID: parseInt(ccrID),
            ccrApprovers: []
        };

        [...approvedApprovers, ...selectedApprovers].forEach((approver, idx) => {
            putBody.ccrApprovers.push({
                approverID: approver.userID,
                approvalSequence: idx + 1,
                statusID: approver.hasApproved ? APPROVED_STATUS_ID : FOR_REVIEW_STATUS_ID
            });
        })

        yield call(ccrAPI.put.reassign, putBody);

    } catch (error) {
        yield put(showErrorAction('Error Occured. Please contact application support', error));
    } finally {
        yield put(Actions.clearReassignData());
        history.push('/');
    }
}

export function* watchReassignCCR() {
    yield takeLatest(Actions.LOAD_REASSIGN_CCR_DATA, loadReassignData)
}
export function* watchSubmitReassignCCR() {
    yield takeLatest(Actions.SUBMIT_REASSIGN_CCR, submitReassignCCR)
}

export default [watchReassignCCR, watchSubmitReassignCCR]
