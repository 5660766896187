import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import AuthProvider from '../src/utils/auth/authProvider';
import 'airbnb-browser-shims';
import 'babel-polyfill';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-quill/dist/quill.snow.css';
import Loading from './components/presentation/Loading';
import ErrorPage from './components/containers/ErrorPage/ErrorPage';
import App from './App';
import store, { persistor } from './redux/store';
import { runWithMsal } from './js/msal/msalHelper';
import './index.scss';

ReactDOM.render(
  <AuthProvider onLoggedIn = {
    (setDbUser) => { 
      runWithMsal(() => {
      }, setDbUser); 
    }
  }>
  <Provider store={store}>
    <BrowserRouter>
      <ErrorPage>
        <PersistGate loading={<Loading isShowing />} persistor={persistor}>
          <App />
        </PersistGate>
      </ErrorPage>
    </BrowserRouter>
  </Provider>
</AuthProvider>
  ,document.getElementById('root')
);