import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Col,
  FormFeedback,
} from 'reactstrap';


export default class Dropdown extends Component {
  onSelect(e) {
    const { onSelect } = this.props;
    onSelect(e.target.value);
  }

  render() {
    const {
      label,
      options,
      disabled,
      nullable,
      invalid,
      selected,
    } = this.props;
    return [
      <Col key="input">
        <Input
          type="select"
          name={label}
          id={label}
          disabled={disabled}
          onChange={e => this.onSelect(e)}
          invalid={invalid}          
          style={{width:"233px"}}
        >
          { nullable ? (
            <option defaultValue={selected === null || selected === undefined} value />
          ) : (null) }
          {options.map((opt => (
            <option
              value={opt.name}
              key={JSON.stringify(opt)}
              defaultValue={selected ? opt.name === selected.name : false}
            >
              {opt.name}
            </option>
          )))}
        </Input>
        <FormFeedback>
          {`Please select a ${label}.`}
        </FormFeedback>
      </Col>,
    ];
  }
}

Dropdown.defaultProps = {
  disabled: false,
  nullable: false,
  invalid: false,
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  nullable: PropTypes.bool,
  invalid: PropTypes.bool,
};
