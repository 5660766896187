import React, { Component } from 'react';
import ActivateCompanyView from './presentation/ActivateCompanyView';
import { manage } from '../../../api';

export default class ActivateCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: '',
      validCompany: false,
    };
    this.activateCompany = this.activateCompany.bind(this);
    this.setCompany = this.setCompany.bind(this);
  }

  setCompany(comp) {
    this.setState({ company: comp, validCompany: comp !== null });
  }

  async activateCompany() {
    const { company, validCompany } = this.state;
    const { hide, max } = this.props;
    if (validCompany) {
      const res = await manage.put.company(
        company.companyID,
        company.name,
        max + 1,
        true,
      ).catch((err) => alert(err.response.data)); // eslint-disable-line
      if (res !== null && res !== undefined) {
        hide(true);
        this.setState({ company: '', validCompany: false });
      }
    }
  }

  render() {
    return (
      <div>
        <ActivateCompanyView
          {...this.props}
          {...this.state}
          set={{ company: this.setCompany }}
          submit={this.activateCompany}
        />
      </div>
    );
  }
}
