import React, { PureComponent } from 'react';
import {
    Col,
    TabContent,
    TabPane,
    Row,
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
import classnames from 'classnames';

export default class Tabs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { activeTab : 0 };
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        this.setState({ activeTab : tab })
    }

    render() {
        const { headers, components } = this.props;
        return (
            <React.Fragment>
                <Nav tabs>
                    {
                        headers.map((item, ind) => {
                            return (
                            <NavItem key={'tab' + ind}>
                            <NavLink key={'tab' + ind}
                            className={`${classnames({ active: this.state.activeTab === ind })} tabs`}
                            onClick={() => { this.toggle(ind); }}
                            > {item} </NavLink></NavItem>);
                        })
                    }
                </Nav>
                <br />
                <TabContent activeTab={this.state.activeTab}>
                    {
                        components.map((item, ind) => {
                            return (<TabPane tabId={ind} key={'tabpane' + ind}>
                                <Row>
                                    <Col sm="12">
                                        {item}
                                    </Col>
                                </Row>
                            </TabPane>);
                        })
                    }
                </TabContent>
            </React.Fragment>
        );
    }
}
