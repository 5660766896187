import config from '../../config';
import resolver from './root';

const url = `${config.kmod_api_moc_base_url}/api`;

export const badgeCalls = {
  list: () => resolver(url, 'get', '/badges/'),
  individual: headcountID => resolver(url, 'get', `/headcounts/${headcountID}`),
  add: (headcountID, badgeID) => resolver(url, 'put', `/headcounts/amendbadge/${headcountID}`, { headcountID, badgeID }),
  searchByNameCompany: (lastName, companyID) => resolver(url, 'get', `/headcounts/amendbadgesearch/${lastName}/${companyID}`),
  edit: (headcountID, badgeID) => resolver(url, 'put', `/headcounts/amendbadge/${headcountID}`, { headcountID, badgeID }),
};

export const ccrCalls = {
  listFilterByUserType: (data) => resolver(url, 'post', '/ccrs', data),
  individual: ccrID => resolver(url, 'get', `/ccrs/${ccrID}`),
  currentAndPrevious: ccrID => resolver(url, 'get', `/ccrs/GetCcrAndPrevious/${ccrID}`),
  usageType: () => resolver(url, 'get', '/UsageTypes/'),
  new: ({ requestorID }) => resolver(url, 'post', '/ccrs', { requestorID }),
  delete: ccrID => resolver(url, 'delete', `/ccrs/${ccrID}`),
  update: (ccrID, data) => resolver(url, 'put', `/ccrs/${ccrID}`, data),
  reassign: (data) => resolver(url, 'put', `/ccrs/reassignccr`, data),
  submit: (data) => resolver(url, 'post', `/ccrs/SubmitCcr`, data),
  shifts: () => resolver(url, 'get', '/shifts'),
  statusList: () => resolver(url, 'get', '/ccrstatus'),
  allApprovers: () => resolver(url, 'get', `/users/GetApprovers/`),
  areas: () => resolver(url, 'get', '/positionareas'),
  section: () => resolver(url, 'get', '/PositionSections'),
  roles: () => resolver(url, 'get', '/PositionRoles'),
  department: () => resolver(url, 'get', '/PositionDepartments'),
  endorse: (ccr, isApproved) => resolver(url, 'put', `Ccrs/EndorseCcr/${ccr}/${isApproved}`),
  approveccr: (data) => resolver(url, 'put', `Ccrs/ApproveCcr`, data),
  declineccr: (data) => resolver(url, 'put', `Ccrs/DeclineCcr`, data),
  revert: (ccr) => resolver(url, 'post', `/ccrs/revertCcr/${ccr}`),
  matchingPositions: (
    /* int */ roleID,
    /* int */ areaID,
    /* int */ sectionID,
    /* int */ departmentID,
    /* int */ shiftID,
    /* int */ companyID,
    /* bool */ billable,
    /* bool */ scheduable,
  ) => resolver(url, 'get', `/Positions/GetPositionByDetails/${roleID}/${areaID}/${sectionID}/${departmentID}/${shiftID}/${companyID}/${billable}/${scheduable}`),
  matchingHeadcounts: (
    /* int */ roleID,
    /* int */ shiftID,
    /* int */ companyID,
    /* bool */ billable,
    /* bool */ scheduable,
    /* date (ISO) */ effectiveDate,
    /* int */ departmentID,
    /* int */ usageTypeID,
    /* int? */ sectionID,
    /* int? */ areaID,
  ) => {
    if (sectionID !== null) {
      if (areaID !== null) {
        return resolver(url, 'get', `/Headcounts/GetHeadcountsByDetails/${roleID}/${shiftID}/${companyID}/${billable}/${scheduable}/${effectiveDate}/${departmentID}/${usageTypeID}/${sectionID}/${areaID}`);
      }
      return resolver(url, 'get', `/Headcounts/GetHeadcountsByDetails/${roleID}/${shiftID}/${companyID}/${billable}/${scheduable}/${effectiveDate}/${departmentID}/${usageTypeID}/${sectionID}`);
    }
    return resolver(url, 'get', `/Headcounts/GetHeadcountsByDetails/${roleID}/${shiftID}/${companyID}/${billable}/${scheduable}/${effectiveDate}/${departmentID}/${usageTypeID}`);
  },
  impactHeadcounts: data => resolver(url, 'post', '/ImpactHeadcounts', data),
  headcounts: ccrID => resolver(url, 'get', `/Headcounts/ByCcrID/${ccrID}`),
};

export const dfrCalls = {
  export: companyID => resolver(url, 'get', `/headcounts/dfrExport/${companyID}`, null, true),
  import: data => resolver(url, 'post', '/headcounts/dfrImport', data, true),  
  historical: (companyID, startDate, endDate) => resolver(url, 'get', `/headcounts/dfrHistorical/${companyID}/${startDate}/${endDate}`, null, true)
};

export const workcenterCalls = {
  export: () => resolver(url, 'get', '/headcounts/WorkCenterExport', null, true),
  import: data => resolver(url, 'post', '/headcounts/WorkCenterImport', data, true),
};

export const unassignedPersonnelCalls = {
  export: companyID => resolver(url, 'get', `/Personnels/UnassignedExport/${companyID}`, null, true),
};

export const siteCalls = {
  list: () => resolver(url, 'get', '/assets/'),
  individual: siteID => resolver(url, 'get', `/assets/${siteID}`),
};

export default {
  badgeCalls,
  ccrCalls,
  dfrCalls,
  siteCalls,
  unassignedPersonnelCalls,
  workcenterCalls,
};
