import {
  SET_SELECTED_CCR,
  RESET_SELECTED_CCR,
  SET_SELECTED_CCR_LOADING,
} from "../actions/ccrActions";
import { convertHTML } from "../../utils/string/htmlParse";

const initialState = { loading: false };
export const selectedCCR = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CCR_LOADING:
      return { ...state, loading: action.payload };
    case SET_SELECTED_CCR:
      return { ...curateAPIData(action.payload), loading: false };
    case RESET_SELECTED_CCR:
      return initialState;
    default:
      return state;
  }
};

const curateAPIData = (data) => {
  const richTextFields = ["title", "justification", "equipmentDetails"];

  richTextFields.forEach((field) => {
    if (_.get(data.ccr, field))
      _.assign(data.ccr, { [field]: convertHTML(_.get(data.ccr, field)) });
    if (data.previous && _.get(data.previous, field))
      _.assign(data.previous, {
        [field]: convertHTML(_.get(data.previous, field)),
      });
  });
  return data;
};
