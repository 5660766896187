export const convertLookup = payload => { 
        const {
                sites: { data: sites },
                shifts: { data: shifts },
                companies: { data: companies },
                usageTypes: { data: usageTypes },
                statuses: { data: statuses }
        } = payload;
        const lookup = {
                siteStructure: sites,
                sites: sites.map(x => ({ value: x.assetID, label: x.name })),
                usageTypes: usageTypes.map(x => ({ label: x.name, value: x.usageTypeID })),
                companies: companies.map(x => ({ label: x.name, value: x.companyID, priority: x.priority })),
                shifts: shifts.map(x => ({ label: x.name, value: x.shiftID })),
                statuses: statuses.map(x => ({ label: x.name, value: x.ccrStatusID }))
        }
        return lookup;
}