const loading = {
  isLoading: true,
  isError: false,
};

const error = {
  isLoading: false,
  isError: true,
};

const success = {
  isLoading: false,
  isError: false,
};

const dropdown = {
  list: [],
  selected: {},
};

const dropdownValidation = {
  list: [],
  selected: {},
  isValid: true,
};

const states = {
  isLoading: loading,
  isError: error,
  isSuccess: success,
  noValidation: {
    dropdown,
  },
  validation: {
    dropdown: dropdownValidation,
  },
};

export default states;
