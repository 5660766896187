import * as Actions from '../actions/reassignActions';
import * as R from 'ramda';

const APPROVED_STATUS_ID = 4;

export const initialState = {
    loading: false,
    ccr: {},
    ccr_companies: [],
    approved_approvers: [],
    applicable_approvers: [],
    original_approvers: []
}

export const reassignCCR = (state = initialState, { type, payload }) => {
    switch (type) {

    case Actions.LOAD_REASSIGN_CCR_DATA:
        return { 
            ...state,
            loading: true,
            error: false
        }

    case Actions.LOAD_REASSIGN_CCR_DATA_SUCCESS:

        const allApprovers = payload.approvers.data;
        const ccrData = payload.ccr.data;
        const comapanySet = new Set(payload.ccr.data.headcountRequests?.map(x => x.companyID));

        const originalApprovers = ccrData?.ccrApprovers
                ?.filter(x => x.isActive)
                ?.sort( (a,b) => parseInt(a.approvalSequence) - parseInt(b.approvalSequence))
                ?.map(x => ({ id: x.approverID, hasApproved: x.approvalStatusID === APPROVED_STATUS_ID}));

        let preloadApprovers = [];
        let approvedApprovers = [];
        originalApprovers.forEach(({id, hasApproved}) => {
            const targetApprover = allApprovers.filter(x => x.userID === id)[0];
            if (targetApprover) {
                let user = R.clone(targetApprover);
                user.hasApproved = hasApproved;
                if (hasApproved)
                    approvedApprovers.push(user);
                else
                    preloadApprovers.push(user);
            };
        });

        const applicableApprovers = allApprovers.reduce((agg, approver) => {
            if (
                approver.userID !== ccrData.requestorID && 
                !approvedApprovers.some( user => user.userID === approver.userID) &&
                approver.companyID.some(id => comapanySet.has(id))){
                agg.push(approver);
            }
            agg.sort()
            return agg;
        }, []);
        applicableApprovers.sort( (a,b) => getLowerCaseName(a).localeCompare(getLowerCaseName(b)));

        return { 
            ...state,
            loading: false,
            ccr: ccrData,
            ccr_companies: [...comapanySet],
            approved_approvers: [...approvedApprovers],
            original_approvers: [...preloadApprovers],
            applicable_approvers: [...applicableApprovers]
        }

    case Actions.REASSIGN_CCR_CLEAR:
        return {...initialState}

    case Actions.SUBMIT_REASSIGN_CCR:
        return {...state, loading: true}

    default:
        return state
    }
}

const getLowerCaseName = (user) => `${user.firstName} ${user.lastName}`.toLowerCase();