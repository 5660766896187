import { createSelector } from "reselect";
import moment from "moment";
import { toDisplayDate } from "../../utils/date/reformat";
import {
  originalApprovers,
  selectedApprovers,
  lastApprovedApprovers,
} from "./manageApprovers";
import {
  compareApprovers,
  compareCcrProperties,
  calculateHeadcountRequestDiff,
} from "./selectedCcr";

// Constants
const databaseFields = [
  "title",
  "justification",
  "sameAsApprover",
  "purchaseOrderUpdateRequired",
  "annualEstimatedCost",
  "equipmentVehicleInvolved",
  "equipmentVehicleEstimatedCost",
  "equipmentDetails",
];

// Store pointers
const headcounts = (state) => state.editCCR.manageHeadcount.headcounts;
const originalHeadcounts = (state) =>
  state.editCCR.manageHeadcount["original_headcounts"];
const lastApprovedHeadcounts = (state) =>
  state.editCCR.manageHeadcount["last_approved_headcounts"];
const referenceData = (state) => state.editCCR.manageHeadcount.referenceData;
const editing = (state) => state.editCCR.manageHeadcount.editing;
const creatingNew = (state) => state.editCCR.manageHeadcount.creatingNew;
const editingCcrStore = (state) => state.editCCR.manageCCR;
const editStore = (state) => state.editCCR;
const cloningFromIdFirstEdit = (state) =>
  state.editCCR.manageHeadcount.cloningFromIdFirstEdit;
const siteStruct = (state) => state.editCCR.lookup.siteStructure;
const companies = (state) => state.editCCR.lookup.companies;
const shifts = (state) => state.editCCR.lookup.shifts;
const usageTypes = (state) => state.editCCR.lookup.usageTypes;

// Logic Functions
const isHeadcountEditable = (hc) =>
  hc.quantity > -1 && (!hc.endDate || moment(hc.endDate) > moment());
const isHeadcountDetailsNotEditable = (hc) =>
  !Boolean(hc.headCountGrouping) && moment(hc.startDate) < moment(); // only apply if individual record

// Data helpers
const getRefName = (ref, item, id) => {
  if (!id || !_.get(ref, item)) return null;
  if (!_.get(_.get(ref, item), id)) return "Unknown";
  return _.get(_.get(ref, item), id);
};

const getFormattedDate = (date) => {
  if (!date) return null;
  return {
    moment: moment(date),
    name: toDisplayDate(date),
    value: moment(date).format("YYYY-MM-DD"),
  };
};

const headcountRefData = (hc, rfd) => ({
  ...hc,
  organization: getRefName(rfd, "organizations", hc.organizationID),
  area: getRefName(rfd, "areas", hc.areaID),
  department: getRefName(rfd, "departments", hc.departmentID),
  section: getRefName(rfd, "sections", hc.sectionID),
  positionRole: getRefName(rfd, "positionRoles", hc.positionRoleID),
  usageType: getRefName(rfd, "usageTypes", hc.usageTypeID),
  company: getRefName(rfd, "companies", hc.companyID),
  startDate: toDisplayDate(hc.startDate),
  endDate: hc.endDate ? toDisplayDate(hc.endDate) : "",
  indefinite: getFormattedDate(hc.indefinite),
  editable: isHeadcountEditable(hc),
  isDetailsNotEditable: isHeadcountDetailsNotEditable(hc),
  shift: getRefName(rfd, "shifts", hc.shiftID),
  billable: hc.billable === true ? "Yes" : "No",
  schedulable: hc.schedulable === true ? "Yes" : "No",
});

const calculateHeadcountRequestDiffEdit = (current, against) => {
  let diff = {};
  if (!current || current.length === 0) return diff;
  current.forEach((hr) => {
    let diffKeys = [];
    const targetHR = against.filter(
      (x) => x.headcountRequestID === hr.headcountRequestID
    )[0];
    if (!targetHR) diffKeys = Object.keys(hr);
    else
      diffKeys = Object.keys(hr).filter(
        (x) => _.get(hr, x) !== _.get(targetHR, x)
      );
    diff[hr.headcountRequestID] = diffKeys;
  });
  return diff;
};

// Selectors
export const getIsEditingHeadcount = createSelector([editing], (x) => x);
export const getSiteStructure = createSelector([siteStruct], (x) => x);
export const getCompanies = createSelector([companies], (x) => x);
export const getActiveCompanies = createSelector([companies], (x) =>
  x.filter((c) => c.priority)
);
export const getUsageTypes = createSelector([usageTypes], (x) => x);
export const getShifts = createSelector([shifts], (x) => x);
export const getCurrentlyCreatingNew = createSelector([creatingNew], (x) => x);
export const getCurrentCloneFromId = createSelector(
  [cloningFromIdFirstEdit],
  (x) => x
);
export const reviewHeadlines = createSelector([editStore], (x) =>
  x.status === "edit" ? ["Edited", "Original"] : ["Edited"]
);

export const getEditedCcr = createSelector(
  [editingCcrStore, headcounts, referenceData, siteStruct, originalApprovers],
  (editingCcrStore, headcounts, rfd, site, selectedApprovers) => {
    let { ccr } = editingCcrStore;
    const newCcr = Object.assign({}, ccr, {
      asset: site[0].name,
      headcountRequests: headcounts.map((x) => headcountRefData(x, rfd)),
      ccrApprovers: selectedApprovers?.map((x) => parseInt(x.user.userID)),
    });
    return newCcr;
  }
);

export const getOriginalCcr = createSelector(
  [
    editingCcrStore,
    originalHeadcounts,
    referenceData,
    siteStruct,
    selectedApprovers,
  ],
  (editingCcrStore, headcounts, rfd, site, originalApprovers) => {
    let { originalCcr: ccr } = editingCcrStore;
    const newCcr = Object.assign({}, ccr, {
      asset: site[0].name,
      headcountRequests: headcounts.map((x) => headcountRefData(x, rfd)),
      ccrApprovers: originalApprovers?.map((x) => parseInt(x.userID)),
    });
    return newCcr;
  }
);

export const getLastApprovedCcr = createSelector(
  [
    editingCcrStore,
    lastApprovedHeadcounts,
    referenceData,
    siteStruct,
    lastApprovedApprovers,
  ],
  (editingCcrStore, headcounts, rfd, site, approvers) => {
    let { previousApprovedCcr: ccr } = editingCcrStore;
    if (!ccr) return null;
    const newCcr = Object.assign({}, ccr, {
      asset: site[0].name,
      headcountRequests: headcounts?.map((x) => headcountRefData(x, rfd)),
      ccrApprovers: approvers?.map((x) => parseInt(x.userID)),
    });
    return newCcr;
  }
);

export const getDiffHighlights = createSelector(
  [editStore, getEditedCcr, getOriginalCcr, getLastApprovedCcr],
  (editStore, ccr, prv, lastApproved) => {
    if (editStore.status === "new") return { ccr: [], headcountRequests: [] };

    if (ccr.ccrStatusID === 5 && lastApproved) {
      return {
        ccr: compareCcrProperties(ccr, lastApproved),
        currentHeadcountRequests: calculateHeadcountRequestDiff(
          ccr.headcountRequests,
          lastApproved.headcountRequests
        ),
        previousHeadcountRequests: calculateHeadcountRequestDiff(
          lastApproved.headcountRequests,
          ccr.headcountRequests
        ),
        ccrApprovers: compareApprovers(
          ccr.ccrApprovers,
          lastApproved.ccrApprovers
        ),
      };
    }

    return {
      ccr: compareCcrProperties(ccr, prv),
      currentHeadcountRequests: calculateHeadcountRequestDiffEdit(
        ccr.headcountRequests,
        prv.headcountRequests
      ),
      previousHeadcountRequests: calculateHeadcountRequestDiffEdit(
        prv.headcountRequests,
        ccr.headcountRequests
      ),
      ccrApprovers: compareApprovers(ccr.ccrApprovers, prv.ccrApprovers),
    };
  }
);

export const getDiffHighlightsOriginal = createSelector(
  [editStore, getEditedCcr, getOriginalCcr],
  (editStore, ccr, prv) => {
    if (editStore.status === "new") return { ccr: [], headcountRequests: [] };
    return {
      ccr: compareCcrProperties(ccr, prv),
      currentHeadcountRequests: calculateHeadcountRequestDiffEdit(
        ccr.headcountRequests,
        prv.headcountRequests
      ),
      previousHeadcountRequests: calculateHeadcountRequestDiffEdit(
        prv.headcountRequests,
        ccr.headcountRequests
      ),
      ccrApprovers: compareApprovers(ccr.ccrApprovers, prv.ccrApprovers),
    };
  }
);

export const changed = createSelector(
  [editStore, getDiffHighlightsOriginal],
  (editStore, diff) => {
    return (
      editStore.status === "new" ||
      diff.ccr.filter((x) => databaseFields.includes(x)).length > 0 ||
      Object.keys(diff.currentHeadcountRequests).some(
        (x) => _.get(diff.currentHeadcountRequests, x).length > 0
      ) ||
      diff.ccrApprovers
    );
  }
);

export const getRenderableHeadcounts = createSelector(
  [headcounts, referenceData],
  (hc, rfd) => {
    let headcounts = [];
    hc.forEach((headcount) => {
      headcounts.push({
        ...headcount,
        organization: getRefName(
          rfd,
          "organizations",
          headcount.organizationID
        ),
        area: getRefName(rfd, "areas", headcount.areaID),
        department: getRefName(rfd, "departments", headcount.departmentID),
        section: getRefName(rfd, "sections", headcount.sectionID),
        positionRole: getRefName(
          rfd,
          "positionRoles",
          headcount.positionRoleID
        ),
        usageType: getRefName(rfd, "usageTypes", headcount.usageTypeID),
        company: getRefName(rfd, "companies", headcount.companyID),
        startDate: getFormattedDate(headcount.startDate),
        endDate: getFormattedDate(headcount.endDate),
        indefinite: getFormattedDate(headcount.indefinite),
        editable: isHeadcountEditable(headcount),
        isDetailsNotEditable: isHeadcountDetailsNotEditable(headcount),
        shift: getRefName(rfd, "shifts", headcount.shiftID),
      });
    });
    return headcounts;
  }
);
