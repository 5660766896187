import React, { Component } from 'react';
import { Table } from 'reactstrap';
import SortableRow from './component/SortableRow';

export default class Sortable extends Component {
  render() {
    const { rows, headers, keys } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            {headers.map((header, count) => (
              // eslint-disable-next-line
              <td key={header + count}>
                {header}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <SortableRow key={row.name} item={row} keys={keys} {...this.props} />
          ))}
        </tbody>
      </Table>
    );
  }
}
