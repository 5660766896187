import React, { useState, useMemo, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./HeadcountCard.module.scss";
import { Col, Row, FormGroup, Label } from "reactstrap";
import { Button, Input } from "reactstrap";
import { Dropdown, Field, ToggleButton } from "../../../../presentation/Input";
import * as Logic from "./EditHeadcountCard.logic";
import * as Actions from "../../../../../redux/actions/manageHeadcountActions";
import {
  getActiveCompanies,
  getUsageTypes,
  getShifts,
} from "../../../../../redux/selectors/manageHeadcount";
import { lookupSiteStructure } from "../../../../../redux/selectors/editCcr";
import DateRange from "../../../Input/DataRange";
import handleKeyPress from "../../../../../utils/number/handleKeyPress";
import moment, { isMoment } from "moment";
import "./datepicker.css";

const EditGroupedHeadcountCard = (props) => {
  const SmallIcon = ({ icon }) => (
    <svg className="em-c-icon em-c-icon--small">
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={`/images/em-icons.svg#${icon}`}
      />
    </svg>
  );
  const { projectedDates } = props;
  console.log(
    "🚀 ~ EditGroupedHeadcountCard ~ projectedDates:",
    projectedDates
  );
  const [headcount, setHeadcount] = useState(props.headcount);
  console.log(headcount);

  const [validation, setValidation] = useState({ valid: true, errors: {} });

  const shiftImbalanceOptionSelected = useMemo(() => {
    const start = moment(headcount.startDate.moment);
    const end = moment(headcount.endDate.moment);
    if (!start.isValid() || !end.isValid()) return "14.14";
    const diff = end.diff(start, "days");

    if (diff === 14) return "14.14";
    if (diff === 7) return "7.7";
    return "4.3";
  }, [projectedDates, headcount]);

  const recurrence = useMemo(() => {
    return Array.isArray(projectedDates) ? projectedDates.length : 2;
  }, [projectedDates]);

  const options = useMemo(
    () =>
      Logic.getOptions(
        props.sites,
        headcount.organizationID,
        headcount.departmentID,
        headcount.sectionID
      ),
    [
      headcount.organizationID,
      headcount.departmentID,
      headcount.sectionID,
      props.sites,
    ]
  );

  const [projectedDatesArr, setProjectedDatesArr] = useState(() => {
    return Array.isArray(projectedDates) ? [...projectedDates] : [];
  });

  const ShiftImbalanceOptions = () => (
    <React.Fragment>
      <Row>
        <Col xs={1}>
          <Row>
            <strong>14-14</strong>
          </Row>
          <Input
            type="checkbox"
            className="position-checkbox"
            checked={shiftImbalanceOptionSelected === "14.14"}
            disabled={true}
          />
        </Col>
        <Col xs={1}>
          <Row>
            <strong>7-7</strong>
          </Row>
          <Input
            type="checkbox"
            className="position-checkbox"
            checked={shiftImbalanceOptionSelected === "7.7"}
            disabled={true}
          />
        </Col>
        <Col xs={1}>
          <Row>
            <strong>4-3</strong>
          </Row>
          <Input
            type="checkbox"
            className="position-checkbox"
            checked={shiftImbalanceOptionSelected === "4.3"}
            disabled={true}
          />
        </Col>
        <Col>
          <Row>
            <strong>Recurrence</strong>
          </Row>
          <Input
            style={{ width: "20%" }}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            value={recurrence}
            bsSize="sm"
            width={"md"}
            disabled={true}
          />
        </Col>
      </Row>
    </React.Fragment>
  );

  const displayProjectedDates = useCallback(() => {
    if (!Array.isArray(projectedDatesArr)) return <></>;
    return (
      <div className={styles.projected_dates}>
        {projectedDatesArr.map(({ startDate, endDate }, i) => {
          return (
            <div key={i} style={{ flexDirection: "row" }}>
              <DateRange
                startDate={startDate.moment}
                endDate={endDate.moment}
                disabled = {true}
                onChange={(startVal, endVal) => {
                  if (moment.isMoment(startVal) && startVal.isValid()) {
                    const newStart = moment(startVal);
                    setProjectedDatesArr((originalArr) =>
                      originalArr.map((originalVal, originalI) =>
                        originalI === i
                          ? {
                              ...originalVal,
                              startDate: {
                                moment: newStart,
                                name: newStart.format("MM DD, YYYY"),
                                value: newStart.format("YYYY-MM-DD"),
                              },
                            }
                          : originalVal
                      )
                    );
                  }
                  if (moment.isMoment(endVal) && endVal.isValid()) {
                    const newEnd = moment(endVal);
                    setProjectedDatesArr((originalArr) =>
                      originalArr.map((originalVal, originalI) =>
                        originalI === i
                          ? {
                              ...originalVal,
                              endDate: {
                                moment: newEnd,
                                name: newEnd.format("MM DD, YYYY"),
                                value: newEnd.format("YYYY-MM-DD"),
                              },
                            }
                          : originalVal
                      )
                    );
                  }
                }}
                initialVisibleMonth={() =>
                  Logic.getInitalMonthForDateRangePicker(
                    headcount.startDate,
                    headcount.endDate
                  )
                }
                blockedDates={[]} // override the default with no blocked dates
              />{" "}
              {moment(endDate.moment).diff(moment(startDate.moment), "days")}{" "}
              Days
            </div>
          );
        })}
      </div>
    );
  }, [projectedDatesArr]);
  return (
    <div className={styles.edit_card_container}>
      {/*
            <header className={styles.card_header}>
                <h3>Headcount Req: {headcount.headcountRequestID}</h3>
                <span>All that is needed to render the display card is here.</span>
            </header>
            */}
      <div>
        <Row className={styles.row_bottom}>
          <Col>
            <Row className={styles.row_bottom}>
              <Col sm={2}>
                <strong>Organization</strong>
              </Col>
              <Col sm={4}>
                <Field
                  isValid={!validation.errors.organizationID}
                  validationMessage={validation.errors.organizationID}
                >
                  <Dropdown
                    sorted={true}
                    items={options.orgOptions}
                    value={headcount.organizationID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        organizationID: Number(e.target.value),
                        positionRoleID: 0,
                        departmentID: 0,
                        sectionID: 0,
                        areaID: 0,
                      })
                    }
                    showNoneOption
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
              <Col sm={2}>
                <strong>Usage Type</strong>
              </Col>
              <Col sm={4}>
                <Field
                  isValid={!validation.errors.usageTypeID}
                  validationMessage={validation.errors.usageTypeID}
                >
                  <Dropdown
                    sorted={true}
                    items={props.usageTypes}
                    value={headcount.usageTypeID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        usageTypeID: Number(e.target.value),
                      })
                    }
                    showNoneOption
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
            </Row>
            <Row className={styles.row_bottom}>
              <Col sm={2}>
                <strong>Role</strong>
              </Col>
              <Col sm={10} className={styles.small_height}>
                <Field
                  isValid={!validation.errors.positionRoleID}
                  validationMessage={validation.errors.positionRoleID}
                >
                  <Dropdown
                    sorted={true}
                    items={options.roleOptions}
                    value={headcount.positionRoleID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        positionRoleID: Number(e.target.value),
                      })
                    }
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
            </Row>
            <Row className={styles.row_bottom}>
              <Col sm={2}>
                <strong>Department</strong>
              </Col>
              <Col sm={10} className={styles.small_height}>
                <Field
                  isValid={!validation.errors.departmentID}
                  validationMessage={validation.errors.departmentID}
                >
                  <Dropdown
                    sorted={true}
                    items={options.deptOptions}
                    value={headcount.departmentID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        departmentID: Number(e.target.value),
                        sectionID: 0,
                        areaID: 0,
                      })
                    }
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
            </Row>
            <Row className={styles.row_bottom}>
              <Col sm={2}>
                <strong>Section</strong>
              </Col>
              <Col sm={4}>
                <Field>
                  <Dropdown
                    sorted={true}
                    items={options.sectOptions}
                    value={headcount.sectionID || 0}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        sectionID: Number(e.target.value),
                        areaID: 0,
                      })
                    }
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
              <Col sm={2}>
                <strong>Area</strong>
              </Col>
              <Col sm={4}>
                <Dropdown
                  sorted={true}
                  items={options.areaOptions}
                  value={headcount.areaID || 0}
                  onChange={(e) =>
                    setHeadcount({
                      ...headcount,
                      areaID: Number(e.target.value),
                    })
                  }
                  disabled={headcount.isDetailsNotEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <strong>Company</strong>
              </Col>
              <Col sm={4}>
                <Field>
                  <Dropdown
                    sorted={true}
                    items={props.companies}
                    value={headcount.companyID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        companyID: Number(e.target.value),
                      })
                    }
                    showNoneOption
                    disabled={headcount.status !== "New"}
                  />
                </Field>
              </Col>
              <Col sm={2}>
                <strong>Qty</strong>
              </Col>
              <Col sm={4}>
                <Input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  placeholder="Number of contractors"
                  value={headcount.quantity}
                  onKeyDown={(e) => handleKeyPress(e)}
                  min={headcount.status === "New" ? 1 : 0}
                  onChange={(e) =>
                    setHeadcount({
                      ...headcount,
                      quantity: Number(e.target.value),
                    })
                  }
                  disabled={headcount.isDetailsNotEditable}
                />
              </Col>
              <div
                className={
                  validation.errors.quantity
                    ? "invalid-feedback show"
                    : "invalid-feedback"
                }
              >
                {validation.errors.quantity}
              </div>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Row>
              <Col sm={7}>
                <strong>Shift</strong>
                <Row>
                  <Col>
                    <ToggleButton
                      name={"shiftID"}
                      options={props.shifts.map((x) => x.label)}
                      onChange={(value) =>
                        setHeadcount({
                          ...headcount,
                          shift: value,
                          shiftID: Number(
                            props.shifts.find((x) => x.label === value).value
                          ),
                        })
                      }
                      selected={headcount.shift}
                      disabled={headcount.isDetailsNotEditable}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Billable</strong>
                  </Col>
                  <Col>
                    <Input
                      type="checkbox"
                      className="position-checkbox"
                      onChange={(e) =>
                        setHeadcount({
                          ...headcount,
                          billable: e.target.checked,
                        })
                      }
                      checked={headcount.billable}
                      disabled={headcount.isDetailsNotEditable}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Schedulable</strong>
                  </Col>
                  <Col>
                    <Input
                      type="checkbox"
                      className="position-checkbox"
                      onChange={(e) =>
                        setHeadcount({
                          ...headcount,
                          schedulable: e.target.checked,
                        })
                      }
                      checked={headcount.schedulable}
                      disabled={headcount.isDetailsNotEditable}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Shift Imbalance</strong>
                  </Col>
                  <Col>
                    <Input
                      type="checkbox"
                      className="position-checkbox"
                      checked={true}
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <strong>Dates</strong>
            </Row>
            <Row>{displayProjectedDates()}</Row>
          </Col>
        </Row>
      </div>

      <div className={styles.edit_card_button_container}>
        <Button
          className={styles.card_button}
          outline
          color="primary"
          onClick={() => props.cancelEdit()}
        >
          Cancel
        </Button>
        <Button
          className={styles.card_button}
          color="primary"
          onClick={() => {
            const validObject = Logic.validate(headcount);
            if (validObject.valid) {
              props.saveEdit(
                {
                  ...headcount,
                },

                projectedDatesArr
              );
            } else {
              setValidation(validObject);
            }
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sites: lookupSiteStructure(state),
  companies: getActiveCompanies(state),
  usageTypes: getUsageTypes(state),
  shifts: getShifts(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    cancelEdit: () => dispatch(Actions.cancelEditHeadcount()),
    saveEdit: (headcount, projectedDates) =>
      //   console.log(headcount, shiftImbalance),
      dispatch(Actions.saveGroupedEditHeadcount(headcount, projectedDates)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGroupedHeadcountCard);
