import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReviewWrapper from '../../presentation/CCR/Review/actionWrapper';
import Loading from '../../presentation/Loading';
import { showErrorAction, getCcrAction, resetSelectedCCRAction } from '../../../redux/actions';
import { getCcr, getSelectedHeadlines, getSelectedLoading,
         getSelectedPrevious, getDiffPreviousCurrent
} from '../../../redux/selectors/selectedCcr';
import { getRequestor as currentUser } from '../../../redux/selectors/editCcr';
import { declineCCRAction, approveCCRAction } from '../../../redux/actions/ccrApprovals';
import { approveCcrLoading } from '../../../redux/selectors/approveCcr';

const copy = {
  title: 'Approver Review',
  breadcrumb: [
    { name: 'Home', link: '/' },
    { name: 'CCR', isActive: 'true' },
    { name: 'Approve', isActive: 'true' },
  ],
  actions: {
    positive: 'Approve',
    negative: 'Deny',
  },
};

export class Approver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ccr: {},
      isLoading: false,
      isOpen: false,
      loadingMessage: 'Loading ...',
    };
    this.approve = this.approve.bind(this);
    this.toggleComment = this.toggleComment.bind(this);
  }

  toggleComment() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  componentDidMount() {
    const { location: { state: ccr } } = this.props;
    if (ccr !== undefined) {
      const { ccrID } = ccr;
      this.setState({
        isLoading: true
      })
      this.props.getCcr(ccrID)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.setState({
        isLoading: false
      })
    }
  }

  async approve(isApproved, comments) {

    if (this.props.isLoadingNew || this.state.isLoading || this.props.isLoading)
      return;

    const loadingMessage = isApproved ? 'Approving the CCR...' : 'Denying the CCR...';
    this.setState({ isLoading: true, loadingMessage });
    const { history } = this.props;
    const { ccr } = this.props;

    if (isApproved) {

      const data = {
        "ccrID": ccr.ccrID,
        "approverID": currentUser().requestorID
      };
      
      this.props.approveCcrCall(data, history);
    }
    else {
      
      if (!this.state.isOpen) return;
      this.toggleComment();

      const data = {
        "ccrID": ccr.ccrID,
        "approverID": currentUser().requestorID,
        "comments": comments
      };

      this.props.declineccr(data, history);
    };

  }

  render() {
    const { loadingMessage, isLoading } = this.state;
    return [
      <Loading 
        isShowing={
          this.props.isLoadingNew || 
          isLoading ||
          this.props.isLoading
        } 
        text={loadingMessage} 
        key="approverLoading" 
      />,
      <ReviewWrapper
        {...this.props}
        proceed={this.approve}
        copy={copy}
        key="approverContent"
        isOpen={this.state.isOpen}
        toggleComment={this.toggleComment}
        onDeny={(comment) => this.approve(false, comment)}
      />,
    ];
  }
}

const mapStateToProps = (state) => ({
  ccr: getCcr(state),
  headlines: getSelectedHeadlines(state),
  previous: getSelectedPrevious(state),
  diff: getDiffPreviousCurrent(state),
  isLoading: getSelectedLoading(state),
  isLoadingNew: approveCcrLoading(state)
});

const mapDispatchToProps = {
  showError: showErrorAction,
  getCcr: getCcrAction,
  resetSelection: resetSelectedCCRAction,
  declineccr: declineCCRAction, 
  approveCcrCall: approveCCRAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Approver);
