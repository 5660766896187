import { put, takeEvery } from 'redux-saga/effects';
import { DECLINE_CCR, APPROVE_CCR, setApproveCcrLoadingAction } from '../actions/ccrApprovals';
import { ccr as ccrAPI } from '../../api';
import { showErrorAction } from '../actions/errorMessageActions'

export function* declineCcrAsync(action) {

    yield put(setApproveCcrLoadingAction(true));
    const {data, history} = action.payload;

    try {
        yield ccrAPI.put.declineccr(data);
    } catch (ex) {
        yield put(showErrorAction('Error Occured. Please contact application support', ex))
    } finally {
        yield put(setApproveCcrLoadingAction(false));
        history.push('/');
    }
}

export function* approveCcrAsync(action) {

    yield put(setApproveCcrLoadingAction(true));
    const {data, history} = action.payload;

    try {
        yield ccrAPI.put.approveccr(data);
    } catch (ex) {
        yield put(showErrorAction('Error Occured. Please contact application support', ex))
    } finally {
        yield put(setApproveCcrLoadingAction(false));
        history.push('/');
    }
}


export function* watchDeclineCcr() {
    yield takeEvery(DECLINE_CCR, declineCcrAsync)
}

export function* watchApproveCcr() {
    yield takeEvery(APPROVE_CCR, approveCcrAsync)
}

export default [watchDeclineCcr, watchApproveCcr]
