import React from 'react';
import ReactQuill from 'react-quill';
import cx from 'classnames';
import './TextArea.css';

const toolbarOptions = [['bold', 'italic', 'underline'],[{ 'list': 'ordered'}, { 'list': 'bullet' }], ['clean']];
  
const formats = [
    'bold', 'italic', 'underline', 'blockquote',
    'list', 'bullet', 'indent',
];

const TextArea = (props) => {
  
  const classNames = cx({'kmod-quill-readonly': props.readOnly}, {'kmod-quill-highlighted': props.highlight}, {'kmod-quill-disabled': props.disabled});
  
  return (
        <ReactQuill
          theme="snow"
          className={classNames}
          modules={props.enableToolbar ? {toolbar:toolbarOptions} : {toolbar: false}}
          formats={formats}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
    )
}

TextArea.defaultProps = {
  enableToolbar: false
}

export default TextArea;