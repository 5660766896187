import moment from "moment";
import * as Actions from "../../actions/manageHeadcountActions";
import * as Logic from "./manageHeadcount.logic";
import { v4 } from "uuid";

export const initialState = {
  editing: 0,
  creatingNew: false,
  cloningFromIdFirstEdit: 0,
  original_headcounts: [],
  headcounts: [],
  referenceData: {
    companies: {},
    areas: {},
    departments: {},
    organizations: {},
    positionRoles: {},
    sections: {},
    usageTypes: {},
  },
};

export const manageHeadcount = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.MANAGE_HEADCOUNTS_LOAD:
      return {
        ...state,
        editing: 0,
        creatingNew: false,
        cloningFromIdFirstEdit: 0,
        referenceData: { ...Logic.getKeyValuesFromApiData(payload) },
        //the following 3 attribues have to have separate calls to prevent reference entanglement
        original_headcounts: Logic.getHeadcountsFromApiData(
          payload?.ccr?.data,
          payload?.lastApproved?.data
        ),
        last_approved_headcounts: Logic.getHeadcountsFromApiData(
          payload?.lastApproved?.data
        ),
        headcounts: Logic.getHeadcountsFromApiData(
          payload?.ccr?.data,
          payload?.lastApproved?.data
        ),
      };

    case Actions.MANAGE_HEADCOUNTS_RESET:
      return {
        ...state,
        editing: 0,
        creatingNew: false,
        cloningFromIdFirstEdit: 0,
        headcounts: Logic.deepCopyUsingJSON(state.original_headcounts),
      };

    case Actions.MANAGE_HEADCOUNTS_CLONE:
      const headcountToClone = Logic.getHeadcountByID(
        state.headcounts,
        payload
      );
      const idx = state.headcounts.findIndex(
        (hc) => hc.headcountRequestID === headcountToClone.headcountRequestID
      );
      const clonedHeadcount = Logic.getHeadcountClone(headcountToClone);
      return {
        ...state,
        editing: clonedHeadcount.headcountRequestID,
        cloningFromIdFirstEdit: headcountToClone.headcountRequestID,
        headcounts: [
          ...state.headcounts.slice(0, idx),
          clonedHeadcount,
          ...state.headcounts.slice(idx),
        ],
      };

    case Actions.MANAGE_HEADCOUNTS_CREATE:
      const newHeadcount = Logic.getNewHeadcount(state.referenceData);
      return {
        ...state,
        editing: newHeadcount.headcountRequestID,
        creatingNew: true,
        headcounts: [newHeadcount, ...state.headcounts],
      };

    case Actions.MANAGE_HEADCOUNTS_EDIT:
      if (state.editing)
        throw new Error("There is already a card being edited.");
      return { ...state, editing: payload };

    case Actions.MANAGE_HEADCOUNTS_EDIT_CANCEL:
      if (state.creatingNew) {
        const headcountToDelete = Logic.getHeadcountByID(
          state.headcounts,
          state.editing
        );
        return {
          ...state,
          editing: 0,
          creatingNew: false,
          cloningFromIdFirstEdit: 0,
          headcounts: [
            ...Logic.getFilteredHeadcountByID(
              state.headcounts,
              headcountToDelete.headcountRequestID
            ),
          ],
        };
      }
      return { ...state, editing: 0, cloningFromIdFirstEdit: 0 };

    case Actions.MANAGE_HEADCOUNTS_EDIT_SAVE:
      const { headcounts } = state;
      const { headcount, shiftImbalance } = payload;
      const headcountToUpdate = Logic.getHeadcountByID(
        state.headcounts,
        headcount.headcountRequestID
      );
      const filteredHeadcounts = Logic.getFilteredHeadcountByID(
        state.headcounts,
        headcount.headcountRequestID
      );
      const update = Logic.updateExistingHeadcount(
        headcountToUpdate,
        headcount
      );
      const shiftImbalanceHeadCounts = [];
      if (
        shiftImbalance &&
        shiftImbalance?.isShiftImbalance &&
        Array.isArray(shiftImbalance?.projectedDatesArr)
      ) {
        const maxGrouping = headcounts.reduce(
          (maxGroupVal, { headCountGrouping: currNum }) =>
            currNum && Number(currNum) > maxGroupVal ? currNum : maxGroupVal,
          0
        );
        const additionalHeadcounts = shiftImbalance.projectedDatesArr.map(
          ({ startDate, endDate }) => {
            const clone = Logic.getHeadcountClone(update);
            return {
              ...clone,
              headCountGrouping: maxGrouping + 1,
              startDate: startDate.format(),
              endDate: endDate.format(),
            };
          }
        );
        shiftImbalanceHeadCounts.push(...additionalHeadcounts);
      }

      const headcountsToAdd = shiftImbalanceHeadCounts.length
        ? shiftImbalanceHeadCounts
        : [update];

      return {
        ...state,
        editing: 0,
        creatingNew: false,
        cloningFromIdFirstEdit: 0,
        headcounts: [...filteredHeadcounts, ...headcountsToAdd],
      };
     
    case Actions.MANAGE_HEADCOUNTS_GROUPED_EDIT_SAVE:
      const { baseHeadcount, projectedDates } = payload;
      const modifiedHeadcountsForSameGrouping = state.headcounts
        .filter(
          (hc) => hc.headCountGrouping === baseHeadcount.headCountGrouping
        )
        .map((originalHc) => {
          const newDates = projectedDates.find(
            ({ headcountRequestID }) =>
              headcountRequestID === originalHc.headcountRequestID
          );
          console.log("🚀 ~ arr ~ newDates:", newDates);
          const headcountToUpdate = Logic.getHeadcountByID(
            state.headcounts,
            originalHc.headcountRequestID
          );

          if (newDates) {
            headcountToUpdate.startDate = newDates.startDate.moment.format();
            headcountToUpdate.endDate = newDates.endDate.moment.format();
          }
          const ret = Logic.updateExistingHeadcount(headcountToUpdate, {
            ...baseHeadcount,
            headcountRequestID: headcountToUpdate.headcountRequestID,
            startDate: { ...newDates.startDate },
            endDate: { ...newDates.endDate },
          });
          return ret;
        });

      return {
        ...state,
        editing: 0,
        creatingNew: false,
        cloningFromIdFirstEdit: 0,
        headcounts: [
          ...state.headcounts.filter(
            (hc) => hc.headCountGrouping !== baseHeadcount.headCountGrouping
          ),
          ...modifiedHeadcountsForSameGrouping,
        ],
      };

    case Actions.MANAGE_HEADCOUNTS_DELETE:
      const headcountToDelete = Logic.getHeadcountByID(
        state.headcounts,
        payload
      );
      if (headcountToDelete.status !== "New")
        throw new Error("Cannot delete an original Headcount Request");
      return {
        ...state,
        headcounts: [
          ...Logic.getFilteredHeadcountByID(
            state.headcounts,
            headcountToDelete.headcountRequestID
          ),
        ],
      };

    case Actions.MANAGE_HEADCOUNTS_DELETE_GROUPED:
      const headCountGrouping = payload;
      const headcountsToDelete = state.headcounts.filter(
        (x) => x?.headCountGrouping === headCountGrouping
      );
      if (headcountsToDelete.some((hc) => hc.status !== "New")) {
        throw new Error("Cannot delete an original Headcount Request");
      }
      return {
        ...state,
        headcounts: [
          ...state.headcounts.filter(
            (hc) =>
              !headcountsToDelete.some(
                (hcToDelete) =>
                  hcToDelete.headcountRequestID === hc.headcountRequestID
              )
          ),
        ],
      };

    default:
      return state;
  }
};
