import React, { Component, Fragment } from 'react';
import {
  find,
  propEq,
} from 'ramda';
import { user as userAPI, unassignedPersonnel } from '../../../../../api';
import UnassignedPersonnelView from './presentation';
import Error from '../../../../presentation/Error';
import Loading from '../../../../presentation/Loading';
import { download } from '../helper';

export default class UnassignedPersonnel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      company: null,
      isError: false,
      errorMessage: '',
      isLoading: false,
      loadingMessage: 'Loading Companies',
    };
    this.setSelected = this.setSelected.bind(this);
    this.getCompanies = this.getCompanies.bind(this);
    this.getReport = this.getReport.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

  componentDidMount() {
    this.getCompanies();
    this.setState({ company: null });
  }

  async getCompanies() {
    const companies = await userAPI.get.activeCompanies().catch(() => []);
    this.setState({ companies: companies.data });
  }

  setSelected(companySelection) {
    const { companies } = this.state;
    const company = find(propEq('name', companySelection))(companies);
    if (company !== null) {
      this.setState({ company });
    } else {
      this.setState({ company: null });
    }
  }

  getReport() {
    const { company } = this.state;
    this.setState({ isLoading: true });
    unassignedPersonnel.get.export(company.companyID)
      .then((res) => {
        download(res.data, res.headers);
        this.setState({ isLoading: false, isError: false, errorMessage: '' });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.setState({ isLoading: false, isError: true, errorMessage: `There has been no updated data from Genetec for ${company.name} in the past 24 hours.` });
        } else {
          this.setState({ isLoading: false, isError: true, errorMessage: `There are no personnel assigned in the system for ${company.name}` });
        }
      });
  }

  dismissError() {
    this.setState({ isError: false, errorMessage: '' });
  }

  render() {
    const {
      isError,
      errorMessage,
      isLoading,
      loadingMessage,
    } = this.state;
    return (
      <Fragment>
        <Loading isShowing={isLoading} text={loadingMessage} />
        <UnassignedPersonnelView
          {...this.state}
          setSelected={this.setSelected}
          getReport={this.getReport}
        />
        <Error show={isError} dismiss={this.dismissError} copy={errorMessage} />
      </Fragment>
    );
  }
}
