import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import moment from "moment";

import isSameDay from "../../../../utils/date/isSameDate";

const blockedDatesDefault = [moment()];

const DateRange = ({
  startDate,
  endDate,
  onChange,
  initialVisibleMonth,
  blockedDates,
  disabled,
}) => {
  const [dateFocused, setDateFocused] = useState(null);

  const updateDateFocus = (dateFocused) => {
    setDateFocused(dateFocused);
  };

  const blockedDatesToUse = blockedDates ? blockedDates : blockedDatesDefault;

  return (
    <React.Fragment>
      <DateRangePicker
        startDate={startDate}
        startDateId="startDate"
        endDate={endDate}
        endDateId="endDate"
        onDatesChange={({ startDate, endDate }) => onChange(startDate, endDate)}
        focusedInput={dateFocused}
        onFocusChange={(focused) => updateDateFocus(focused)}
        disabled={disabled}
        initialVisibleMonth={initialVisibleMonth}
        numberOfMonths={3}
        withPortal={true}
        small
        required
        showClearDates
        reopenPickerOnClearDates
        isDayBlocked={(day) =>
          blockedDatesToUse.some((day2) => isSameDay(day2, day))
        }
        minimumNights={0}
      />
    </React.Fragment>
  );
};

export default DateRange;
