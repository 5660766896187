import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isUser, getDBUser } from '../../../../js/msal/msalHelper';

class ReassignRoute extends Component {

    render() {
        const { component: ReactComponent, ...rest } = this.props;
        const user = getDBUser();
        const { userID } = user;
        if (!isUser.admin() && this.props.location.state.requestorID !== userID) {
            return (<Redirect to="/" />);
        }

        return (
            <Route
                {...rest}
                render={props => <ReactComponent {...props} />}
            />
        );
    }
}

export default ReassignRoute;
