import React, { Component } from "react";
import { Alert, Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { isUser } from "../js/msal/msalHelper";
import Routes from "../components/routes/Routes";
import TopNav from "../components/containers/Nav/TopNav";
import SideNav from "../components/containers/Nav/SideNav";
import ErrorMessage from "../components/containers/ErrorMessage";
import isIE from "../utils/isIE";
import "./App.css";
import "../utils/util.css";

class App extends Component {
  AppWithSideBar = () => (
    <Row>
      <SideNav />
      <Col sm="10">
        <Routes />
      </Col>
    </Row>
  );

  AppWithoutSideBar = () => (
    <Row>
      <Col sm="12">
        <div className="em-l-container em-l-container--override">
          <Routes />
        </div>
      </Col>
    </Row>
  );

  IEAlert = () => (
    <Alert color="warning">
      We have detected that you are using IE! For the best experience we
      recommend you use Google Chrome!
    </Alert>
  );

  render() {
    const showSideNav = 
      isUser.admin() || isUser.contractLead() || isUser.securityGuard();

    return (
      <div>
        <TopNav />
        {isIE && this.IEAlert()}
        <ErrorMessage />
        <Container fluid>
          {showSideNav ? this.AppWithSideBar() : this.AppWithoutSideBar()}
        </Container>
      </div>
    );
  }
}

export default compose(withRouter)(App);
