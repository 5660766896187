import React from 'react';
import { Alert } from 'reactstrap';

const UploadAlert = (props) => {
  const {
    show,
    dismiss,
    isSuccess,
    successMessage,
    failMessage,
  } = props;
  if (isSuccess) {
    return (
      <Alert color="success" isOpen={show} toggle={dismiss}>
        { successMessage }
      </Alert>
    );
  }
  return (
    <Alert color="danger" isOpen={show} toggle={dismiss}>
      { failMessage }
    </Alert>
  );
};

export default UploadAlert;
