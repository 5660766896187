import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isUser } from '../../../js/msal/msalHelper';

class HomeRoute extends Component {
  render() {
    const { component: ReactComponent, ...rest } = this.props;

    // only if you are a security guard/requestor, else see all the functionality
    const isOther = isUser.admin()
      || isUser.contractLead()
      || isUser.approver();
    if (isUser.securityGuard() && !isOther) {
      return (<Redirect to="/badge-data-entry" />);
    }

    return (
      <Route
        {...rest}
        render={props => <ReactComponent {...props} />}
      />
    );
  }
}

export default HomeRoute;
