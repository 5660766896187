import React from 'react';
import BreadcrumbNav from '../../presentation/Breadcrumb';
import Wizard from '../Nav/Wizard'
import RequestForm from './RequestForm';
import Review from './Review';
import ManageHeadcounts from './ManageHeadcounts/ManageHeadcounts';
import ManageApproversContainer from './ManageApprovers';

const breadcrumb = (editStatus) => ([
    { name: 'Home', link: '/' },
    { name: 'CCR', isActive: true },
    { name: editStatus.charAt(0).toUpperCase() + editStatus.slice(1), isActive: true },
  ]);

const headers = ['Request Information', 'Manage Headcount', 'Approvers', 'Review'];

const EditView = (props) => {

    return (
        <div className="homeContainer">
            <BreadcrumbNav items={breadcrumb(props.editStatus)} />
            <div className="mocContainer">
                <h3 className="header"> {`${props.editStatus === 'new' ? 'Submit' : 'Edit'} CCR`}</h3>
            </div>
            <Wizard headers={headers}
                components={[ 
                    <RequestForm {...props} />, 
                    <ManageHeadcounts {...props} />, 
                    <ManageApproversContainer {...props} />, 
                    <Review {...props} />
            ]} 
            />
        </div>
    );
} 

export default EditView;