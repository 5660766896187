const headcountDataStructure = [
  {
    value: "organization",
    label: "Organization",
    isSectionGroupOption: true,
    isSortOption: true,
  },
  {
    value: "usageType",
    label: "Usage Type",
    isSectionGroupOption: true,
    isSortOption: true,
  },
  {
    value: "positionRole",
    label: "Role",
    isSectionGroupOption: true,
    isSortOption: true,
  },
  {
    value: "department",
    label: "Department",
    isSectionGroupOption: true,
    isSortOption: true,
  },
  {
    value: "section",
    label: "Section",
    isSectionGroupOption: true,
    isSortOption: true,
  },
  {
    value: "area",
    label: "Area",
    isSectionGroupOption: true,
    isSortOption: true,
  },
  {
    value: "status",
    label: "Status",
    isSectionGroupOption: true,
    isSortOption: false,
  },
  {
    value: "company",
    label: "Company",
    isSectionGroupOption: true,
    isSortOption: true,
  },
];
const statusOrder = ["New", "Edited", "Current"];

export const getSectionOptions = headcountDataStructure.filter(
  (atr) => atr.isSectionGroupOption
);
export const getSortOptions = headcountDataStructure.filter(
  (atr) => atr.isSortOption
);

export const getDefaultConfig = {
  group: "department",
  sort: "section",
  date_sort: "Start Date",
};

// Depracated
export const validateHeadcounts = () => {
  return {
    isError: false,
    errorMsg: "Just an Error!",
  };
};

export const getCuratedHeadcountRequests = (
  headcounts,
  config,
  currentlyEditingHeadcount,
  currentCloneFromId
) => {
  const groupedAndSorted = sortSections(
    config.group,
    arrayGroupBy(headcounts, config.group)
  );
  groupedAndSorted.forEach((section) =>
    section.values.sort((a, b) => hcSortFunction(a, b, config))
  );

  // Move the newly cloned card right next to the parent card
  if (currentCloneFromId && config.group !== "status") {
    for (let i = 0; i < groupedAndSorted.length; i++) {
      const section = groupedAndSorted.at(i);

      const cloneParentIndex = section.values.findIndex(
        (hc) => hc.headcountRequestID === currentCloneFromId
      );
      if (cloneParentIndex === -1) continue;
      const cloneIndex = section.values.findIndex(
        (hc) => hc.headcountRequestID === currentlyEditingHeadcount
      );

      const targetIndex =
        cloneIndex < cloneParentIndex ? cloneParentIndex : cloneParentIndex + 1;
      let element = section.values.at(cloneIndex);
      section.values.splice(cloneIndex, 1);
      section.values.splice(targetIndex, 0, element);

      break;
    }
  }

  // Add editing status to the
  for (let i = 0; i < groupedAndSorted.length; i++) {
    if (
      groupedAndSorted
        .at(i)
        .values.some((x) => x.headcountRequestID === currentlyEditingHeadcount)
    ) {
      groupedAndSorted.at(i).editing = true;
      break;
    }
  }

  return groupedAndSorted;
};

export const arrayGroupBy = (data, key) =>
  data.reduce((groupedData, item) => {
    let group = groupedData.find(
      (map) => map && map.key === (_.get(item, key) || "")
    );
    group
      ? group.values.push(item)
      : groupedData.push({
          key: _.get(item, key) === null ? "" : _.get(item, key),
          values: [item],
        });
    return groupedData;
  }, []);

const sortSections = (groupBy, data) => {
  switch (groupBy) {
    case "status":
      data.sort(
        (a, b) => statusOrder.indexOf(a.key) - statusOrder.indexOf(b.key)
      );
      break;

    default:
      data.sort((a, b) => a.key.localeCompare(b.key));
      break;
  }
  return data;
};

const hcSortFunction = (a, b, config) => {
  const A = a[config.sort] || "";
  const B = b[config.sort] || "";
  const dateSortAtr =
    config.date_sort === "Start Date" ? "startDate" : "endDate";

  const level1SortCondition = A.localeCompare(B);
  if (!level1SortCondition) {
    const dateA = _.get(a, dateSortAtr);
    const dateB = _.get(b, dateSortAtr);

    if (!dateA && !dateB) return 0;
    if (dateA && dateB) return dateA.moment.diff(dateB.moment);

    if (dateSortAtr === "startDate") {
      if (dateA) return 1;
      return -1;
    } else {
      if (dateA) return -1;
      return 1;
    }
  } else {
    return level1SortCondition;
  }
};

export const getSectionWithEditingHeadcount = (
  curatedHCs,
  currentlyEditingHeadcount
) => {
  if (!currentlyEditingHeadcount) return null;
  return null;
};
