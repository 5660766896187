function toTitleCase(str) {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i += 1) {
    _.assign(splitStr, {
      [i]: splitStr.at(i).charAt(0).toUpperCase() + splitStr.at(i).substring(1),
    });
  }
  return splitStr.join(" ");
}

export const titleCaseRegex = (str) =>
  str?.toLowerCase().replace(/(^|\s)\S/g, (t) => t.toUpperCase());
export default toTitleCase;
