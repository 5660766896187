import React from 'react';

export default function ConditionalCell(props) {
  const { condition, children, header } = props;
  if (condition) {
    if (header) {
      return (
        <th>
          {children}
        </th>
      );
    }
    return (
      <td>
        {children}
      </td>
    );
  }

  return null;
}
