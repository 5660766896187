import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "reactstrap";
import obj from "../../../utils/json/flatten";
import {
  isUser,
  getDBUser,
  loadDBUserAndCache,
} from "../../../js/msal/msalHelper";
import ButtonLink from "./component/ButtonLink";
import UnityButton from "../../presentation/unity/Button";
import { titleCaseRegex as titleCase } from "../../../utils/string/to-title-case";
import ConditionalCell from "./component/ConditionalCell";
import Marker from "../../presentation/Marker";
import styles from "./Table.module.scss";
import moment from "moment";
import _ from "lodash";

export default class TableLayout extends Component {
  renderField = (field, value, fn) => {
    if (field === "totalHeadcount") return value;
    else if (value && fn) return fn(value);
    else if (value) return value;
    return <div>&nbsp;</div>;
  };

  render() {
    const {
      headers,
      list,
      fields,
      remove,
      edit,
      theadClass,
      renderFunctions,
      tableMainClass,
      isCCRList,
      setModal,
      newCcrFlag,
      highlights,
    } = this.props;

    const isApprover = (isUser.approver() || !isUser.approver()) && isCCRList;
    const isAdmin = isUser.admin() && isCCRList;
    const user = getDBUser();
    const marked = (id, element) => {
      return _.get(highlights, id) && _.get(highlights, id).includes(element);
    };
    const { userID } = user;
    return (
      <div>
        <Table hover className={tableMainClass}>
          <thead className={theadClass}>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
              <ConditionalCell condition={remove !== null} header>
                Remove
              </ConditionalCell>
              <ConditionalCell condition={edit !== null} header>
                Edit
              </ConditionalCell>

              <ConditionalCell condition={isApprover} header>
                Approver
              </ConditionalCell>
              <ConditionalCell condition={isCCRList && userID} header>
                Re-Assign
              </ConditionalCell>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => {
              const flatItem = obj.flatten(item);
              const isExpired =
                !isCCRList &&
                item.endDate !== null &&
                moment(item.endDate) < moment()
                  ? true
                  : false;
              const color = isExpired ? "#212529a1" : "";
              const bg = isExpired ? "#80808030" : "";

              /* eslint-disable react/no-array-index-key */
              return (
                /* <tr key={index} className={styles.headcount_expired}> */
                <tr key={index} style={{ color: color, backgroundColor: bg }}>
                  <ConditionalCell condition={isCCRList}>
                    <Button
                      color="link"
                      size="sm"
                      onClick={() => setModal(item.ccrID)}
                    >
                      {item.ccrNumber}
                    </Button>
                    {(item.prevID && item.prevID > 0) ||
                    (item.archiveID && item.archiveID > 0) ? (
                      <div className={styles.pill_edited}>edited</div>
                    ) : (
                      ""
                    )}
                  </ConditionalCell>
                  {fields.map((field) => (
                    <td key={field}>
                      <Marker
                        hasMarker={marked(item.headcountRequestID, field)}
                      >
                        {this.renderField(
                          field,
                          _.get(flatItem, field),
                          _.get(renderFunctions, field)
                        )}
                      </Marker>
                    </td>
                  ))}
                  <ConditionalCell condition={remove !== null}>
                    <UnityButton
                      size="small"
                      onClick={() => remove(item)}
                      text="Remove"
                    />
                  </ConditionalCell>
                  <ConditionalCell condition={edit !== null}>
                    <UnityButton
                      size="small"
                      text="Edit"
                      onClick={() => edit(item)}
                    />
                  </ConditionalCell>
                  <ConditionalCell
                    condition={isApprover && isCCRList && newCcrFlag !== true}
                  >
                    <ButtonLink
                      show={isApprover}
                      canClick={
                        item.approverID === userID &&
                        item.ccrStatus.name === "For Review"
                      }
                      path="/ccr/approver-review"
                      item={item}
                      text="Review"
                    />

                    <div
                      className={`${
                        item.approverID !== userID &&
                        item.ccrStatus &&
                        item.ccrStatus.name === "For Review"
                          ? "em-u-display-block"
                          : "em-u-is-hidden"
                      }`}
                    >
                      {titleCase(item.approverName)}
                    </div>
                  </ConditionalCell>
                  <ConditionalCell condition={isCCRList && newCcrFlag !== true}>
                    <ButtonLink
                      show={isAdmin || item.requestorID === userID}
                      canClick={
                        item.ccrStatus &&
                        (item.ccrStatus.name === "Submitted" ||
                          item.ccrStatus.name === "For Review")
                      }
                      path={`/ccr/reassign/${item.ccrID}`}
                      item={item}
                      text="Update"
                    />
                  </ConditionalCell>
                </tr>
              );
              /* eslint-enable react/no-array-index-key */
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

TableLayout.defaultProps = {
  headers: [],
  highlights: [],
  list: [],
  fields: [],
  renderFunctions: {},
  theadClass: "",
  tableMainClass: "",
  remove: null,
  edit: null,
};

TableLayout.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  list: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.arrayOf(PropTypes.string),
  remove: PropTypes.func,
  edit: PropTypes.func,
};
