
const emptyValue = {value: 0, label: ''}
const emptySection = {
    ...emptyValue,
    areas: [emptyValue]
}
const emptyDepartment = {...emptyValue, sections : [
    emptySection
]}

const filterDeleted = arr => arr.filter(x => !x.isDeleted);


export function siteDateRestructure (siteData) {
    var sites = filterDeleted(siteData.organizations).map(org => (
        {   
            value: org.organizationID , label: org.name,
            departments: org.departments.length ?
                [emptyDepartment].concat(filterDeleted(org.departments).map(dep => ({
                    value: dep.departmentID, label: dep.name,
                    sections: [emptySection].concat(filterDeleted(dep.sections).map(sec => ({
                        value: sec.sectionID, label: sec.name,
                        areas: [emptyValue].concat(filterDeleted(sec.areas).map(ar => ({value: ar.areaID, label: ar.name}) ))
                    }) ))
                }))) : [emptyDepartment],
            roles: org.organizationPositionRoles.length ?
                [emptyValue].concat(filterDeleted(org.organizationPositionRoles).map(rl => ({value: rl.positionRoleID, label: rl.positionRole.name}))) : [emptyValue]
                
        }
    ))
    return sites;
}