import React, { useState, useMemo, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./HeadcountCard.module.scss";
import { Col, Row, FormGroup, Label } from "reactstrap";
import { Button, Input } from "reactstrap";
import { Dropdown, Field, ToggleButton } from "../../../../presentation/Input";
import * as Logic from "./EditHeadcountCard.logic";
import * as Actions from "../../../../../redux/actions/manageHeadcountActions";
import {
  getActiveCompanies,
  getUsageTypes,
  getShifts,
} from "../../../../../redux/selectors/manageHeadcount";
import { lookupSiteStructure } from "../../../../../redux/selectors/editCcr";
import DateRange from "../../../Input/DataRange";
import handleKeyPress from "../../../../../utils/number/handleKeyPress";
import moment, { isMoment } from "moment";
import "./datepicker.css";
import { DateRangePicker } from "react-dates";

const EditHeadcountCard = (props) => {
  const SmallIcon = ({ icon }) => (
    <svg className="em-c-icon em-c-icon--small">
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={`/images/em-icons.svg#${icon}`}
      />
    </svg>
  );
  const [headcount, setHeadcount] = useState(props.headcount);
  const [isProjecteddateEditable, setProjectedDateEditable] = useState(false);
  const today = moment();

  const [validation, setValidation] = useState({ valid: true, errors: {} });
  const [isShiftImbalance, setIsShiftImbalance] = useState(
    Boolean(headcount?.headCountGrouping)
  );
  const [indefinite, setIndefinite] = useState(() => {
    if (headcount?.indefinite) return true;
    if (headcount?.endDate) return false;
    return true;
  });

  const dateRangeDisabled = useMemo(() => {
    if (headcount.isDetailsNotEditable) return false;
    if (indefinite) return "endDate";
  }, [indefinite, headcount.isDetailsNotEditable]);

  const shiftImbalanceOptionMaxRecurrenceTable = useMemo(
    () =>
      new Map([
        ["14.14", 15],
        ["7.7", 30],
        ["4.3", 52],
      ]),
    []
  );

  const handleCheckboxChange = e => {
    if(e.target.checked)
    {
      setIsShiftImbalance(e.target.checked)
      setProjectedDateEditable(true)
    }
    else
    {
        setIsShiftImbalance(false)
        setProjectedDateEditable(false)
    }}
   


  const [shiftImbalanceOptionSelected, setShiftImbalanceOptionSelected] =
    useState("14.14");

  const maxRecurrence = useMemo(() => {
    return (
      shiftImbalanceOptionMaxRecurrenceTable.get(
        shiftImbalanceOptionSelected
      ) ?? 5
    );
  }, [shiftImbalanceOptionSelected]);

  const [recurrence, setRecurrence] = useState(2);

  useEffect(() => {
    if (
      !shiftImbalanceOptionMaxRecurrenceTable.has(shiftImbalanceOptionSelected)
    ) {
      setRecurrence(5);
    }
    if (recurrence > maxRecurrence) {
      setRecurrence(maxRecurrence);
    }
  }, [shiftImbalanceOptionSelected]);

  const options = useMemo(
    () =>
      Logic.getOptions(
        props.sites,
        headcount.organizationID,
        headcount.departmentID,
        headcount.sectionID
      ),
    [
      headcount.organizationID,
      headcount.departmentID,
      headcount.sectionID,
      props.sites,
    ]
  );

  const daysWorking = useMemo(() => {
    if (
      !shiftImbalanceOptionMaxRecurrenceTable.has(shiftImbalanceOptionSelected)
    )
      return 4;
    return Number(shiftImbalanceOptionSelected.split(".")[0]);
  }, [shiftImbalanceOptionSelected]);

  const [projectedDatesArr, setProjectedDatesArr] = useState(() => {
    if (
      !headcount ||
      !headcount.startDate ||
      !isMoment(headcount.startDate.moment)
    )
      return [];
    const originalStartDate = moment(headcount.startDate.moment);

    return Logic.calculateProjectedDatesArray(
      recurrence,
      originalStartDate,
      daysWorking
    );
  });

  const handleShiftImbalanceOptionSelection = useCallback((option) => {
    if (!shiftImbalanceOptionMaxRecurrenceTable.has(option)) return;

    setShiftImbalanceOptionSelected(option);
  }, []);

  useEffect(() => {
    setProjectedDatesArr(
      Logic.calculateProjectedDatesArray(
        recurrence,
        headcount.startDate.moment,
        daysWorking
      )
    );
  }, [daysWorking, headcount?.startDate?.moment]);

  const handleRecurrenceInput = useCallback(
    (e) => {
      const input = e.target.value;
      const newVal = Number(input);
      const validNum = Boolean(newVal);
      const originalRecurrence = recurrence;
      if (newVal >= maxRecurrence || !validNum) return;

      setRecurrence(newVal);

      // increment recurrence
      if (originalRecurrence < newVal) {
        // keep original array incase they have made manual changes to the dates
        // add on new dates
        setProjectedDatesArr((originalArr) => [
          ...originalArr,
          ...Logic.calculateProjectedDatesArray(
            // add as many entries as they jumped in recurrence
            newVal - originalRecurrence,
            // start from 2 * daysWorking from the last projected date
            moment(originalArr[originalArr.length - 1].startDate).add(
              2 * daysWorking,
              "days"
            ),
            daysWorking
          ),
        ]);
      }
      if (originalRecurrence > newVal) {
        setProjectedDatesArr((originalArr) => originalArr.slice(0, newVal));
      }
    },
    [maxRecurrence, daysWorking, recurrence]
  );

  const ShiftImbalanceOptions = () => (
    <React.Fragment>
      <Row>
        <Col xs={2}>
          <Row>
            <strong>14-14</strong>
          </Row>
          <Input
            type="checkbox"
            className="position-checkbox"
            onChange={() => {
              handleShiftImbalanceOptionSelection("14.14");
            }}
            checked={shiftImbalanceOptionSelected === "14.14"}
          />
        </Col>
        <Col xs={2}>
          <Row>
            <strong>7-7</strong>
          </Row>
          <Input
            type="checkbox"
            className="position-checkbox"
            onChange={() => {
              handleShiftImbalanceOptionSelection("7.7");
            }}
            checked={shiftImbalanceOptionSelected === "7.7"}
          />
        </Col>
        <Col xs={2}>
          <Row>
            <strong>4-3</strong>
          </Row>
          <Input
            type="checkbox"
            className="position-checkbox"
            onChange={() => {
              handleShiftImbalanceOptionSelection("4.3");
            }}
            checked={shiftImbalanceOptionSelected === "4.3"}
          />
        </Col>
        <Col xs={1}>
          <Row>
            <strong>Recurrence</strong>
          </Row>
          <Input
            style={{ width: "4rem" }}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="How Many times would you like this to happen again"
            value={recurrence}
            onKeyDown={handleKeyPress}
            min={2}
            max={maxRecurrence}
            onChange={handleRecurrenceInput}
            disabled={headcount.isDetailsNotEditable}
          />
        </Col>
      </Row>
    </React.Fragment>
  );

  const displayProjectedDates = useCallback(() => {    
    if (!Array.isArray(projectedDatesArr)) return <></>;
    return (
      <div className={styles.projected_dates}>
        {projectedDatesArr.map(({ startDate, endDate }, i) => (
          <div key={i}>
            <DateRange
              startDate={startDate}
              endDate={endDate}
              disabled={true}             
              onChange={(startVal, endVal) => {
                if (moment.isMoment(startVal) && startVal.isValid()) {
                  const newStart = moment(startVal);
                  setProjectedDatesArr((originalArr) =>
                    originalArr.map((originalVal, originalI) =>
                      originalI === i
                        ? { ...originalVal, startDate: newStart }
                        : originalVal
                    )
                  );
                }
                if (moment.isMoment(endVal) && endVal.isValid()) {
                  const newEnd = moment(endVal);
                  setProjectedDatesArr((originalArr) =>
                    originalArr.map((originalVal, originalI) =>
                      originalI === i
                        ? { ...originalVal, endDate: newEnd }
                        : originalVal
                    )
                  );
                }
              }}
              initialVisibleMonth={() =>
                Logic.getInitalMonthForDateRangePicker(
                  headcount.startDate,
                  headcount.endDate
                )
              }
              blockedDates={[]} // override the default with no blocked dates
            />{" "}
            {moment(endDate).diff(moment(startDate), "days")} Days
          </div>
        ))}
      </div>
    );
  }, [projectedDatesArr, shiftImbalanceOptionSelected]);
  return (
    <div className={styles.edit_card_container}>
      {/*
            <header className={styles.card_header}>
                <h3>Headcount Req: {headcount.headcountRequestID}</h3>
                <span>All that is needed to render the display card is here.</span>
            </header>
            */}
      <div>
        <Row className={styles.row_bottom}>
          <Col>
            <Row className={styles.row_bottom}>
              <Col sm={2}>
                <strong>Organization</strong>
              </Col>
              <Col sm={4}>
                <Field
                  isValid={!validation.errors.organizationID}
                  validationMessage={validation.errors.organizationID}
                >
                  <Dropdown
                    sorted={true}
                    items={options.orgOptions}
                    value={headcount.organizationID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        organizationID: Number(e.target.value),
                        positionRoleID: 0,
                        departmentID: 0,
                        sectionID: 0,
                        areaID: 0,
                      })
                    }
                    showNoneOption
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
              <Col sm={2}>
                <strong>Usage Type</strong>
              </Col>
              <Col sm={4}>
                <Field
                  isValid={!validation.errors.usageTypeID}
                  validationMessage={validation.errors.usageTypeID}
                >
                  <Dropdown
                    sorted={true}
                    items={props.usageTypes}
                    value={headcount.usageTypeID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        usageTypeID: Number(e.target.value),
                      })
                    }
                    showNoneOption
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
            </Row>
            <Row className={styles.row_bottom}>
              <Col sm={2}>
                <strong>Role</strong>
              </Col>
              <Col sm={10} className={styles.small_height}>
                <Field
                  isValid={!validation.errors.positionRoleID}
                  validationMessage={validation.errors.positionRoleID}
                >
                  <Dropdown
                    sorted={true}
                    items={options.roleOptions}
                    value={headcount.positionRoleID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        positionRoleID: Number(e.target.value),
                      })
                    }
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
            </Row>
            <Row className={styles.row_bottom}>
              <Col sm={2}>
                <strong>Department</strong>
              </Col>
              <Col sm={10} className={styles.small_height}>
                <Field
                  isValid={!validation.errors.departmentID}
                  validationMessage={validation.errors.departmentID}
                >
                  <Dropdown
                    sorted={true}
                    items={options.deptOptions}
                    value={headcount.departmentID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        departmentID: Number(e.target.value),
                        sectionID: 0,
                        areaID: 0,
                      })
                    }
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
            </Row>
            <Row className={styles.row_bottom}>
              <Col sm={2}>
                <strong>Section</strong>
              </Col>
              <Col sm={4}>
                <Field>
                  <Dropdown
                    sorted={true}
                    items={options.sectOptions}
                    value={headcount.sectionID || 0}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        sectionID: Number(e.target.value),
                        areaID: 0,
                      })
                    }
                    disabled={headcount.isDetailsNotEditable}
                  />
                </Field>
              </Col>
              <Col sm={2}>
                <strong>Area</strong>
              </Col>
              <Col sm={4}>
                <Dropdown
                  sorted={true}
                  items={options.areaOptions}
                  value={headcount.areaID || 0}
                  onChange={(e) =>
                    setHeadcount({
                      ...headcount,
                      areaID: Number(e.target.value),
                    })
                  }
                  disabled={headcount.isDetailsNotEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <strong>Company</strong>
              </Col>
              <Col sm={4}>
                <Field>
                  <Dropdown
                    sorted={true}
                    items={props.companies}
                    value={headcount.companyID}
                    onChange={(e) =>
                      setHeadcount({
                        ...headcount,
                        companyID: Number(e.target.value),
                      })
                    }
                    showNoneOption
                    disabled={headcount.status !== "New"}
                  />
                </Field>
              </Col>
              <Col sm={2}>
                <strong>Qty</strong>
              </Col>
              <Col sm={4}>
                <Input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  placeholder="Number of contractors"
                  value={headcount.quantity}
                  onKeyDown={(e) => handleKeyPress(e)}
                  min={headcount.status === "New" ? 1 : 0}
                  onChange={(e) =>
                    setHeadcount({
                      ...headcount,
                      quantity: Number(e.target.value),
                    })
                  }
                  disabled={headcount.isDetailsNotEditable}
                />
              </Col>
              <div
                className={
                  validation.errors.quantity
                    ? "invalid-feedback show"
                    : "invalid-feedback"
                }
              >
                {validation.errors.quantity}
              </div>
            </Row>
          </Col>
        </Row>
        <Row className={styles.row_bottom}>
          <Col xs={3}>
            <strong>Shift</strong>
            <ToggleButton
              name={"shiftID"}
              options={props.shifts.map((x) => x.label)}
              onChange={(value) =>
                setHeadcount({
                  ...headcount,
                  shift: value,
                  shiftID: Number(
                    props.shifts.find((x) => x.label === value).value
                  ),
                })
              }
              selected={headcount.shift}
              disabled={headcount.isDetailsNotEditable}
            />
            <Row>
              <Col xs={6}>
                <strong>Billable</strong>
              </Col>
              <Col>
                <Input
                  type="checkbox"
                  className="position-checkbox"
                  onChange={(e) =>
                    setHeadcount({
                      ...headcount,
                      billable: e.target.checked,
                    })
                  }
                  checked={headcount.billable}            
                  disabled={headcount.isDetailsNotEditable}                  
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <strong>Schedulable</strong>
              </Col>
              <Col>
                <Input
                  type="checkbox"
                  className="position-checkbox"
                  onChange={(e) =>
                    setHeadcount({
                      ...headcount,
                      schedulable: e.target.checked,
                    })
                  }
                  checked={headcount.schedulable}
                  disabled={headcount.isDetailsNotEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <strong>Shift Imbalance</strong>
              </Col>
              <Col>
                <Input
                  type="checkbox"
                  className="position-checkbox"
                  onChange={handleCheckboxChange}
                  checked={isShiftImbalance}
                  disabled={headcount.isDetailsNotEditable}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={3}>
            <Row>
              <strong>Dates</strong>
            </Row>
            <FormGroup row>
              <Field             
                isValid={!validation.errors.startDate}
                validationMessage={validation.errors.startDate}
              >
                {isShiftImbalance ? (
                  <DateRange
                    startDate={
                      headcount.startDate ? headcount.startDate.moment : null
                    }
                    disabled={"endDate"}
                    onChange={(startDate) => {
                      setHeadcount({
                        ...headcount,
                        startDate: isMoment(startDate)
                          ? {
                              moment: moment(startDate),
                              value: startDate.format("YYYY-MM-DD"),
                            }
                          : {
                            moment: moment(today),
                            value: today.format("YYYY-MM-DD")                           
                          },
                        endDate: null,
                      });
                    }}                    
                    initialVisibleMonth={() =>
                      Logic.getInitalMonthForDateRangePicker(
                        headcount.startDate,
                        headcount.endDate
                      )
                    }
                    blockedDates={[]} // override the default with no blocked dates
                  />
                ) : (
                  <DateRange                
                    onChange={(startDate, endDate) => {
                      setHeadcount({
                        ...headcount,
                        startDate: startDate
                          ? {
                              moment: startDate,
                              value: startDate.format("YYYY-MM-DD"),
                            }
                          : {
                            moment: moment(today),
                            value: today.format("YYYY-MM-DD")                           
                          },
                        endDate: endDate
                          ? {
                              moment: endDate,
                              value: endDate.format("YYYY-MM-DD"),
                            }
                          : null,
                      });
                    }}
                    startDate={
                      headcount.startDate ? headcount.startDate.moment : null
                    }
                    endDate={
                      headcount.endDate ? headcount.endDate.moment : null
                    }
                    initialVisibleMonth={() =>
                      Logic.getInitalMonthForDateRangePicker(
                        headcount.startDate,
                        headcount.endDate
                      )
                    }
                    blockedDates={[]} // override the default with no blocked dates
                    disabled={dateRangeDisabled}
                  />
                )}
              </Field>

              {!isShiftImbalance && (
                <React.Fragment>
                  <FormGroup check inline>
                    <Label check className="datepicker">
                      <Input                      
                        checked={indefinite}
                        onChange={(e) => {
                          setIndefinite((originalVal) => !originalVal);
                          if (e.target.checked) {
                            setHeadcount({
                              ...headcount,
                              endDate: null,
                            });
                          }
                        }}
                        type="checkbox"
                      />
                      {" Indefinite"}
                    </Label>
                  </FormGroup>
                </React.Fragment>
              )}
            </FormGroup>
            {isShiftImbalance && ShiftImbalanceOptions()}
          </Col>
          {isShiftImbalance && (
            <Col>
              <Row>
                <strong>Projected Dates</strong>
              </Row>
              <Row>{displayProjectedDates()}</Row>
            </Col>
          )}
        </Row>
      </div>

      <div className={styles.edit_card_button_container}>
        <Button
          className={styles.card_button}
          outline
          color="primary"
          onClick={() => props.cancelEdit()}
        >
          Cancel
        </Button>
        <Button
          className={styles.card_button}
          color="primary"
          onClick={() => {
            const validObject = Logic.validate(headcount);
            if (validObject.valid) {
              console.log(projectedDatesArr);
              props.saveEdit(
                {
                  ...headcount,
                  indefinite: isShiftImbalance ? false : headcount.indefinite,
                },
                {
                  isShiftImbalance,
                  projectedDatesArr,
                }
              );
            } else {
              setValidation(validObject);
            }
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sites: lookupSiteStructure(state),
  companies: getActiveCompanies(state),
  usageTypes: getUsageTypes(state),
  shifts: getShifts(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    cancelEdit: () => dispatch(Actions.cancelEditHeadcount()),
    saveEdit: (headcount, shiftImbalance) =>
      dispatch(Actions.saveEditHeadcount(headcount, shiftImbalance)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHeadcountCard);
