import React, { Component } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import BreadcrumbNav from '../../../presentation/Breadcrumb';
import ImportCompare from '../../../containers/DailyForceReport/Import';
import Export from '../../../containers/DailyForceReport/Export';

const tabNames = [
  { id: '1', name: 'Export', listName: 'export' },
  { id: '2', name: 'Import', listName: 'importCompare' },
];

const breadcrumb = [
  { name: 'Home', link: '/' },
  { name: 'Daily Force Report', isActive: true },
];

export default class DailyForceReportView extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      tabUpdated: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activeTab: nextProps.tab, tabUpdated: true });
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      this.forceUpdate();
    }
  }

  render() {
    const { activeTab, tabUpdated } = this.state;
    return (
      <div className="homeContainer">
        <BreadcrumbNav items={breadcrumb} />
        {tabUpdated ? (
          <div className="ccrContainer">
            <Nav tabs key="userTabNav">
              {
                tabNames.map(item => (
                  <NavItem key={item.id}>
                    <NavLink
                      className={`${classnames({ active: activeTab === item.id })} tabs`}
                      onClick={() => { this.toggle(item.id); }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))
              }
            </Nav>
            <TabContent activeTab={activeTab} key="userTabContent">
              <TabPane tabId="1">
                <br />
                <Export {...this.props} />
              </TabPane>
              <TabPane tabId="2">
                <br />
                <ImportCompare {...this.props} />
              </TabPane>
            </TabContent>
          </div>
        ) : null
      }
      </div>
    );
  }
}
