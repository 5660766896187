import React, { Component } from 'react';
import UnityButton from '../../presentation/unity/Button';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

class ReviewComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ccrDenyComment: "",
            disabledSubmit: true
        }
        this.setValue = this.setValue.bind(this);
    }

    onDeny = () => this.props.onDeny(this.state.ccrDenyComment); //arrow function without binding

    setValue(value) {
        this.setState({
            ccrDenyComment: value,
            disabledSubmit: value.length > 1 ? false : true
        })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggleComment} className='em-c-modal__window em-js-modal-window'>
                    <ModalHeader className="em-c-modal__title">
                        Deny Ccr
                    </ModalHeader>
                    <ModalBody id="ccrBoxView" className='kmod-view-ccr-modal-body'>
                        Please provide appropriate details for the requestor
                        <div className="em-c-field__body">
                            <textarea className="em-c-textarea "
                                id="ccrDenyComment"
                                name="ccrDenyComment"
                                value={this.state.ccrDenyComment}
                                onChange={val => this.setValue(val.target.value)}
                                rows="5">
                            </textarea>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <UnityButton disabled={(this.state.disabledSubmit)} onClick={this.onDeny} text="Continue" />
                        <UnityButton onClick={this.props.toggleComment} text="Cancel" />
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ReviewComment;

